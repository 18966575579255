import { useEffect } from "react";
import { Observable } from "rxjs";

const useObservable = <T>(
  observable: Observable<T>,
  setter: (value: T) => void,
  onError?: (err: any) => any | undefined
) => {
  useEffect(() => {
    const subscription = observable.subscribe({
      error: onError,
      next: (value: T) => {
        if (value) {
          setter(value);
        }
      },
      complete: () => {},
    });

    return () => subscription.unsubscribe();
  }, [observable, onError, setter]);
};

export default useObservable;
