import { McuEntry } from "../../const/types";
import { Chip } from "@mui/material";
import { ParkIcon, TravelIcon, WorkIcon } from "../common/customIcons";
import { primaryBlueLite } from "../../const/ui";

export const getOpModeChip = (entry: McuEntry) => {
  return (
    <Chip
      color={"primary"}
      size={"small"}
      sx={{
        paddingX: 0.5,
        paddingY: 1,
        backgroundColor: primaryBlueLite,
      }}
      variant={"outlined"}
      icon={
        entry.mode === "work" ? (
          <WorkIcon />
        ) : entry.mode === "travel" ? (
          <TravelIcon />
        ) : (
          <ParkIcon />
        )
      }
      label={entry.mode?.toUpperCase() || "UNKNOWN"}
    />
  );
};
