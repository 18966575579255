/**
 * Action types
 */
import {
  EditVehiclePayload,
  ManyEntry,
  VehicleEntry,
  VehicleQs,
  VehicleServiceTypeEntry,
  VehicleServiceTypes,
} from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_VEHICLE_TYPES = Symbol.for("FETCH_VEHICLE_TYPES");
export const FETCH_MANY_VEHICLES = Symbol.for("FETCH_MANY_VEHICLES");
export const EDIT_VEHICLE = Symbol.for("EDIT_VEHICLE");
export const LOAD_VEHICLE = Symbol.for("LOAD_VEHICLE");
export const FETCH_VEHICLE_FAILED = Symbol.for("FETCH_VEHICLE_FAILED");
export const LOAD_MANY_VEHICLES = Symbol.for("LOAD_MANY_VEHICLES");
export const LOAD_VEHICLE_TYPES = Symbol.for("LOAD_VEHICLE_TYPES");
export const CREATE_VEHICLE_TYPE = Symbol.for("CREATE_VEHICLE_TYPE");
export const LOAD_VEHICLE_TYPE = Symbol.for("LOAD_VEHICLE_TYPE");

/**
 * Actions
 */

export function fetchVehicleTypesAction() {
  return {
    type: FETCH_VEHICLE_TYPES,
  };
}

export function fetchManyVehicleAction(qs: VehicleQs) {
  return {
    type: FETCH_MANY_VEHICLES,
    payload: qs,
  };
}

export function vehicleFetchFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_VEHICLE_FAILED,
    payload: err.toString(),
  };
}

export function loadVehicleTypesAction(payload: VehicleServiceTypes) {
  return {
    type: LOAD_VEHICLE_TYPES,
    payload,
  };
}

export function loadManyVehicleAction(payload: ManyEntry<VehicleEntry>) {
  return {
    type: LOAD_MANY_VEHICLES,
    payload,
  };
}

export function createVehicleTypeAction(label: string) {
  return {
    type: CREATE_VEHICLE_TYPE,
    payload: label,
  };
}

export function loadVehicleTypeAction(payload: VehicleServiceTypeEntry) {
  return {
    type: LOAD_VEHICLE_TYPE,
    payload,
  };
}

export function editVehicleAction(id: string, payload: EditVehiclePayload) {
  return {
    type: EDIT_VEHICLE,
    payload: {
      id,
      payload,
    },
  };
}

export function loadVehicleAction(id: string, payload: VehicleEntry) {
  return {
    type: LOAD_VEHICLE,
    payload: {
      id,
      payload,
    },
  };
}
