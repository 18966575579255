import * as settingsAction from "../actions/settings.action";
import { AnyAction } from "redux";
import { DARK_MODE } from "../const/const";

export interface State {
  darkMode: boolean;
}

const initialState: State = {
  darkMode: localStorage.getItem(DARK_MODE) === "true",
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case settingsAction.ENABLE_DARK_MODE: {
      return {
        ...state,
        darkMode: action.payload,
      };
    }
    default:
      return state;
  }
}

/**
 * selector
 */
export const getDarkMode = (state: State) => state.darkMode;
