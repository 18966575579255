import * as alertAction from "../actions/alert.action";
import * as notificationAction from "../actions/notification.action";
import * as authAction from "../actions/auth.action";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import {
  AlertEntry,
  AlertQs,
  AlertTypeEntry,
  ManyEntry,
  RawAlertEntry,
  Role,
  UserEntry,
} from "../const/types";
import { ajax } from "rxjs/ajax";
import { ALERT_API, MCU_API, VEHICLE_API, VPU_API } from "../const/api";
import { getAlertTypes, getLocation, getToken, getAlertQs } from "../reducers";
import {
  alertColor,
  alertMessage,
  joinAlertQueryStr,
  toAlertEntry,
} from "../helper/util";
import { RootState } from "../config/store";

export const fetchAlertTypesAfterLoginEpic = (
  action$: Observable<AnyAction>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(authAction.LOAD_MY_INFO),
    map((action) => action.payload),
    filter((me: UserEntry) => me.Role?.label !== Role.ASSEMBLER),
    map(() => alertAction.fetchAlertTypesAction())
  );

export const fetchAlertTypesEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(alertAction.FETCH_ALERTS_TYPES),
    switchMap(() =>
      ajax
        .getJSON<AlertTypeEntry[]>(`${ALERT_API}/types`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(alertAction.loadAlertTypesAction),
          catchError((err) => of(alertAction.fetchAlertFailedAction(err)))
        )
    )
  );

export const handleNewAlertEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(alertAction.NEW_ALERT),
    map((action) => action.payload),
    map((payload: RawAlertEntry) => {
      const location = getLocation(state$.value);
      const processedAlert: AlertEntry = toAlertEntry(payload);
      if (location && location.pathname.startsWith("/alert")) {
        return alertAction.appendAlertAction(processedAlert);
      } else {
        const alertType: AlertTypeEntry = getAlertTypes(state$.value)[
          payload.alert_type_id
        ];
        processedAlert.message = alertMessage(alertType, processedAlert);
        processedAlert.color = alertColor(alertType, processedAlert);
        return notificationAction.loadNotificationFromAlertAction(
          processedAlert,
          alertType.label
        );
      }
    })
  );

export const fetchManyAlertsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(alertAction.FETCH_MANY_ALERTS),
    map((action) => action.payload),
    switchMap((qs: AlertQs) =>
      ajax
        .getJSON<ManyEntry<AlertEntry>>(
          `${ALERT_API}?${joinAlertQueryStr(qs)}`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(alertAction.loadManyAlertAction),
          catchError((err) => of(alertAction.fetchAlertFailedAction(err)))
        )
    )
  );

export const setSearchInputValueEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(alertAction.SET_SEARCH_INPUT_VALUE),
    debounceTime(500),
    map((action) => action.payload),
    distinctUntilChanged(),
    switchMap((val: string) => {
      if (val.length >= 3 && getAlertQs(state$.value).filter_value !== val) {
        let req;
        let { filter_by } = getAlertQs(state$.value);
        switch (filter_by) {
          case "mcu_id":
            req = `${MCU_API}/search?id=${val}&only_ids=true`;
            break;
          case "mcu_sn":
            req = `${MCU_API}/search?sn=${val}&only_ids=true`;
            break;
          case "vpu_id":
            req = `${VPU_API}/search?id=${val}&only_ids=true`;
            break;
          case "vpu_sn":
            req = `${VPU_API}/search?sn=${val}&only_ids=true`;
            break;
          case "vehicle_id":
            req = `${VEHICLE_API}?query=${val}&only_ids=true`;
            break;
        }
        return ajax
          .getJSON(req, {
            Authorization: `Bearer ${getToken(state$.value)}`,
          })
          .pipe(
            map(alertAction.setSearchResultsAction),
            catchError((err) => {
              return of(
                alertAction.setSearchResultsAction({ count: 0, rows: [] })
              );
            })
          );
      }
      return of(alertAction.setSearchResultsAction({ count: 0, rows: [] }));
    })
  );

export const alertEpics = [
  fetchAlertTypesAfterLoginEpic,
  fetchAlertTypesEpic,
  fetchManyAlertsEpic,
  handleNewAlertEpic,
  setSearchInputValueEpic,
];
