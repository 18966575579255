import * as fatAction from "../actions/fat.action";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  ignoreElements,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import { ComponentSearchResult, TestResults, Tests } from "../const/types";
import { ajax } from "rxjs/ajax";
import { FAT_BASE_URL } from "../const/api";
import { getSelectedComponent, getTestResultQs, getToken } from "../reducers";
import { RootState } from "../config/store";
import { joinQueryStr } from "../helper/util";
import {
  COMPONENT_SEARCH_DEBOUNCE_TIME,
  SELECTED_FAT_COMPONENT,
} from "../const/const";

export const fetchComponentListEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(fatAction.FETCH_COMPONENT_LIST),
    first(),
    map((action) => action.payload),
    switchMap(() =>
      ajax
        .getJSON<{ components: string[] }>(`${FAT_BASE_URL}/component`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map((r) => fatAction.loadComponentListAction(r.components)),
          catchError((err) => of(fatAction.fetchFATFailedAction(err)))
        )
    )
  );

export const fetchTestsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(fatAction.FETCH_TESTS),
    map((action) => action.payload),
    switchMap(({ component, qs }) =>
      ajax
        .getJSON<Tests>(
          `${FAT_BASE_URL}/${component}/test?${joinQueryStr(qs)}`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map((tests) => fatAction.loadTestsAction(component, tests)),
          catchError((err) => of(fatAction.fetchFATFailedAction(err)))
        )
    )
  );

export const fetchTestResultsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(fatAction.FETCH_TEST_RESULTS),
    map((action) => action.payload),
    switchMap(({ component, qs }) =>
      ajax
        .getJSON<TestResults>(
          `${FAT_BASE_URL}/${component}/result?${joinQueryStr(qs)}`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(fatAction.loadTestResultsAction),
          catchError((err) => of(fatAction.fetchFATFailedAction(err)))
        )
    )
  );

export const searchTestTargetEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(fatAction.SEARCH_COMPONENT),
    debounceTime(COMPONENT_SEARCH_DEBOUNCE_TIME),
    map((action) => action.payload),
    distinctUntilChanged(),
    filter(
      (idLike: string) =>
        idLike.length > 3 &&
        getTestResultQs(state$.value).test_target_id !== idLike
    ),
    switchMap((idLike: string) =>
      ajax
        .getJSON<ComponentSearchResult>(
          `${FAT_BASE_URL}/${getSelectedComponent(
            state$.value
          )}/search?id_like=${idLike}`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(fatAction.loadSearchComponentResult),
          catchError((err) => of(fatAction.fetchFATFailedAction(err)))
        )
    )
  );

export const selectComponentEpic = (
  action$: Observable<AnyAction>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(fatAction.SELECT_COMPONENT),
    map((action) => action.payload),
    tap((component: string) =>
      localStorage.setItem(SELECTED_FAT_COMPONENT, component)
    ),
    ignoreElements()
  );

export const fatEpics = [
  fetchComponentListEpic,
  fetchTestsEpic,
  fetchTestResultsEpic,
  searchTestTargetEpic,
  selectComponentEpic,
];
