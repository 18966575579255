import { applyMiddleware, createStore } from "redux";
import { createEpicMiddleware } from "redux-observable";
import createRootReducer from "../reducers";
import { createBrowserHistory } from "history";
import rootEpic from "../epics";
import { createReduxHistoryContext } from "redux-first-history";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import pca from "./msal";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

const epicMiddleware = createEpicMiddleware({
  dependencies: { msalInstance: pca },
});
const middlewares = [epicMiddleware, routerMiddleware];
if (
  process.env.NODE_ENV !== "production" &&
  process.env.REACT_APP_ENABLE_REDUX_LOGGER
) {
  middlewares.push(logger);
}

export const store = createStore(
  createRootReducer(routerReducer),
  composeWithDevTools(applyMiddleware(...middlewares))
);
export type RootState = ReturnType<typeof store.getState>;

epicMiddleware.run(rootEpic);
export const history = createReduxHistory(store);
