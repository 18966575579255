import React, { useState, useMemo } from "react";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {
  ListItemIcon,
  ListItemText,
  Divider,
  List,
  Tooltip,
  ListItemButton,
  Collapse,
  Box,
  IconButton,
  Popover,
  Button,
} from "@mui/material";
import { SIDE_MENU, ASSEMBLER_SIDE_MENU } from "../../const/ui";
import { connect, ConnectedProps } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getDarkMode, getMe } from "../../reducers";
import { RootState } from "../../config/store";
import { DRAWER_WIDTH } from "../../const/const";
import { memo } from "react";
import Typography from "@mui/material/Typography";
import logo from "../../asset/piper_white.png";
import { ThemeProvider } from "@mui/material/styles";
import { sideBarTheme } from "../../const/themes";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { logoutAction } from "../../actions/auth.action";
import { Dispatch } from "redux";
import { Role } from "../../const/types";

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  scrollbarColor: "grey #333333",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideBar: React.FC<Props> = ({ open, location, me, dispatchLogout }) => {
  const navigate = useNavigate();
  const [btnOpen, setBtnOpen] = useState<string | null>(null);
  const handleExpand = (e: any, label: string) => {
    e.preventDefault();
    btnOpen === label ? setBtnOpen(null) : setBtnOpen(label);
  };
  const handleListItemBtnClick = (e: any, path: string) => {
    e.preventDefault();
    if (path !== "") navigate(path);
  };
  const [logoutAnchorEl, setLogoutAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const handleLogoutOpen = (evt: React.MouseEvent<HTMLElement>) => {
    setLogoutAnchorEl(evt.currentTarget);
  };
  const handleLogoutClose = () => setLogoutAnchorEl(null);
  const logoutOpen = Boolean(logoutAnchorEl);
  const logoutId = open ? "logout-popover" : undefined;

  const sideMenu = useMemo(() => {
    return me.Role?.label === Role.ASSEMBLER ? ASSEMBLER_SIDE_MENU : SIDE_MENU;
  }, [me]);

  return (
    <ThemeProvider theme={sideBarTheme}>
      <Drawer variant="permanent" anchor="left" open={open}>
        <Link
          to="/"
          style={{
            alignSelf: "center",
            marginTop: "15px",
          }}
        >
          <img
            style={{
              minWidth: "142px",
              height: "48px",
            }}
            src={logo}
            alt={"Piper"}
          />
        </Link>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant={"subtitle1"}
            marginRight={1}
            sx={{
              alignSelf: "center",
            }}
          >
            {me.name}
          </Typography>
          <IconButton onClick={handleLogoutOpen}>
            <ArrowDropDownIcon />
          </IconButton>
          <Popover
            id={logoutId}
            open={logoutOpen}
            anchorEl={logoutAnchorEl}
            onClose={handleLogoutClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Button onClick={dispatchLogout}>Logout</Button>
          </Popover>
        </Box>
        <Divider />
        <List>
          {sideMenu
            .filter(
              ({ allowedTo, providersBlocked }) =>
                me.Role &&
                allowedTo.includes(me.Role.label) &&
                !providersBlocked.includes(me.provider)
            )
            .map(({ label, icon: Icon, path, children }) => {
              const selected = location?.pathname.includes(path) && path !== "";
              return (
                <React.Fragment key={label}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      pr: 1,
                      backgroundColor: selected ? "grey" : "inherit",
                      "&:hover": {
                        backgroundColor: selected ? "grey" : "inherit",
                      },
                    }}
                  >
                    <ListItemButton
                      component="div"
                      onClick={(e) => {
                        handleListItemBtnClick(e, path);
                        if (children) {
                          handleExpand(e, label);
                        }
                      }}
                    >
                      <ListItemIcon>
                        <Tooltip title={label} placement={"right"}>
                          <span>
                            <Icon />
                          </span>
                        </Tooltip>
                      </ListItemIcon>
                      <ListItemText primary={label} />
                    </ListItemButton>
                    {children && (
                      <>
                        {btnOpen === label ? (
                          <ExpandLess
                            onClick={(e) => handleExpand(e, label)}
                            sx={{ cursor: "pointer" }}
                          />
                        ) : (
                          <ExpandMore
                            onClick={(e) => handleExpand(e, label)}
                            sx={{ cursor: "pointer" }}
                          />
                        )}
                      </>
                    )}
                  </Box>
                  <Collapse in={btnOpen === label} unmountOnExit>
                    <List component="div">
                      {children?.map(({ label, icon: Icon, path }) => {
                        const selected =
                          location?.pathname.includes(path) && path !== "";
                        return (
                          <ListItemButton
                            key={label}
                            component="div"
                            sx={{
                              pl: 4,
                              backgroundColor: selected ? "grey" : "inherit",
                              "&:hover": {
                                backgroundColor: selected ? "grey" : "inherit",
                              },
                            }}
                            onClick={(e) => handleListItemBtnClick(e, path)}
                          >
                            <ListItemIcon>
                              <Tooltip title={label} placement={"right"}>
                                <span>
                                  <Icon />
                                </span>
                              </Tooltip>
                            </ListItemIcon>
                            <ListItemText primary={label} />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Collapse>
                </React.Fragment>
              );
            })}
        </List>
      </Drawer>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  location: state.router.location,
  darkMode: getDarkMode(state),
  me: getMe(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchLogout: () => dispatch(logoutAction()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(memo(SideBar));

interface PropsFromParent {
  open: boolean;
  setOpen: Function;
}

type StateProps = ConnectedProps<typeof connector>;
type Props = PropsFromParent & StateProps;
