import { TableHeadRow, TabProps, LccasTableRowType } from "../const/types";

/**
 * Action types
 */

export const SET_TABS = Symbol.for("SET_TABS");
export const SET_TAB_PROPS = Symbol.for("SET_TAB_PROPS");
export const SET_TAB_STYLE = Symbol.for("SET_TAB_STYLE");
export const SET_SELECTED_TAB = Symbol.for("SET_SELECTED_TAB");

export const SET_PAGINATION = Symbol.for("SET_PAGINATION");
export const SET_PAGE = Symbol.for("SET_PAGE");
export const SET_ROWS_PER_PAGE = Symbol.for("SET_ROWS_PER_PAGE");
export const SET_PAGINATION_COUNT = Symbol.for("SET_PAGINATION_COUNT");

export const SET_HEADERS = Symbol.for("SET_HEADERS");
export const SET_ROWS = Symbol.for("SET_ROWS");
export const RESET_TABLE = Symbol.for("RESET_TABLE");
export const SET_SORT_DIRECTION = Symbol.for("SET_SORT_DIRECTION");
export const SET_SORT_FIELD = Symbol.for("SET_SORT_FIELD");

/**
 * Actions
 */

// Tabs
export function setTabsAction(payload: boolean) {
  return {
    type: SET_TABS,
    payload,
  };
}
export function setTabPropsAction(payload: TabProps[]) {
  return {
    type: SET_TAB_PROPS,
    payload,
  };
}
export function setTabStyleAction(payload: any) {
  return {
    type: SET_TAB_STYLE,
    payload,
  };
}
export function setSelectedTabAction(payload: string) {
  return {
    type: SET_SELECTED_TAB,
    payload,
  };
}

// Pagination
export function setPaginationAction(payload: boolean) {
  return {
    type: SET_PAGINATION,
    payload,
  };
}
export function setPageAction(payload: number) {
  return {
    type: SET_PAGE,
    payload,
  };
}
export function setRowsPerPageAction(payload: number) {
  return {
    type: SET_ROWS_PER_PAGE,
    payload,
  };
}
export function setPaginationCountAction(payload: number) {
  return {
    type: SET_PAGINATION_COUNT,
    payload,
  };
}

// Table Data
export function setHeadersAction(payload: TableHeadRow[]) {
  return {
    type: SET_HEADERS,
    payload,
  };
}
export function setRowsAction(payload: LccasTableRowType[]) {
  return {
    type: SET_ROWS,
    payload,
  };
}
export function setSortDirectionAction(payload: string) {
  return {
    type: SET_SORT_DIRECTION,
    payload,
  };
}
export function setSortFieldAction(payload: string) {
  return {
    type: SET_SORT_FIELD,
    payload,
  };
}

// Reset
export function resetTableAction() {
  return {
    type: RESET_TABLE,
  };
}
