import * as authAction from "../actions/auth.action";
import { AnyAction } from "redux";
import { UserEntry } from "../const/types";
import { AllowedVersionsType } from "../const/types";

export interface State {
  token: string | null;
  me: UserEntry;
  error: string;
  loading: boolean;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  passwordSameAsOld: boolean;
  passwordNotMatching: boolean;
  canSubmit: boolean;
  dialogIsOpen: boolean;
  updateRequired: boolean;
  allowedVersions?: AllowedVersionsType;
}

const initialState: State = {
  token: null,
  me: {
    id: "",
    RoleId: -1,
    name: "",
    provider: "",
    createdAt: "",
    updatedAt: "",
  },
  error: "",
  loading: false,
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  passwordSameAsOld: false,
  passwordNotMatching: false,
  canSubmit: false,
  dialogIsOpen: false,
  updateRequired: false,
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction): State {
  switch (action.type) {
    case authAction.LOGIN: {
      return { ...state, loading: true };
    }

    case authAction.LOAD_TOKEN: {
      return { ...state, token: action.payload, loading: false };
    }

    case authAction.LOAD_MY_INFO: {
      return { ...state, me: action.payload };
    }

    case authAction.AFTER_LOG_OUT: {
      return initialState;
    }

    case authAction.LOG_IN_ERROR: {
      return { ...state, error: action.payload, loading: false };
    }

    case authAction.TYPE_PASSWORD: {
      const oldPassword = action.payload.oldPassword;
      const newPassword = action.payload.newPassword;
      const confirmPassword = action.payload.confirmPassword;
      let passwordSameAsOld = false;
      let passwordNotMatching = false;
      if (
        oldPassword.length > 0 &&
        newPassword.length > 0 &&
        oldPassword === newPassword
      )
        passwordSameAsOld = true;
      if (
        confirmPassword.length > 0 &&
        newPassword.length > 0 &&
        confirmPassword !== newPassword
      )
        passwordNotMatching = true;
      return {
        ...state,
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
        passwordSameAsOld: passwordSameAsOld,
        passwordNotMatching: passwordNotMatching,
        canSubmit:
          oldPassword &&
          newPassword &&
          confirmPassword &&
          !passwordSameAsOld &&
          !passwordNotMatching,
      };
    }

    case authAction.SUBMIT_PASSWORD_CHANGE: {
      return {
        ...state,
        confirmPassword: "",
        passwordSameAsOld: false,
        passwordNotMatching: false,
        canSubmit: false,
      };
    }

    case authAction.OPEN_PASSWORD_DIALOG: {
      return { ...state, dialogIsOpen: true };
    }

    case authAction.CLOSE_PASSWORD_DIALOG: {
      return {
        ...state,
        dialogIsOpen: false,
        oldPassword: "",
        newPassword: "",
      };
    }

    case authAction.FETCH_MY_INFO_FAILED:
    case authAction.ACQUIRE_AD_ID_TOKEN_FAILED:
    case authAction.FORBIDDEN_ERROR:
      return { ...state, loading: false };

    case authAction.LOAD_VERSIONS:
      return { ...state, allowedVersions: action.payload };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getMe = (state: State) => state.me;
export const getToken = (state: State) => state.token;
export const getLoginError = (state: State) => state.error;
export const getAuthLoading = (state: State) => state.loading;
export const getUpdateRequired = (state: State) => state.updateRequired;
export const getAllowedVersions = (state: State) => state.allowedVersions;
