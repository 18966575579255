export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? (process.env.REACT_APP_BASE_DEV_URL ||
        "https://lccas-demo.pipernetworks.com") + "/api/v1"
    : process.env.NODE_ENV === "production" &&
      process.env.REACT_APP_API_BASE_URL
    ? process.env.REACT_APP_API_BASE_URL + "/api/v1"
    : "/api/v1";
export const USER_API = BASE_URL + "/user";
export const INSTALLATION_API = BASE_URL + "/installation";
export const MCU_API = BASE_URL + "/mcu";
export const STREAM_API = BASE_URL + "/stream";
export const VEHICLE_API = BASE_URL + "/vehicle";
export const VPU_API = BASE_URL + "/vpu";
export const VOD_API = BASE_URL + "/vod";
export const AUTH_API = BASE_URL + "/auth";
export const ALERT_API = BASE_URL + "/alert";
export const DASHBOARD_API = BASE_URL + "/dashboard";
export const BASESTATION_API = BASE_URL + "/basestation";
export const TRACK_API = BASE_URL + "/track";
export const MESSAGE_API = BASE_URL + "/message";
export const SW_UPDATE_API = BASE_URL + "/sw_update";
export const MENDER_API = BASE_URL + "/ota";
export const VIDEO_API = BASE_URL + "/video";
export const CAB_CAM_API = BASE_URL + "/cab_camera";
export const WORK_GANG_API = BASE_URL + "/workgang";

export const LOCAL_TOKEN = "local_token";

export const FAT_BASE_URL =
  "https://a6-lccas-api-dev.pipernetworks.com/api/v1/fat";
