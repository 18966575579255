import * as messageAction from "../actions/message.action";
import {
  REQUEST_BS_SSH_TUNNEL,
  REQUEST_ENABLE_LIDAR,
  REQUEST_ENABLE_SENSOR,
  REQUEST_REPLACE_SENSOR,
  REQUEST_WLAN_SETUP,
  REQUEST_SEND_CONFIG_TO_OLD_DEVICE,
} from "../actions/message.action";
import * as notificationAction from "../actions/notification.action";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import {
  ChangeOpModeMessage,
  EnableLidarPayload,
  EnableSensorPayload,
  ReplaceSensorPayload,
  MessageEntry,
  SSHTunnelMessagePayload,
  SshTunnelPayload,
  WlanSetupPayload,
  WlanSetupResultMessagePayload,
} from "../const/types";
import { getToken } from "../reducers";
import { RootState } from "../config/store";
import {
  NOTIFICATION_SEVERITY_ERROR,
  NOTIFICATION_SEVERITY_SUCCESS,
} from "../const/const";
import { ajax, AjaxError } from "rxjs/ajax";
import { BASESTATION_API, MCU_API, MESSAGE_API } from "../const/api";
import { NOTIFICATION_BOTTOM } from "../const/ui";

const changeOpModeEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(messageAction.CHANGE_OP_MODE_MESSAGE),
    map((action) => action.payload),
    switchMap((payload: ChangeOpModeMessage) =>
      ajax
        .post<ChangeOpModeMessage>(`${MESSAGE_API}/change_op_mode`, payload, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map((res) => res.response),
          mergeMap((res: ChangeOpModeMessage) =>
            of(
              messageAction.changeOpModeSentAction(res),
              notificationAction.loadNotificationAction({
                type: `CHANGE_OP_MODE_MESSAGE_${
                  res.sent ? "SENT" : "FAILED_TO_SEND"
                }`,
                severity: res.sent
                  ? NOTIFICATION_SEVERITY_SUCCESS
                  : NOTIFICATION_SEVERITY_ERROR,
                position: NOTIFICATION_BOTTOM,
                title: `Change Operation mode`,
                content: res.sent
                  ? `Operation mode change requested`
                  : `Failed to send operation mode change request`,
                dur: 10000,
                created: new Date().toISOString(),
              })
            )
          ),
          catchError((err) => of(messageAction.postMessageFailedAction(err)))
        )
    )
  );

const requestMCUSshTunnelEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(messageAction.REQUEST_MCU_SSH_TUNNEL),
    map((action) => action.payload),
    switchMap((payload: SshTunnelPayload) =>
      ajax
        .post<MessageEntry<SSHTunnelMessagePayload>>(
          `${MCU_API}/ssh_tunnel`,
          payload,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map((res: any) => res.response),
          map(messageAction.mcuSshTunnelRequestedAction),
          catchError((err: AjaxError) => {
            if (err.status === 408) {
              return of(
                messageAction.deviceNotRespondingAction({
                  mcu_id: payload.id,
                  command: "ssh",
                }),
                notificationAction.loadNotificationAction({
                  type: `SSH_FAILED`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: "MCU not responding",
                  content: `ssh tunnel request to ${payload.id} failed`,
                  dur: 5000,
                  created: new Date().toISOString(),
                })
              );
            }
            return of(messageAction.fetchMessageFailedAction(err));
          })
        )
    )
  );

export const requestWlanSetupEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(REQUEST_WLAN_SETUP),
    map((action) => action.payload),
    switchMap((payload: WlanSetupPayload) =>
      ajax
        .post<MessageEntry<WlanSetupResultMessagePayload>>(
          `${MESSAGE_API}/wlan_setup`,
          payload,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map((res: any) => res.response),
          map(messageAction.loadWlanSetupResultMessageAction),
          catchError((err: AjaxError) => {
            if (err.status === 408) {
              return of(
                messageAction.deviceNotRespondingAction({
                  mcu_id: payload.mcu_id,
                  command: "wlan",
                }),
                notificationAction.loadNotificationAction({
                  type: `WLAN_SETUP_FAILED`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: "MCU not responding",
                  content: `ssh tunnel request to ${payload.mcu_id} failed`,
                  dur: 5000,
                  created: new Date().toISOString(),
                })
              );
            }
            return of(messageAction.fetchMessageFailedAction(err));
          })
        )
    )
  );

export const requestEnableLidarEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(REQUEST_ENABLE_LIDAR),
    map((action) => action.payload),
    switchMap((payload: EnableLidarPayload) =>
      ajax
        .post<MessageEntry<WlanSetupResultMessagePayload>>(
          `${MESSAGE_API}/enable_lidar`,
          payload,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map((res: any) => res.response),
          map(messageAction.loadEnableLidarAckMessageAction),
          catchError((err: AjaxError) => {
            if (err.status === 408) {
              return of(
                messageAction.enableLidarNotRespondingAction({
                  mcu_id: payload.mcu_id,
                  vpu_id: payload.vpu_id,
                  command: "lidar",
                }),
                notificationAction.loadNotificationAction({
                  type: `ENABLE_LIDAR_FAILED`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: "MCU not responding",
                  content: `${
                    payload.enable ? "enabling" : "disabling"
                  } lidar on ${payload.mcu_id}-${payload.vpu_id} failed`,
                  dur: 5000,
                  created: new Date().toISOString(),
                })
              );
            }
            return of(messageAction.fetchMessageFailedAction(err));
          })
        )
    )
  );

export const requestEnableSensorEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(REQUEST_ENABLE_SENSOR),
    map((action) => action.payload),
    switchMap((payload: EnableSensorPayload) =>
      ajax
        .post<MessageEntry<WlanSetupResultMessagePayload>>(
          `${MESSAGE_API}/enable_sensor`,
          payload,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map((res: any) => {
            console.log("stop");
            return res.response;
          }),
          map(messageAction.loadEnableSensorAckMessageAction),
          catchError((err: AjaxError) => {
            if (err.status === 408) {
              return of(
                messageAction.enableLidarNotRespondingAction({
                  mcu_id: payload.mcu_id,
                  vpu_id: payload.vpu_id,
                  command: payload.sensor_type,
                }),
                notificationAction.loadNotificationAction({
                  type: `ENABLE_SENSOR_FAILED`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: "MCU not responding",
                  content: `${payload.enable ? "enabling" : "disabling"} ${
                    payload.sensor_type
                  } on ${payload.mcu_id}-${
                    payload.vpu_id
                  } will be delivered when MCU returns online`,
                  dur: 5000,
                  created: new Date().toISOString(),
                })
              );
            } else if (err.status === 400) {
              return of(
                messageAction.enableLidarNotRespondingAction({
                  mcu_id: payload.mcu_id,
                  vpu_id: payload.vpu_id,
                  command: payload.sensor_type,
                }),
                notificationAction.loadNotificationAction({
                  type: `ENABLE_SENSOR_FAILED`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: "Enable/Disable Sensor Bad Request",
                  content: `${payload.enable ? "enabling" : "disabling"} ${
                    payload.sensor_type
                  } on ${payload.mcu_id}-${payload.vpu_id} failed`,
                  dur: 5000,
                  created: new Date().toISOString(),
                })
              );
            }
            return of(messageAction.fetchMessageFailedAction(err));
          })
        )
    )
  );

export const requestReplaceSensorEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(REQUEST_REPLACE_SENSOR),
    map((action) => action.payload),
    switchMap((payload: ReplaceSensorPayload) =>
      ajax
        .post<MessageEntry<WlanSetupResultMessagePayload>>(
          `${MESSAGE_API}/sensor_status_replace`,
          payload,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map((res: any) => res.response),
          map(messageAction.loadReplaceSensorAckMessageAction),
          catchError((err: AjaxError) => {
            if (err.status === 408) {
              return of(
                messageAction.enableLidarNotRespondingAction({
                  mcu_id: payload.mcu_id,
                  vpu_id: payload.vpu_id,
                  command: payload.sensor_type,
                }),
                notificationAction.loadNotificationAction({
                  type: `REPLACE_SENSOR_FAILED`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: "MCU not responding",
                  content: `Replace ${payload.sensor_type} on ${payload.mcu_id}-${payload.vpu_id} failed`,
                  dur: 5000,
                  created: new Date().toISOString(),
                })
              );
            } else if (err.status === 400) {
              return of(
                messageAction.replaceSensorNotRespondingAction({
                  mcu_id: payload.mcu_id,
                  vpu_id: payload.vpu_id,
                  command: payload.sensor_type,
                }),
                notificationAction.loadNotificationAction({
                  type: `REPLACE_SENSOR_FAILED`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: "Replace Sensor Bad Request",
                  content: `Replace ${payload.sensor_type} on ${payload.mcu_id}-${payload.vpu_id} failed`,
                  dur: 5000,
                  created: new Date().toISOString(),
                })
              );
            }
            return of(messageAction.fetchMessageFailedAction(err));
          })
        )
    )
  );

export const requestSendConfigToOldDeviceEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(REQUEST_SEND_CONFIG_TO_OLD_DEVICE),
    map((action) => action.payload),
    switchMap((mcuId: string) =>
      ajax
        .getJSON<MessageEntry<any>>(`${MCU_API}/update_old_config/${mcuId}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          mergeMap((res) =>
            of(
              messageAction.loadSendConfigToDeviceAckMessageAction(res),
              notificationAction.loadNotificationAction({
                type: `SEND_CONFIG`,
                severity: NOTIFICATION_SEVERITY_SUCCESS,
                position: NOTIFICATION_BOTTOM,
                title: "Send Config",
                content: `config on ${mcuId} was updated`,
                dur: 5000,
                created: new Date().toISOString(),
              })
            )
          ),
          catchError((err: AjaxError) => {
            if (err.status === 408) {
              return of(
                messageAction.deviceNotRespondingAction({
                  mcu_id: mcuId,
                  command: "send_config",
                }),
                notificationAction.loadNotificationAction({
                  type: `SEND_CONFIG_FAILED`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: "MCU not responding",
                  content: `mcu ${mcuId} not responding`,
                  dur: 5000,
                  created: new Date().toISOString(),
                })
              );
            }
            return of(messageAction.fetchMessageFailedAction(err));
          })
        )
    )
  );

export const requestBsSshTunnelEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(REQUEST_BS_SSH_TUNNEL),
    map((action) => action.payload),
    switchMap((payload: SshTunnelPayload) =>
      ajax
        .post<MessageEntry<SSHTunnelMessagePayload>>(
          `${BASESTATION_API}/ssh_tunnel`,
          payload,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map((res: any) => res.response),
          map(messageAction.bsSshTunnelRequestedAction),
          catchError((err: AjaxError) => {
            if (err.status === 408) {
              return of(
                messageAction.deviceNotRespondingAction({
                  mcu_id: payload.id,
                  command: "ssh",
                }),
                notificationAction.loadNotificationAction({
                  type: `SSH_FAILED`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: "Base station not responding",
                  content: `ssh tunnel request to ${payload.id} failed`,
                  dur: 5000,
                  created: new Date().toISOString(),
                })
              );
            }
            return of(messageAction.fetchMessageFailedAction(err));
          })
        )
    )
  );

export const messageEpics = [
  // loadMessageEpic,
  changeOpModeEpic,
  requestMCUSshTunnelEpic,
  requestWlanSetupEpic,
  requestBsSshTunnelEpic,
  requestEnableLidarEpic,
  requestEnableSensorEpic,
  requestReplaceSensorEpic,
  requestSendConfigToOldDeviceEpic,
];
