import * as deviceMonitoringAction from "../actions/device_monitoring.action";
import { catchError, map, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import { ajax } from "rxjs/ajax";
import { MCU_API } from "../const/api";
import { getToken } from "../reducers";
import { RootState } from "../config/store";
import { HeartbeatDropoutEntry, ManyEntry } from "../const/types";

export const fetchManyHeartbeatDropoutListEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(deviceMonitoringAction.FETCH_MANY_HB_DROPOUTS),
    map((action) => action.payload),
    switchMap(() =>
      ajax
        .getJSON<ManyEntry<HeartbeatDropoutEntry>>(
          `${MCU_API}/system_failure`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(deviceMonitoringAction.loadManyHeartbeatDropoutsAction),
          catchError((err) =>
            of(deviceMonitoringAction.fetchHeartbeatDropoutFailedAction(err))
          )
        )
    )
  );

export const deviceMonitoringEpics = [fetchManyHeartbeatDropoutListEpic];
