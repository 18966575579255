import * as cabCameraAction from "../actions/cabcamera.action";
import { AnyAction } from "redux";
import { CabCameraEntry } from "../const/types";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  entries: CabCameraEntry[];
  count: number;
  selected: CabCameraEntry | null;
  loading: boolean;
}

const initialState: State = {
  entries: [],
  count: 0,
  selected: null,
  loading: false,
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case cabCameraAction.LOAD_MANY_CAB_CAMS: {
      const { rows, count } = action.payload;
      return { ...state, entries: rows, count, loading: false };
    }

    case cabCameraAction.FETCH_CAB_CAMS_FAILED:
    case FORBIDDEN_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getCabCameras = (state: State) => ({
  entries: state.entries,
  count: state.count,
});
