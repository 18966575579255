import * as notificationAction from "../actions/notification.action";
import { Observable } from "rxjs";
import { AnyAction } from "redux";
import { ofType } from "redux-observable";
import { ignoreElements, map, tap } from "rxjs/operators";
import { ALERT_NOTIFICATION_ENABLED } from "../const/const";

export const toggleAlertNotificationsEpic = (
  action$: Observable<AnyAction>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(notificationAction.TOGGLE_ALERT_NOTIFICATIONS),
    map((action) => action.payload),
    tap((enabled: boolean) =>
      localStorage.setItem(ALERT_NOTIFICATION_ENABLED, enabled.toString())
    ),
    ignoreElements()
  );

export const notificationEpics = [toggleAlertNotificationsEpic];
