/**
 * Action types
 */
import { ManyEntry, VpuConfig, VpuEntry, VpuQs } from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_MANY_VPUS = Symbol.for("FETCH_MANY_VPUS");
export const FETCH_SINGLE_VPU = Symbol.for("FETCH_SINGLE_VPU");
export const FETCH_VPU_CONFIGS = Symbol.for("FETCH_VPU_CONFIGS");
export const LOAD_VPU_CONFIGS = Symbol.for("LOAD_VPU_CONFIGS");
export const UPDATE_VPU_CONFIGS = Symbol.for("UPDATE_VPU_CONFIGS");
export const FETCH_VPUS_FAILED = Symbol.for("FETCH_VPUS_FAILED");
export const LOAD_MANY_VPUS = Symbol.for("LOAD_MANY_VPUS");

/**
 * Actions
 */
export function fetchManyVpuAction(qs: VpuQs) {
  return {
    type: FETCH_MANY_VPUS,
    payload: qs,
  };
}

export function fetchVpuFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_VPUS_FAILED,
    payload: err.toString(),
  };
}

export function loadManyVpuAction(payload: ManyEntry<VpuEntry>) {
  return {
    type: LOAD_MANY_VPUS,
    payload,
  };
}

export function fetchVpuConfigsAction(id: string, isRubberTire?: boolean) {
  return {
    type: FETCH_VPU_CONFIGS,
    payload: { id, isRubberTire },
  };
}

export function loadVpuConfigsAction(
  config: VpuConfig,
  isRubberTire?: boolean
) {
  return {
    type: LOAD_VPU_CONFIGS,
    payload: {
      config,
      isRubberTire,
    },
  };
}

export function updateVpuConfigsAction(id: string, body: any) {
  return {
    type: UPDATE_VPU_CONFIGS,
    payload: {
      id,
      body,
    },
  };
}
