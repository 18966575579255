import * as mcuAction from "../actions/mcu.action";
import {
  catchError,
  debounce,
  first,
  map,
  mergeMap,
  switchMap,
} from "rxjs/operators";
import { Observable, of, timer, EMPTY } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import {
  FleetSearchQs,
  ManyEntry,
  McuEntry,
  OnlineAlert,
  SetAudibleAlertPayload,
  SetOnlineNotificationPayload,
} from "../const/types";
import { ajax } from "rxjs/ajax";
import { MCU_API } from "../const/api";
import { getSelectedMcu, getToken } from "../reducers";
import { joinQueryStr } from "../helper/util";
import { RootState } from "../config/store";
import * as notificationAction from "../actions/notification.action";
import { NOTIFICATION_SEVERITY_SUCCESS } from "../const/const";
import { NOTIFICATION_BOTTOM } from "../const/ui";

export const fetchManyMcusEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(mcuAction.FETCH_MANY_MCUS),
    map((action) => action.payload),
    debounce((qs: FleetSearchQs) =>
      timer(qs.search_value.length > 0 ? 500 : 100)
    ),
    switchMap((qs: FleetSearchQs) =>
      ajax
        .getJSON<ManyEntry<McuEntry>>(`${MCU_API}?${joinQueryStr(qs)}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(mcuAction.loadManyMcuAction),
          catchError((err) => of(mcuAction.fetchMcuFailedAction(err)))
        )
    )
  );

export const fetchSingleMcuEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(mcuAction.FETCH_SINGLE_MCU),
    map((action) => action.payload),
    switchMap((id: string) =>
      ajax
        .getJSON<McuEntry>(
          `${MCU_API}/${id}?with_online_alert=true&with_modem=true`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(mcuAction.loadSingleMcuAction),
          catchError((err) => of(mcuAction.fetchMcuFailedAction(err)))
        )
    )
  );

export const fetchSelectedMcuAgainEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(mcuAction.FETCH_SINGLE_MCU_AGAIN),
    map(() => state$.value.mcu.selected.id),
    switchMap((id: string) => {
      if (id !== "") {
        return ajax
          .getJSON<McuEntry>(
            `${MCU_API}/${id}?with_online_alert=true&with_modem=true`,
            {
              Authorization: `Bearer ${getToken(state$.value)}`,
            }
          )
          .pipe(
            map(mcuAction.loadSingleMcuAction),
            catchError((err) => of(mcuAction.fetchMcuFailedAction(err)))
          );
      } else {
        return EMPTY;
      }
    })
  );

export const fetchMcuConfigsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(mcuAction.FETCH_MCU_CONFIGS),
    map((action) => action.payload),
    switchMap((id: string) =>
      ajax
        .getJSON<McuEntry[]>(`${MCU_API}/get_configs?ids=["${id}"]`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(mcuAction.loadMcuConfigsAction),
          catchError((err) => of(mcuAction.fetchMcuFailedAction(err)))
        )
    )
  );

export const uninstallMcuEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(mcuAction.UNINSTALL_MCU),
    map((action) => action.payload),
    switchMap((id: string) =>
      ajax
        .get<McuEntry>(`${MCU_API}/uninstall/${id}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map((res: any) => res.response),
          mergeMap((res: any) => {
            const mcu = getSelectedMcu(state$.value).entry;
            const reqType = mcu?.installed
              ? mcu?.maintenance
                ? "Uninstallation"
                : "Setting maintenance mode"
              : "Re-Init request";
            return of(
              mcuAction.updateMcuUninstalledAction(res),
              notificationAction.loadNotificationAction({
                type: `UNINSTALL/RE_INIT`,
                severity: NOTIFICATION_SEVERITY_SUCCESS,
                position: NOTIFICATION_BOTTOM,
                title: reqType,
                content: `${reqType} was successful`,
                dur: 5000,
                created: new Date().toISOString(),
              })
            );
          }),
          catchError((err) => of(mcuAction.fetchMcuFailedAction(err)))
        )
    )
  );

export const setOnlineNotificationEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(mcuAction.SET_ONLINE_NOTIFICATION),
    map((action) => action.payload),
    switchMap((payload: SetOnlineNotificationPayload) =>
      ajax
        .put<OnlineAlert>(
          `${MCU_API}/online_notification/${payload.id}`,
          {
            enable: payload.enable,
            online_alert_id: payload.online_alert_id,
            note: payload.note,
          },
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map((res) => res.response),
          mergeMap((res) =>
            of(
              payload.enable
                ? mcuAction.loadOnlineNotificationAction(res)
                : mcuAction.unloadOnlineNotificationAction(),
              notificationAction.loadNotificationAction({
                type: `ONLINE_NOTIFICATION`,
                severity: NOTIFICATION_SEVERITY_SUCCESS,
                position: NOTIFICATION_BOTTOM,
                title: `Online notification`,
                content: `Online notification was ${
                  payload.enable ? "enabled" : "disabled"
                }`,
                dur: 5000,
                created: new Date().toISOString(),
              })
            )
          ),
          catchError((err) => of(mcuAction.fetchMcuFailedAction(err)))
        )
    )
  );

export const setAudibleAlertEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(mcuAction.SET_AUDIBLE_ALERTS),
    map((action) => action.payload),
    switchMap((payload: SetAudibleAlertPayload) =>
      ajax
        .put<SetAudibleAlertPayload>(
          `${MCU_API}/audible_alerts/${payload.id}`,
          { audible_alerts: payload.audible_alerts },
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map((res) => res.response),
          mergeMap((res) =>
            of(
              mcuAction.loadAudibleAlertAction(res),
              notificationAction.loadNotificationAction({
                type: `AUDIBLE ALERTS`,
                severity: NOTIFICATION_SEVERITY_SUCCESS,
                position: NOTIFICATION_BOTTOM,
                title: `Audible alert`,
                content: `Audible alert was ${
                  res.audible_alerts ? "set" : "unset"
                }`,
                dur: 5000,
                created: new Date().toISOString(),
              })
            )
          ),
          catchError((err) => of(mcuAction.fetchMcuFailedAction(err)))
        )
    )
  );

export const fetchRegionsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(mcuAction.FETCH_REGIONS),
    first(),
    switchMap(() =>
      ajax
        .getJSON<string[]>(`${MCU_API}/get_regions`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(mcuAction.loadRegionsAction),
          catchError((err) => of(mcuAction.fetchMcuFailedAction(err)))
        )
    )
  );

export const mcuEpics = [
  fetchManyMcusEpic,
  fetchSingleMcuEpic,
  fetchSelectedMcuAgainEpic,
  uninstallMcuEpic,
  fetchMcuConfigsEpic,
  fetchRegionsEpic,
  setAudibleAlertEpic,
  setOnlineNotificationEpic,
];
