import { Observable } from "rxjs";
import { AnyAction } from "redux";
import { ofType } from "redux-observable";
import * as settingsAction from "../actions/settings.action";
import { ignoreElements, map, tap } from "rxjs/operators";
import { DARK_MODE } from "../const/const";

export const enableDarkModeEpic = (
  action$: Observable<AnyAction>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(settingsAction.ENABLE_DARK_MODE),
    map((action) => action.payload),
    tap((enabled: boolean) =>
      localStorage.setItem(DARK_MODE, enabled.toString())
    ),
    ignoreElements()
  );

export const settingsEpics = [enableDarkModeEpic];
