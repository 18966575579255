import * as vpuAction from "../actions/vpu.action";
import { AnyAction } from "redux";
import { VpuConfig, VpuEntry } from "../const/types";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  entries: VpuEntry[];
  count: number;
  selected: VpuEntry | null;
  selectedConfig: VpuConfig | null;
  loading: boolean;
}

const initialState: State = {
  entries: [],
  count: 0,
  selected: null,
  selectedConfig: null,
  loading: false,
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case vpuAction.LOAD_MANY_VPUS: {
      const { rows, count } = action.payload;
      return { ...state, entries: rows, count, loading: false };
    }

    case vpuAction.FETCH_VPU_CONFIGS: {
      if (state.selectedConfig?.VpuId !== action.payload) {
        return { ...state, selectedConfig: null };
      } else {
        return state;
      }
    }

    case vpuAction.LOAD_VPU_CONFIGS: {
      const { config, isRubberTire } = action.payload;
      if (isRubberTire) {
        const { vpuBumperOffset: uwb_bu_mm, ...rest } = config;
        return {
          ...state,
          selectedConfig: {
            uwb_bu_mm,
            ...rest,
          },
        };
      }
      return { ...state, selectedConfig: config };
    }

    case vpuAction.FETCH_VPUS_FAILED:
    case FORBIDDEN_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getVpus = (state: State) => ({
  entries: state.entries,
  count: state.count,
});
export const getSelectedVpuConfig = (state: State) => state.selectedConfig;
