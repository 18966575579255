/**
 * Action types
 */
import {
  FleetSearchQs,
  ManyEntry,
  McuEntry,
  OnlineAlert,
  SetAudibleAlertPayload,
  SetOnlineNotificationPayload,
} from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_MANY_MCUS = Symbol.for("FETCH_MANY_MCUS");
export const FETCH_REGIONS = Symbol.for("FETCH_REGIONS");
export const FETCH_SINGLE_MCU = Symbol.for("FETCH_SINGLE_MCU");
export const FETCH_SINGLE_MCU_AGAIN = Symbol.for("FETCH_SINGLE_MCU_AGAIN");
export const FETCH_MCUS_FAILED = Symbol.for("FETCH_MCUS_FAILED");
export const LOAD_SINGLE_MCU = Symbol.for("LOAD_SINGLE_MCU");
export const LOAD_MANY_MCUS = Symbol.for("LOAD_MANY_MCUS");
export const LOAD_REGIONS = Symbol.for("LOAD_REGIONS");
export const UNLOAD_SINGLE_MCU = Symbol.for("UNLOAD_SINGLE_MCU");
export const UNINSTALL_MCU = Symbol.for("UNINSTALL_MCU");
export const UPDATE_MCU_UNINSTALLED = Symbol.for("UPDATE_MCU_UNINSTALLED");
export const FETCH_MCU_CONFIGS = Symbol.for("FETCH_MCU_CONFIGS");
export const LOAD_MCU_CONFIGS = Symbol.for("LOAD_MCU_CONFIGS");
export const SET_ONLINE_NOTIFICATION = Symbol.for("SET_ONLINE_NOTIFICATION");
export const LOAD_ONLINE_NOTIFICATION = Symbol.for("LOAD_ONLINE_NOTIFICATION");
export const UNLOAD_ONLINE_NOTIFICATION = Symbol.for(
  "UNLOAD_ONLINE_NOTIFICATION"
);
export const SET_AUDIBLE_ALERTS = Symbol.for("SET_AUDIBLE_ALERTS");
export const LOAD_AUDIBLE_ALERTS = Symbol.for("LOAD_AUDIBLE_ALERTS");

/**
 * Actions
 */
export function fetchManyMcuAction(qs: FleetSearchQs) {
  return {
    type: FETCH_MANY_MCUS,
    payload: qs,
  };
}

export function fetchMcuFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_MCUS_FAILED,
    payload: err.toString(),
  };
}

export function loadManyMcuAction(payload: ManyEntry<McuEntry>) {
  return {
    type: LOAD_MANY_MCUS,
    payload,
  };
}

export function fetchSingleMcuAction(id: string) {
  return {
    type: FETCH_SINGLE_MCU,
    payload: id,
  };
}

export function fetchSelectedMcuAgainAction() {
  return {
    type: FETCH_SINGLE_MCU_AGAIN,
  };
}

export function loadSingleMcuAction(payload: McuEntry) {
  return {
    type: LOAD_SINGLE_MCU,
    payload,
  };
}

export function unloadSingleMcuAction() {
  return {
    type: UNLOAD_SINGLE_MCU,
  };
}

export function uninstallMcuAction(id: string) {
  return {
    type: UNINSTALL_MCU,
    payload: id,
  };
}

export function updateMcuUninstalledAction(payload: McuEntry) {
  return {
    type: UPDATE_MCU_UNINSTALLED,
    payload,
  };
}

export function fetchMcuConfigsAction(id: string) {
  return {
    type: FETCH_MCU_CONFIGS,
    payload: id,
  };
}

export function fetchRegionsAction() {
  return {
    type: FETCH_REGIONS,
  };
}

export function loadRegionsAction(payload: string[]) {
  return {
    type: LOAD_REGIONS,
    payload,
  };
}

export function loadMcuConfigsAction(payload: McuEntry[]) {
  return {
    type: LOAD_MCU_CONFIGS,
    payload,
  };
}

export function setOnlineNotificationAction(
  payload: SetOnlineNotificationPayload
) {
  return {
    type: SET_ONLINE_NOTIFICATION,
    payload,
  };
}

export function loadOnlineNotificationAction(payload: OnlineAlert) {
  return {
    type: LOAD_ONLINE_NOTIFICATION,
    payload,
  };
}

export function unloadOnlineNotificationAction() {
  return {
    type: UNLOAD_ONLINE_NOTIFICATION,
  };
}

export function setAudibleAlertAction(payload: SetAudibleAlertPayload) {
  return {
    type: SET_AUDIBLE_ALERTS,
    payload,
  };
}

export function loadAudibleAlertAction(payload: SetAudibleAlertPayload) {
  return {
    type: LOAD_AUDIBLE_ALERTS,
    payload,
  };
}
