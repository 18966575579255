// provider
import { Boundary, MapCenterZoom, SwDeploymentStatus, TrackQs } from "./types";

export const APP_ID = "lccas-dashboard";
export const LOCAL = "local";
export const AMTRAK_AD = "amtrak-ad";
export const PIPER_AD = "piper-ad";
export const VERSION = "1.32";

// map
export const DEFAULT_MAP: MapCenterZoom = {
  center:
    process.env.NODE_ENV === "development"
      ? {
          lat: 32.77990667403977,
          lng: -117.22231390510139,
        }
      : {
          lat: 40.46058101,
          lng: -74.43283376,
        },
  zoom: process.env.NODE_ENV === "development" ? 19 : 10,
  sw: {
    lat: 40.403773917,
    lng: -74.52390521,
  },
  ne: {
    lat: 40.56075901,
    lng: -74.30610179,
  },
};
export const DEFAULT_FLEET_MAP: MapCenterZoom = {
  center: {
    lat: 40.46058101,
    lng: -74.43283376,
  },
  zoom: 10,
};
export const DEFAULT_BASESTATION_MAP: MapCenterZoom = {
  center: {
    lat: 40.46058101,
    lng: -74.43283376,
  },
  zoom: 10,
};
export const DEFAULT_EQUIPMENT_MAP: MapCenterZoom = {
  center: {
    lat: 40.46058101,
    lng: -74.43283376,
  },
  zoom: 10,
};
export const MIN_ZOOM_LEVEL_FOR_TRACK_DATA = 15;
export const MIN_ZOOM_LEVEL_FOR_NATIONAL_ROUTE_DATA = 9;
export const MAX_QUERY_RADIUS = 10000;

// time
export const ONE_SECOND = 1e3;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const DEFAULT_MCU_MAP_STREAM_INTERVAL = 10000;
export const DEFAULT_RECENT_ENTRY_COLOR_DUR = 1e3 * 60;
export const DEFAULT_NOTIFICATION_DUR = 1e3 * 20;
export const DEFAULT_FLEET_STREAM_INTVL = 1e3 * 10;
export const DEFAULT_NEW_FLEET_STREAM_INTVL = ONE_MINUTE;
export const AD_ID_TOKEN_REFRESH_INTVL = 1e3 * 60 * 59; // 59 minutes
export const TRACK_FETCH_DEBOUNCE_TIME = 1500;
export const ROUTE_FETCH_DEBOUNCE_TIME = 500;
export const COMPONENT_SEARCH_DEBOUNCE_TIME = 500;
export const DEFAULT_SEARCH_DEBOUNCE_TIME = 500;

// navigation
export const DRAWER_WIDTH = 240;
export const PER_PAGE_TEN = 10;
export const PER_PAGE_TWENTY = 20;

// table
export const ASC = "asc";
export const DESC = "desc";

// boolean
export const YES = "YES";
export const NO = "NO";

// notification
export const NOTIFICATION_SEVERITY_ERROR = "error";
export const NOTIFICATION_SEVERITY_WARNING = "warning";
export const NOTIFICATION_SEVERITY_INFO = "info";
export const NOTIFICATION_SEVERITY_SUCCESS = "success";

// installation/message status
export const PENDING = "PENDING";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const CANCELED = "CANCELED";

// mcu
export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const INSTALLED = "installed";
export const UNINSTALLED = "uninstalled";
export const TOTAL = "total";

// qs action type
export const PAGE_CHANGE = "PAGE_CHANGE";
export const SORT_CHANGE = "SORT_CHANGE";
export const SELECT_CHANGE = "SELECT_CHANGE";
export const RESET_QS = "RESET_QS";

// message
export const SSH_TUNNEL_OPEN = "ssh_tunnel_open";
export const WLAN_SETUP_RESULT = "wlan_setup_result";

// basestation
export const ONLINE_BASESTATION_MSG_RATE = 3;
export const ONLINE = "online";
export const OFFLINE = "offline";
export const MAINTENANCE = "maintenance";

// notification toggle
export const ALERT_NOTIFICATION_ENABLED = "alertNotificationEnabled";

// string boolean
export const TRUE = "true";
export const FALSE = "false";

export const DEFAULT_TRK_QS: TrackQs = {
  latlon: "",
  radius: 0,
};
export const DEFAULT_ROUTE_BOUNDARY: Boundary = {
  ne: "",
  sw: "",
};

// fat
export const TESTS_PER_PAGE = 100;
export const TEST_RESULTS_PER_PAGE = 10;
export const SELECTED_FAT_COMPONENT = "selected_fat_component";

// settings
export const DARK_MODE = "darkMode";
export const TIME_ZONE = "timeZone";

// vpu config update
export const EDITABLE_FIELDS = [
  "vpuLocation",
  "vpuBumperOffset",
  "vpuCenterOffset",
  "vpuHeight",
  "lidarBumperOffset",
  "lidarCenterOffset",
  "lidarHeight",
  "lidarPitch",
  "lidarRoll",
  "lidarYaw",
  "tx_power",
  "imu_trigger",
  "imu_delta",
  "uwb_c_mm",
  "uwb_bu_mm",
  "uwb_h_mm",
  "lidar_c_mm",
  "lidar_bu_mm",
  "lidar_h_mm",
  "lidar_roll",
  "lidar_pitch",
  "lidar_yaw",
  "gps_location",
  "gps_c_mm",
  "gps_f_mm",
  "gps_b_mm",
  "gps_h_mm",
];

export const STATES: { [name: string]: string } = {
  Alabama: "AL",
  Alaska: "AK",
  "American Samoa": "AS",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District Of Columbia": "DC",
  "Federated States Of Micronesia": "FM",
  Florida: "FL",
  Georgia: "GA",
  Guam: "GU",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  "Marshall Islands": "MH",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Northern Mariana Islands": "MP",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Palau: "PW",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  "Virgin Islands": "VI",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

export const DEFAULT_DATE_FORMAT = "M/D/YYYY h:mm A";
export const FILENAME_DATE_FORMAT = "YYYY-MM-DD";
export const ISO_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
export const VIDEO_FILTER_REQUESTED = "video_filter_requested";
export const VIDEO_REQUEST_EMAIL = "video_request_email";
export const LAST_USER_ID = "last_user_id";

// dashboard
export const DEGRADED = "Degraded";
export const NORMAL = "Normal";
export const MCU_FAILURE = "MCU Failure";
export const MCU_WORKING = "MCU Running";

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const basestationRegex = /^[A-Z0-9_-]+$/;

// sw update
export const SW_UPDATE_COMPLETE_STATUSES = [
  SwDeploymentStatus.SUCCESS,
  SwDeploymentStatus.FAILURE,
  SwDeploymentStatus.CANCELED,
];

export const SW_UPDATE_CANCELLABLE_STATUSES = [
  SwDeploymentStatus.INITIATED,
  SwDeploymentStatus.BUILD_REQUESTED,
  SwDeploymentStatus.ACKED,
  SwDeploymentStatus.READY,
  SwDeploymentStatus.START_REQUESTED,
];

// sensor health
export const NEW_STATUS_FROM_PACKET_VERSION = 13;

// new status
export const HEALTH_OK = 0;
export const HEALTH_NOT_READY = 10;
export const HEALTH_USABLE = 20;
export const HEALTH_NOT_USABLE = 30;
export const HEALTH_DISABLED = 40;
export const HEALTH_FAILED = 50;
export const HEALTH_BAD_CONFIG = 60;
export const HEALTH_REPLACE = 86;

export const HEALTH_DIC: { [k: number]: string } = {
  [HEALTH_OK]: "OK",
  [HEALTH_NOT_READY]: "NOT READY",
  [HEALTH_USABLE]: "USABLE",
  [HEALTH_NOT_USABLE]: "NOT USABLE",
  [HEALTH_FAILED]: "FAILED",
  [HEALTH_REPLACE]: "REPLACE",
  [HEALTH_DISABLED]: "DISABLED",
  [HEALTH_BAD_CONFIG]: "BAD CONFIG",
};

export const NORM_STATUS_CODES = [0, 20];
export const NORM_GPS_UWB = [0, 20, 30];

export const NO_DATA = "- - -";
