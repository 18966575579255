/**
 * Action types
 */
import { ManyEntry, InstallationQs, InstallationEntry } from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_MANY_INSTALLATIONS = Symbol.for("FETCH_MANY_INSTALLATIONS");
export const FETCH_SINGLE_INSTALLATION = Symbol.for(
  "FETCH_SINGLE_INSTALLATION"
);
export const LOAD_SINGLE_INSTALLATION = Symbol.for("LOAD_SINGLE_INSTALLATION");
export const FETCH_INSTALLATIONS_FAILED = Symbol.for(
  "FETCH_INSTALLATIONS_FAILED"
);
export const LOAD_MANY_INSTALLATIONS = Symbol.for("LOAD_MANY_INSTALLATIONS");
export const DOWNLOAD_CSV = Symbol.for("DOWNLOAD_CSV");

/**
 * Actions
 */
export function fetchManyInstallationAction(qs: InstallationQs) {
  return {
    type: FETCH_MANY_INSTALLATIONS,
    payload: qs,
  };
}

export function fetchSingleInstallationAction(mcuId: string) {
  return {
    type: FETCH_SINGLE_INSTALLATION,
    payload: mcuId,
  };
}

export function loadSingleInstallationAction(payload: InstallationEntry) {
  return {
    type: LOAD_SINGLE_INSTALLATION,
    payload,
  };
}

export function fetchInstallationFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_INSTALLATIONS_FAILED,
    payload: err.toString(),
  };
}

export function loadManyInstallationAction(
  payload: ManyEntry<InstallationEntry>
) {
  return {
    type: LOAD_MANY_INSTALLATIONS,
    payload,
  };
}

export function downloadInstalledUnitCSVAction() {
  return {
    type: DOWNLOAD_CSV,
  };
}
