import { combineReducers, Reducer } from "redux";
import * as fromAuth from "./auth.reducer";
import * as fromMcu from "./mcu.reducer";
import * as fromVpu from "./vpu.reducer";
import * as fromVod from "./vod.reducer";
import * as fromVehicle from "./vehicle.reducer";
import * as fromInstallation from "./installation.reducer";
import * as fromUser from "./user.reducer";
import * as fromNotification from "./notification.reducer";
import * as fromAlert from "./alert.reducer";
import * as fromMessage from "./message.reducer";
import * as fromBasestation from "./basestation.reducer";
import * as fromTrack from "./track.reducer";
import * as fromFAT from "./fat.reducer";
import * as fromSettings from "./settings.reducer";
import * as fromSwUpdate from "./swupdate.reducer";
import * as fromDeviceMonitoring from "./device_monitoring.reducer";
import * as fromOTA from "./mender.reducer";
import * as fromVideo from "./video.reducer";
import * as fromSearch from "./search.reducer";
import * as fromCabCamera from "./cabcamera.reducer";
import * as fromWorkGang from "./workgang.reducer";
import * as fromMap from "./map.reducer";
import * as fromSearchBar from "./searchBar.reducer";
import * as fromTable from "./table.reducer";
import { createSelector } from "reselect";
import { RouterState } from "redux-first-history";
import { RootState } from "../config/store";

/**
 * root reduces
 */
const createRootReducer = (router: Reducer<RouterState>) =>
  combineReducers({
    router,
    auth: fromAuth.reducer,
    mcu: fromMcu.reducer,
    vpu: fromVpu.reducer,
    vod: fromVod.reducer,
    basestation: fromBasestation.reducer,
    vehicle: fromVehicle.reducer,
    installation: fromInstallation.reducer,
    user: fromUser.reducer,
    notification: fromNotification.reducer,
    alert: fromAlert.reducer,
    message: fromMessage.reducer,
    track: fromTrack.reducer,
    swUpdate: fromSwUpdate.reducer,
    fat: fromFAT.reducer,
    settings: fromSettings.reducer,
    deviceMonitoring: fromDeviceMonitoring.reducer,
    mender: fromOTA.reducer,
    video: fromVideo.reducer,
    search: fromSearch.reducer,
    cabCamera: fromCabCamera.reducer,
    workGang: fromWorkGang.reducer,
    mapData: fromMap.reducer,
    searchBar: fromSearchBar.reducer,
    table: fromTable.reducer,
  });
export default createRootReducer;

/**
 * selectors
 */

// auth
export const getAuthState = (state: RootState) => state.auth;
export const getToken = createSelector(getAuthState, fromAuth.getToken);
export const getLoginError = createSelector(
  getAuthState,
  fromAuth.getLoginError
);
export const getAuthLoading = createSelector(
  getAuthState,
  fromAuth.getAuthLoading
);
export const getMe = createSelector(getAuthState, fromAuth.getMe);
export const getAllowedVersions = createSelector(
  getAuthState,
  fromAuth.getAllowedVersions
);

// router
export const getRouterState = (state: RootState) => state.router;
export const getLocation = createSelector(
  getRouterState,
  (state) => state.location
);

// mcu
export const getMcuState = (state: RootState) => state.mcu;
export const getMcus = createSelector(getMcuState, fromMcu.getMcus);
export const getMcuLoading = createSelector(getMcuState, fromMcu.getMcuLoading);
export const getSelectedMcu = createSelector(
  getMcuState,
  fromMcu.getSelectedMcu
);
export const getRegions = createSelector(getMcuState, fromMcu.getRegions);

// basestation
export const getBasestationState = (state: RootState) => state.basestation;
export const getBasestations = createSelector(
  getBasestationState,
  fromBasestation.getBasestations
);
export const getSelectedBasestations = createSelector(
  getBasestationState,
  fromBasestation.getSelectedBasestations
);

// vehicle
export const getVehicleState = (state: RootState) => state.vehicle;
export const getVehicles = createSelector(
  getVehicleState,
  fromVehicle.getVehicles
);
export const getServiceAndVehicleTypes = createSelector(
  getVehicleState,
  fromVehicle.getServiceAndVehicleTypes
);
export const getVehicleTypes = createSelector(
  getVehicleState,
  fromVehicle.getVehicleTypes
);

// installation
export const getInstallationState = (state: RootState) => state.installation;
export const getInstallations = createSelector(
  getInstallationState,
  fromInstallation.getInstallations
);
export const getSelectedInstallation = createSelector(
  getInstallationState,
  fromInstallation.getSelectedInstallation
);

// user
export const getUserState = (state: RootState) => state.user;
export const getUsers = createSelector(getUserState, fromUser.getUsers);
export const getRoles = createSelector(getUserState, fromUser.getRoles);

// vpu
export const getVpuState = (state: RootState) => state.vpu;
export const getVpus = createSelector(getVpuState, fromVpu.getVpus);
export const getSelectedVpuConfig = createSelector(
  getVpuState,
  fromVpu.getSelectedVpuConfig
);

// workgang
export const getWorkGangStatus = (state: RootState) => state.workGang;
export const getWorkGangs = createSelector(
  getWorkGangStatus,
  fromWorkGang.getWorkGangs
);

// vod
export const getVodState = (state: RootState) => state.vod;
export const getVods = createSelector(getVodState, fromVod.getVods);

// cab camera
export const getCabCameraState = (state: RootState) => state.cabCamera;
export const getCabCameras = createSelector(
  getCabCameraState,
  fromCabCamera.getCabCameras
);

// notification
export const getNotificationState = (state: RootState) => state.notification;
export const getNotifications = createSelector(
  getNotificationState,
  fromNotification.getNotifications
);

// alert
export const getAlertState = (state: RootState) => state.alert;
export const getAlerts = createSelector(getAlertState, fromAlert.getAlerts);
export const getAlertQs = createSelector(getAlertState, fromAlert.getAlertQs);
export const getAlertTypes = createSelector(
  getAlertState,
  fromAlert.getAlertTypes
);
export const getAlertSearch = createSelector(
  getAlertState,
  fromAlert.getAlertSearch
);

// message
export const getMessageState = (state: RootState) => state.message;
export const getSshTunnels = createSelector(
  [getMessageState, (state: RootState, id: string) => id],
  fromMessage.getSshTunnels
);
export const getWlanSetup = createSelector(
  [getMessageState, (state: RootState, id: string) => id],
  fromMessage.getWlanSetup
);
export const getSendConfigState = createSelector(
  [getMessageState, (state: RootState, id: string) => id],
  fromMessage.getSendConfigState
);
export const getLidarEnabled = createSelector(
  [getMessageState, (state: RootState, id: string) => id],
  fromMessage.getLidarEnabled
);
export const getSensorEnabled = createSelector(
  [
    getMessageState,
    (state: RootState, id: string) => id,
    (state: RootState, id: string, sensor: string) => sensor,
  ],
  fromMessage.getSensorEnabled
);
export const getSensorReplace = createSelector(
  [
    getMessageState,
    (state: RootState, id: string) => id,
    (state: RootState, id: string, sensor: string) => sensor,
  ],
  fromMessage.getSensorReplace
);
export const getChangeOpModeState = createSelector(
  [getMessageState, (state: RootState, id: string) => id],
  fromMessage.getChangeOpModeState
);

// tracks
export const getTrackState = (state: RootState) => state.track;
export const getTracks = createSelector(getTrackState, fromTrack.getTracks);
export const getAssets = createSelector(getTrackState, fromTrack.getAssets);
export const getNationalRoute = createSelector(
  getTrackState,
  fromTrack.getNationalRoute
);
export const getNationalRouteBoundaries = createSelector(
  getTrackState,
  fromTrack.getNationalRouteBoundaries
);

// sw updates
export const getSwUpdateState = (state: RootState) => state.swUpdate;
export const getSwManifests = createSelector(
  getSwUpdateState,
  fromSwUpdate.getSwManifests
);
export const getSwDeployments = createSelector(
  getSwUpdateState,
  fromSwUpdate.getSwDeployments
);
export const getCurrentInstalledSw = createSelector(
  getSwUpdateState,
  fromSwUpdate.getCurrentInstalledSw
);
export const getSwDeploymentsForSelected = createSelector(
  getSwUpdateState,
  fromSwUpdate.getSwDeploymentsForSelected
);
export const getSwReleases = createSelector(
  getSwUpdateState,
  fromSwUpdate.getSwReleases
);
export const getSwUpdateLoading = createSelector(
  getSwUpdateState,
  fromSwUpdate.getSwUpdateLoading
);
export const getCreateSwUpdate = createSelector(
  getSwUpdateState,
  fromSwUpdate.getCreateSwManifest
);

// fat
export const getFATState = (state: RootState) => state.fat;
export const getFATComponentList = createSelector(
  getFATState,
  fromFAT.getFATComponentList
);
export const getFATests = createSelector(getFATState, fromFAT.getFATests);
export const getSelectedComponent = createSelector(
  getFATState,
  fromFAT.getSelectedComponent
);
export const getTestResultQs = createSelector(
  getFATState,
  fromFAT.getTestResultQs
);
export const getTestResults = createSelector(
  getFATState,
  fromFAT.getTestResults
);
export const getComponentSearchResults = createSelector(
  getFATState,
  fromFAT.getComponentSearchResults
);
export const getSearchLoading = createSelector(
  getFATState,
  fromFAT.getSearchLoading
);
export const getSearchComponentInput = createSelector(
  getFATState,
  fromFAT.getSearchComponentInput
);

// settings
export const getSettings = (state: RootState) => state.settings;
export const getDarkMode = createSelector(
  getSettings,
  fromSettings.getDarkMode
);

// dev monitoring
export const getDeviceMonitoring = (state: RootState) => state.deviceMonitoring;
export const getHeartbeatDropouts = createSelector(
  getDeviceMonitoring,
  fromDeviceMonitoring.getHeartbeatDropouts
);

// mender
export const getMender = (state: RootState) => state.mender;
export const getCurrentRelease = createSelector(
  getMender,
  fromOTA.getCurrentRelease
);
export const getMenderDeployments = createSelector(
  getMender,
  fromOTA.getMenderDeployments
);

// video
export const getVideoState = (state: RootState) => state.video;
export const getVideos = createSelector(getVideoState, fromVideo.getVideos);
export const getAllVideos = createSelector(
  getVideoState,
  fromVideo.getAllVideos
);
export const getVideoLoading = createSelector(
  getVideoState,
  fromVideo.getLoading
);

export const getSpecificVideo = createSelector(
  [getVideoState, (state: RootState, id: string) => id],
  fromVideo.getSpecificVideo
);

export const getVideoUrls = createSelector(
  getVideoState,
  fromVideo.getVideoUrls
);
export const getEventUrls = createSelector(
  getVideoState,
  fromVideo.getEventUrls
);
export const getDownloadUrlLoading = createSelector(
  getVideoState,
  fromVideo.getLoading
);

// mainSearch
export const getSearchState = (state: RootState) => state.search;
export const getMainSearchState = createSelector(
  getSearchState,
  fromSearch.getMainSearchState
);
export const getDialogSearchState = createSelector(
  getSearchState,
  fromSearch.getDialogSearchState
);

//  LccasMap
export const getMapState = (state: RootState) => state.mapData;
export const getMap = createSelector(getMapState, fromMap.getMap);
export const getMapLoaded = createSelector(getMapState, fromMap.getMapLoaded);
export const getMapCenter = createSelector(getMapState, fromMap.getMapCenter);
export const getMapZoom = createSelector(getMapState, fromMap.getMapZoom);
export const getMapPoints = createSelector(getMapState, fromMap.getMapPoints);
export const getMapClusterPoints = createSelector(
  getMapState,
  fromMap.getMapClusterPoints
);
export const getMapLines = createSelector(getMapState, fromMap.getMapLines);
export const getMapTracks = createSelector(getMapState, fromMap.getMapTracks);
export const getMapNatRoutes = createSelector(
  getMapState,
  fromMap.getMapNatRoutes
);
export const getMapMileposts = createSelector(
  getMapState,
  fromMap.getMapMileposts
);
export const getMapBoundsFunc = createSelector(
  getMapState,
  fromMap.getMapBoundsFunc
);
export const getMapCenterFunc = createSelector(
  getMapState,
  fromMap.getMapCenterFunc
);
export const getMapZoomFunc = createSelector(
  getMapState,
  fromMap.getMapZoomFunc
);
export const getMapOnDragEndFunc = createSelector(
  getMapState,
  fromMap.getMapOnDragEndFunc
);

export const getSearchBarState = (state: RootState) => state.searchBar;
export const getSearchVal = createSelector(
  getSearchBarState,
  fromSearchBar.getSearchVal
);
export const getInputVal = createSelector(
  getSearchBarState,
  fromSearchBar.getInputVal
);
export const getSuggestions = createSelector(
  getSearchBarState,
  fromSearchBar.getSuggestions
);
export const getSearchPrefix = createSelector(
  getSearchBarState,
  fromSearchBar.getSearchPrefix
);

export const getTableState = (state: RootState) => state.table;
// Tabs
export const getTabs = createSelector(getTableState, fromTable.getTabs);
export const getTabProps = createSelector(getTableState, fromTable.getTabProps);
export const getTabStyle = createSelector(getTableState, fromTable.getTabStyle);
export const getSelectedTab = createSelector(
  getTableState,
  fromTable.getSelectedTab
);
//Pagination
export const getPagination = createSelector(
  getTableState,
  fromTable.getPagination
);
export const getPage = createSelector(getTableState, fromTable.getPage);
export const getRowsPerPage = createSelector(
  getTableState,
  fromTable.getRowsPerPage
);
export const getPaginationCount = createSelector(
  getTableState,
  fromTable.getPaginationCount
);
// Table Data
export const getTableHeaders = createSelector(
  getTableState,
  fromTable.getTableHeaders
);
export const getTableRows = createSelector(
  getTableState,
  fromTable.getTableRows
);
export const getSortDirection = createSelector(
  getTableState,
  fromTable.getSortDirection
);
export const getSortField = createSelector(
  getTableState,
  fromTable.getSortField
);
