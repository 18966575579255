import * as notificationAction from "../actions/notification.action";
import { AnyAction } from "redux";
import { AlertEntry, NotificationEntry } from "../const/types";
import { LOAD_TOKEN, LOG_OUT } from "../actions/auth.action";
import { AlertColor } from "@mui/material/Alert";
import {
  ALERT_NOTIFICATION_ENABLED,
  DEFAULT_NOTIFICATION_DUR,
  TRUE,
} from "../const/const";
import { NOTIFICATION_TOP_RIGHT } from "../const/ui";

export interface State {
  entries: NotificationEntry[];
  alertNotificationsEnabled: boolean;
}

const initialState: State = {
  entries: [],
  alertNotificationsEnabled:
    localStorage.getItem(ALERT_NOTIFICATION_ENABLED) === TRUE,
};

const AlertColorMap: { [key: string]: AlertColor } = {
  orange: "warning",
  red: "error",
  green: "success",
  blue: "info",
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case notificationAction.LOAD_NOTIFICATION: {
      return {
        ...state,
        entries: [...state.entries.slice(-2), action.payload],
      };
    }
    case notificationAction.TOGGLE_ALERT_NOTIFICATIONS: {
      return {
        entries: action.payload
          ? state.entries
          : state.entries.filter((n) => n.type !== "ALERT"),
        alertNotificationsEnabled: action.payload,
      };
    }
    case notificationAction.LOAD_NOTIFICATION_FROM_ALERT: {
      if (!state.alertNotificationsEnabled) return state;
      const { alert, title }: { alert: AlertEntry; title: string } =
        action.payload;
      return {
        ...state,
        entries: [
          ...state.entries.slice(-1),
          {
            type: "ALERT",
            severity: AlertColorMap[alert.color],
            position: NOTIFICATION_TOP_RIGHT,
            title,
            content: `${alert.McuId}: ${alert.message}`,
            dur: DEFAULT_NOTIFICATION_DUR,
            created: alert.createdAt,
          },
        ],
      };
    }

    case LOAD_TOKEN: {
      return initialState;
    }

    case LOG_OUT: {
      return {
        ...state,
        entries: [],
      };
    }

    case notificationAction.UNLOAD_NOTIFICATION: {
      const index: number = action.payload;
      return {
        ...state,
        entries: state.entries.filter((_, i) => i !== index),
      };
    }

    default:
      return state;
  }
}

/**
 * selector
 */
export const getNotifications = (state: State) => state.entries;
export const getAlertNotificationStatus = (state: State) =>
  state.alertNotificationsEnabled;
