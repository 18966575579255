import * as basestationAction from "../actions/basestation.action";
import { AnyAction } from "redux";
import { BasestationEntry } from "../const/types";
import { ONE_MINUTE } from "../const/const";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  entries: BasestationEntry[];
  selected: BasestationEntry | null;
  loading: boolean;
}

const initialState: State = {
  entries: [],
  selected: null,
  loading: false,
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case basestationAction.LOAD_ALL_BASESTATIONS: {
      const bss = action.payload as BasestationEntry[];
      bss.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        } else if (a.label < b.label) {
          return -1;
        } else {
          return 0;
        }
      });
      let selected = null;
      bss.forEach((bs) => {
        if (bs.gcs) {
          bs.position = {
            lat: bs.gcs.coordinates[1],
            lng: bs.gcs.coordinates[0],
          };
        }
        bs.avgMsgPerSec =
          bs.rtcmNum > 0 && +bs.rtcmTo > Date.now() - ONE_MINUTE * 5
            ? Math.round(bs.rtcmNum / ((+bs.rtcmTo - +bs.rtcmFrom) / 1000))
            : 0;

        if (state.selected && bs.id === state.selected.id) {
          selected = bs;
        }
      });

      return { ...state, entries: bss, selected, loading: false };
    }

    case basestationAction.SELECT_BASESTATION: {
      return { ...state, selected: action.payload };
    }

    case basestationAction.EDIT_BASESTATION_SUCCESS: {
      const { id } = action.payload as BasestationEntry;
      const idx = state.entries.findIndex(d => d.id === id);
      if (idx === -1) {
        return state;
      }
      const bsEntries = [
        ...state.entries.slice(0, idx),
        action.payload,
        ...state.entries.slice(idx + 1)
      ]
      return {...state, entries: bsEntries};
    }

    case basestationAction.FETCH_BASESTATIONS_FAILED:
    case FORBIDDEN_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getBasestations = (state: State) => state.entries;
export const getSelectedBasestations = (state: State) => state.selected;
