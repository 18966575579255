import * as mcuAction from "../actions/mcu.action";
import { AnyAction } from "redux";
import { McuEntry, SelectedMCU } from "../const/types";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  entries: McuEntry[];
  count: number;
  selected: SelectedMCU;
  loading: boolean;
  regions: string[];
}

const initialState: State = {
  entries: [],
  count: 0,
  selected: {
    id: "",
    entry: null,
  },
  loading: false,
  regions: [],
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case mcuAction.FETCH_MANY_MCUS: {
      return { ...state, loading: true };
    }

    case mcuAction.LOAD_MANY_MCUS: {
      const { rows, count } = action.payload;
      return { ...state, entries: rows, count, loading: false };
    }

    case mcuAction.FETCH_SINGLE_MCU: {
      return {
        ...state,
        selected: { id: action.payload, entry: null },
        loading: true,
      };
    }

    case mcuAction.LOAD_SINGLE_MCU: {
      if (action.payload) {
        return {
          ...state,
          selected: { id: action.payload.id, entry: action.payload },
          loading: false,
        };
      } else {
        return state;
      }
    }

    case mcuAction.LOAD_REGIONS: {
      return {
        ...state,
        loading: false,
        regions: action.payload,
      };
    }

    case mcuAction.UNLOAD_SINGLE_MCU: {
      return {
        ...state,
        selected: { entry: null, id: "" },
        loading: false,
      };
    }

    case mcuAction.UPDATE_MCU_UNINSTALLED: {
      let selected = state.selected;
      if (selected && selected.id === action.payload.id) {
        const { installed, needToReInit, maintenance } = action.payload;
        selected = {
          id: selected.id,
          entry: {
            ...selected.entry,
            id: selected.id,
            installed,
            needToReInit,
            maintenance,
          } as McuEntry,
        };
      }
      const idx = state.entries.findIndex((e) => e.id === action.payload.id);
      if (idx > -1) {
        const found = { ...state.entries[idx], installed: false };
        const entries = [
          state.entries.slice(0, idx),
          found,
          state.entries.slice(idx + 1),
        ];
        return {
          ...state,
          entries,
          selected,
        };
      } else {
        return {
          ...state,
          selected,
        };
      }
    }

    case mcuAction.LOAD_ONLINE_NOTIFICATION: {
      if (state.selected.id !== action.payload.McuId || !state.selected)
        return state;
      return {
        ...state,
        selected: {
          ...state.selected,
          entry: {
            ...state.selected.entry,
            OnlineAlerts: [action.payload],
          },
        },
        loading: false,
      };
    }

    case mcuAction.UNLOAD_ONLINE_NOTIFICATION: {
      return {
        ...state,
        selected: {
          ...state.selected,
          entry: {
            ...state.selected.entry,
            OnlineAlerts: [],
          },
        },
        loading: false,
      };
    }

    case mcuAction.LOAD_AUDIBLE_ALERTS: {
      if (state.selected.id !== action.payload.id || !state.selected)
        return state;
      return {
        ...state,
        selected: {
          ...state.selected,
          entry: {
            ...state.selected.entry,
            audibleAlerts: action.payload.audible_alerts,
          },
        },
        loading: false,
      };
    }

    case mcuAction.FETCH_MCUS_FAILED:
    case FORBIDDEN_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getMcus = (state: State) => ({
  entries: state.entries,
  count: state.count,
});

export const getSelectedMcu = (state: State) => state.selected;
export const getRegions = (state: State) => state.regions;
export const getMcuLoading = (state: State) => state.loading;
