/**
 * Action types
 */
import {
  BasestationEntry,
  EditBasestationPayload
} from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";
import { loadNotificationAction } from "./notification.action";
import { NOTIFICATION_BOTTOM } from "../const/ui";
import { NOTIFICATION_SEVERITY_ERROR } from "../const/const"; 

export const FETCH_ALL_BASESTATIONS = Symbol.for("FETCH_ALL_BASESTATIONS");
export const FETCH_BASESTATIONS_FAILED = Symbol.for(
  "FETCH_BASESTATIONS_FAILED"
);
export const LOAD_ALL_BASESTATIONS = Symbol.for("LOAD_ALL_BASESTATIONS");
export const SELECT_BASESTATION = Symbol.for("SELECT_BASESTATION");
export const EDIT_BASESTATION = Symbol.for("EDIT_BASESTATION");
export const EDIT_BASESTATION_FAILED = Symbol.for("EDIT_BASESTATION_FAILED");
export const EDIT_BASESTATION_SUCCESS = Symbol.for("EDIT_BASESTATION_SUCCESS");

/**
 * Actions
 */
export function fetchAllBasestationAction() {
  return {
    type: FETCH_ALL_BASESTATIONS,
  };
}

export function fetchBasestationFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_BASESTATIONS_FAILED,
    payload: err.toString(),
  };
}

export function loadAllBasestationAction(payload: BasestationEntry[]) {
  return {
    type: LOAD_ALL_BASESTATIONS,
    payload,
  };
}

export function selectBasestationAction(payload: BasestationEntry | null) {
  return {
    type: SELECT_BASESTATION,
    payload,
  };
}

export function editBasestationAction(payload: EditBasestationPayload) {
  return {
    type: EDIT_BASESTATION,
    payload
  };
}

export function editBasestationFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return loadNotificationAction({
    type: "EDIT BASESTATION",
    severity: NOTIFICATION_SEVERITY_ERROR,
    position: NOTIFICATION_BOTTOM,
    title: "Edit Basestation Failed",
    content: err.response.errors.reduce((acc: string, er: any) => {
      acc += er.msg;
      return acc;
    }, ""),
    dur: 5000,
    created: new Date().toISOString(),
  })
}

export function editBasestationSuccessAction(payload: any) {
  return {
    type: EDIT_BASESTATION_SUCCESS,
    payload
  };
}
