import React from "react";
import TitleComponent from "../../components/common/TitleComponent";
import { Typography } from "@mui/material";

const FormDPage = () => {
  return (
    <>
      <TitleComponent title={"formd"} />
      <Typography align={"center"} variant={"h5"}>
        Work in progress
      </Typography>
    </>
  );
};
export default FormDPage;
