import { AlertEntry, NotificationEntry } from "../const/types";

export const LOAD_NOTIFICATION = Symbol.for("LOAD_NOTIFICATION");

export const LOAD_NOTIFICATION_FROM_ALERT = Symbol.for(
  "LOAD_NOTIFICATION_FROM_ALERT"
);
export const UNLOAD_NOTIFICATION = Symbol.for("UNLOAD_NOTIFICATION");

export const TOGGLE_ALERT_NOTIFICATIONS = Symbol.for(
  "TOGGLE_ALERT_NOTIFICATIONS"
);

/**
 * Actions
 */
export function loadNotificationAction(n: NotificationEntry) {
  return {
    type: LOAD_NOTIFICATION,
    payload: n,
  };
}

export function unloadNotificationAction(index: number) {
  return {
    type: UNLOAD_NOTIFICATION,
    payload: index,
  };
}

export function loadNotificationFromAlertAction(
  alert: AlertEntry,
  title: string
) {
  return {
    type: LOAD_NOTIFICATION_FROM_ALERT,
    payload: { alert, title },
  };
}

export function toggleAlertNotificationsAction(status: boolean) {
  return {
    type: TOGGLE_ALERT_NOTIFICATIONS,
    payload: status,
  };
}
