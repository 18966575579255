import * as searchAction from "../actions/search.action";
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
} from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import { ajax } from "rxjs/ajax";
import { getSearchState, getToken } from "../reducers";
import { RootState } from "../config/store";
import { ManyEntry, SearchDeviceActionPayload } from "../const/types";
import { getSearchUrl } from "../helper/util";

export const setDeviceSearchInputEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(searchAction.SET_DEVICE_SEARCH_INPUT),
    debounceTime(500),
    map((action) => action.payload),
    filter(
      (payload: SearchDeviceActionPayload) =>
        payload.value.length >= 3 &&
        !getSearchState(state$.value)[payload.component].entries.includes(
          payload.value
        )
    ),
    map(searchAction.searchDeviceAction)
  );

export const searchDeviceEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(searchAction.SEARCH_DEVICE),
    map((action) => action.payload),
    switchMap((payload: SearchDeviceActionPayload) => {
      return ajax
        .getJSON<ManyEntry<string>>(getSearchUrl(payload), {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map((res) =>
            searchAction.loadDeviceSearchResultsAction(payload.component, res)
          ),
          catchError((err) => {
            return of(
              searchAction.loadDeviceSearchResultsAction(payload.component, {
                count: 0,
                rows: [],
              })
            );
          })
        );
    })
  );

export const searchEpics = [setDeviceSearchInputEpic, searchDeviceEpic];
