/**
 * Action types
 */
import {
  AssetEntry,
  Boundary,
  NationalRouteEntry,
  TrackEntry,
  TrackQs,
} from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_TRACKS = Symbol.for("FETCH_TRACKS");
export const FETCH_NATIONAL_ROUTE = Symbol.for("FETCH_NATIONAL_ROUTE");
export const FETCH_NATIONAL_ROUTE_BOUNDARIES = Symbol.for(
  "FETCH_NATIONAL_ROUTE_BOUNDARIES"
);
export const FETCH_TRACKS_FAILED = Symbol.for("FETCH_TRACKS_FAILED");
export const LOAD_TRACKS = Symbol.for("LOAD_TRACKS");
export const LOAD_ASSETS = Symbol.for("LOAD_ASSETS");
export const LOAD_NATIONAL_ROUTE = Symbol.for("LOAD_NATIONAL_ROUTE");
export const LOAD_NATIONAL_ROUTE_BOUNDARIES = Symbol.for(
  "LOAD_NATIONAL_ROUTE_BOUNDARIES"
);

/**
 * Actions
 */
export function fetchTrackAction(
  qs: TrackQs,
  debounce: number,
  withAssets = false
) {
  return {
    type: FETCH_TRACKS,
    payload: { qs, debounce, withAssets },
  };
}

export function fetchNationalRouteAction(boundary: Boundary, debounce: number) {
  return {
    type: FETCH_NATIONAL_ROUTE,
    payload: { boundary, debounce },
  };
}

export function fetchNationalRouteBoundariesAction() {
  return {
    type: FETCH_NATIONAL_ROUTE_BOUNDARIES,
  };
}

export function fetchTrackFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_TRACKS_FAILED,
    payload: err.toString(),
  };
}

export function loadTracksAction(payload: TrackEntry[]) {
  return {
    type: LOAD_TRACKS,
    payload,
  };
}

export function loadAssetsAction(payload: AssetEntry[]) {
  return {
    type: LOAD_ASSETS,
    payload,
  };
}

export function loadNationalRouteAction(payload: NationalRouteEntry[]) {
  return {
    type: LOAD_NATIONAL_ROUTE,
    payload,
  };
}

export function loadNationalRouteBoundariesAction(
  payload: NationalRouteEntry[]
) {
  return {
    type: LOAD_NATIONAL_ROUTE_BOUNDARIES,
    payload,
  };
}
