import * as workGangAction from "../actions/workgang.action";
import { AnyAction } from "redux";
import { WorkGangEntry } from "../const/types";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  entries: WorkGangEntry[];
  count: number;
  selected: WorkGangEntry | null;
  loading: boolean;
}

const initialState: State = {
  entries: [],
  count: 0,
  selected: null,
  loading: false,
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case workGangAction.LOAD_MANY_WORK_GANGS: {
      const { rows, count } = action.payload;
      return { ...state, entries: rows, count, loading: false };
    }

    case workGangAction.CHANGE_WORK_GANG_STATUS: {
      const { id, active } = action.payload;
      const idx = state.entries.findIndex((wg) => wg.id === id);
      if (idx === -1) return state;
      if (
        state.entries.length > 0 &&
        state.entries.some((wg) => wg.active === active)
      ) {
        return state;
      }
      return {
        ...state,
        entries: [
          ...state.entries.slice(0, idx),
          ...state.entries.slice(idx + 1),
        ],
      };
    }

    case workGangAction.FETCH_WORK_GANGS_FAILED:
    case FORBIDDEN_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getWorkGangs = (state: State) => ({
  entries: state.entries,
  count: state.count,
});
