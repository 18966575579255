import { AccountInfo } from "@azure/msal-common";
import { AjaxError } from "rxjs/ajax";

/**
 * Action types
 */
export const LOAD_TOKEN = Symbol.for("LOAD_TOKEN");
export const LOGIN = Symbol.for("LOGIN");
export const LOG_IN_ERROR = Symbol.for("LOG_IN_ERROR");
export const LOG_OUT = Symbol.for("LOG_OUT");
export const AFTER_LOG_OUT = Symbol.for("AFTER_LOG_OUT");
export const GET_TOKEN_FROM_SESSION_STORAGE = Symbol.for(
  "GET_TOKEN_FROM_SESSION_STORAGE"
);
export const STORE_TOKEN_FROM_SESSION_STORAGE = Symbol.for(
  "STORE_TOKEN_FROM_SESSION_STORAGE"
);
export const TOKEN_NOT_FOUND = Symbol.for("TOKEN_NOT_FOUND");
export const FETCH_MY_INFO = Symbol.for("FETCH_MY_INFO");
export const FETCH_MY_INFO_FAILED = Symbol.for("FETCH_MY_INFO_FAILED");
export const LOAD_MY_INFO = Symbol.for("LOAD_MY_INFO");
export const LOGIN_WITH_AZURE_AD = Symbol.for("LOGIN_WITH_AZURE_AD");
export const LOGIN_WITH_AZURE_AD_FAILED = Symbol.for(
  "LOGIN_WITH_AZURE_AD_FAILED"
);
export const ACQUIRE_AD_ID_TOKEN = Symbol.for("ACQUIRE_AD_ID_TOKEN");
export const ACQUIRE_AD_ID_TOKEN_FAILED = Symbol.for(
  "ACQUIRE_AD_ID_TOKEN_FAILED"
);
export const UNAUTHORIZED_ERROR = Symbol.for("UNAUTHORIZED_ERROR");
export const FORBIDDEN_ERROR = Symbol.for("FORBIDDEN_ERROR");
export const REFRESH_AD_TOKEN = Symbol.for("REFRESH_AD_TOKEN");
export const TYPE_PASSWORD = Symbol.for("TYPE_PASSWORD");
export const SUBMIT_PASSWORD_CHANGE = Symbol.for("SUBMIT_PASSWORD_CHANGE");
export const CLOSE_PASSWORD_DIALOG = Symbol.for("CLOSE_PASSWORD_DIALOG");
export const OPEN_PASSWORD_DIALOG = Symbol.for("OPEN_PASSWORD_DIALOG");
export const CHECK_VERSION = Symbol.for("CHECK_VERSION");
export const LOAD_VERSIONS = Symbol.for("LOAD_VERSIONS");

/**
 * Actions
 */
export function acquireADIDTokenAction(account: AccountInfo) {
  return {
    type: ACQUIRE_AD_ID_TOKEN,
    payload: { account },
  };
}

export function acquireADIDTokenFailedAction(err: Error) {
  return {
    type: ACQUIRE_AD_ID_TOKEN_FAILED,
    payload: err.toString(),
  };
}

export function loginWithAzureAdFailedAction(err: Error) {
  return {
    type: LOGIN_WITH_AZURE_AD_FAILED,
    payload: err.toString(),
    error: true,
  };
}

export function loadTokenAction(token: any) {
  return {
    type: LOAD_TOKEN,
    payload: token, // token: string
  };
}

export function fetchMyInfoAction(token: any, path?: string) {
  return {
    type: FETCH_MY_INFO,
    payload: { token, path },
  };
}

export function fetchMyInfoFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_MY_INFO_FAILED,
    payload: err,
  };
}

export function loadMyInfoAction(info: any) {
  return {
    type: LOAD_MY_INFO,
    payload: info,
  };
}

export function loginAction(credential: any) {
  return {
    type: LOGIN,
    payload: credential, // credential: {email: string, password: string}
  };
}

export function handleLogInError(err: any) {
  return {
    type: LOG_IN_ERROR,
    payload: err,
  };
}

export function logoutAction() {
  return {
    type: LOG_OUT,
  };
}

export function getTokenFromSessionStorageAction() {
  return {
    type: GET_TOKEN_FROM_SESSION_STORAGE,
  };
}

export function storeTokenInSessionStorageAction(token: any) {
  return {
    type: STORE_TOKEN_FROM_SESSION_STORAGE,
    payload: token, //string
  };
}

export function unauthorizedErrorAction(err: AjaxError) {
  return {
    type: UNAUTHORIZED_ERROR,
    payload: err,
  };
}

export function forbiddenErrorAction(err: AjaxError) {
  return {
    type: FORBIDDEN_ERROR,
    payload: err,
  };
}

export function tokenNotFoundAction() {
  return {
    type: TOKEN_NOT_FOUND,
  };
}

export function refreshADTokenAction(
  account: AccountInfo | null,
  exp: Date | null
) {
  // exp in seconds
  return {
    type: REFRESH_AD_TOKEN,
    payload: { account, exp },
  };
}

export function typePasswordAction(
  oldPassword: string,
  newPassword: string,
  confirmPassword: string
) {
  return {
    type: TYPE_PASSWORD,
    payload: { oldPassword, newPassword, confirmPassword },
  };
}

export function submitPasswordChangeAction() {
  return {
    type: SUBMIT_PASSWORD_CHANGE,
  };
}

export function openPasswordDialogAction() {
  return {
    type: OPEN_PASSWORD_DIALOG,
  };
}

export function closePasswordDialogAction() {
  return {
    type: CLOSE_PASSWORD_DIALOG,
  };
}

export function checkVersionAction() {
  return {
    type: CHECK_VERSION,
  };
}

export function loadVersionsAction(payload: {
  minAppVersion: string;
  maxAppVersion: string;
}) {
  return {
    type: LOAD_VERSIONS,
    payload,
  };
}
