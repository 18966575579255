import * as swUpdateActions from "../actions/swupdate.action";
import { AnyAction } from "redux";
import {
  SWManifestEntry,
  SWDeploymentEntry,
  SWReleaseEntry,
  SWDeploymentChangeEntry,
  McuEntry,
} from "../const/types";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  manifests: {
    entries: SWManifestEntry[];
    count: number;
    selected: SWManifestEntry | null;
  };
  createSwManifest: {
    open: boolean;
    loading: false;
  };
  releases: {
    entries: SWReleaseEntry[];
    count: number;
  };
  deployments: SWDeploymentEntry[];
  mcus: McuEntry[];
  deploymentsForSelected: {
    entries: SWDeploymentEntry[];
    count: number;
  };
  loading: boolean;
}

const initialState: State = {
  manifests: {
    entries: [],
    count: 0,
    selected: null,
  },
  createSwManifest: {
    open: false,
    loading: false,
  },
  deployments: [],
  mcus: [],
  deploymentsForSelected: {
    entries: [],
    count: 0,
  },
  releases: {
    entries: [],
    count: 0,
  },
  loading: false,
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case swUpdateActions.LOAD_SW_MANIFEST: {
      return {
        ...state,
        manifests: {
          ...state.manifests,
          selected: {
            ...action.payload,
            SwDeployment: state.manifests.selected?.SwDeployment,
          },
        },
        loading: false,
      };
    }

    case swUpdateActions.LOAD_MANY_SW_MANIFESTS: {
      const { rows, count } = action.payload;
      return {
        ...state,
        manifests: { ...state.manifests, entries: rows, count },
        loading: false,
      };
    }

    case swUpdateActions.SELECT_SW_UPDATE: {
      return {
        ...state,
        manifests: {
          ...state.manifests,
          selected: action.payload,
        },
      };
    }

    case swUpdateActions.OPEN_CREATE_SW_UPDATE_DIALOG: {
      return {
        ...state,
        createSwUpdate: {
          ...state.createSwManifest,
          open: action.payload,
          loading: false,
        },
      };
    }

    case swUpdateActions.CREATE_SW_MANIFEST: {
      return {
        ...state,
        createSwUpdate: {
          ...state.createSwManifest,
          loading: true,
        },
      };
    }

    case swUpdateActions.FETCH_MANY_SW_MANIFEST: {
      return { ...state, loading: true };
    }

    case swUpdateActions.LOAD_LATEST_SW_DEPLOYMENTS: {
      return { ...state, deployments: action.payload };
    }

    case swUpdateActions.LOAD_CURRENT_INSTALLED_SW: {
      return {
        ...state,
        mcus: action.payload.map((m: any) => ({
          ...m,
          mode: "",
          menderId: "",
        })),
      };
    }

    case swUpdateActions.LOAD_MANY_SW_RELEASE: {
      return {
        ...state,
        releases: {
          entries: action.payload.rows.sort(
            (a: SWReleaseEntry, b: SWReleaseEntry) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          ),
          count: action.payload.count,
        },
      };
    }

    case swUpdateActions.LOAD_MANY_SW_DEPLOYMENTS_FOR_SELECTED_MCU: {
      return {
        ...state,
        deploymentsForSelected: {
          entries: action.payload.rows,
          count: action.payload.count,
        },
      };
    }

    case swUpdateActions.LOAD_SW_DEPLOYMENT_CHANGE: {
      const {
        id,
        status,
        version,
        created_at: createdAt,
        updated_at: updatedAt,
        sw_manifest_id: SwManifestId,
        mcu_id: McuId,
      } = action.payload as SWDeploymentChangeEntry;
      const idx = state.deployments.findIndex((d) => d.McuId === McuId);
      if (idx === -1) {
        return state;
      }

      const deployments = [
        ...state.deployments.slice(0, idx),
        { id, status, version, createdAt, updatedAt, SwManifestId, McuId },
        ...state.deployments.slice(idx + 1),
      ];
      return {
        ...state,
        deployments,
      };
    }

    case swUpdateActions.FETCH_SW_UPDATE_FAILED:
    case FORBIDDEN_ERROR:
      return {
        ...state,
        createSwManifest: { open: false, laoding: false },
        loading: false,
      };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getSwReleases = (state: State) => state.releases;
export const getSwManifests = (state: State) => state.manifests;
export const getSwDeployments = (state: State) => state.deployments;
export const getCurrentInstalledSw = (state: State) => state.mcus;
export const getSwDeploymentsForSelected = (state: State) =>
  state.deploymentsForSelected;
export const getCreateSwManifest = (state: State) => state.createSwManifest;
export const getSwUpdateLoading = (state: State) => state.loading;
