import * as vpuAction from "../actions/vpu.action";
import {
  catchError,
  debounce,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
} from "rxjs/operators";
import { Observable, of, timer } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import { ManyEntry, VpuConfig, VpuEntry, VpuQs } from "../const/types";
import { ajax } from "rxjs/ajax";
import { VPU_API } from "../const/api";
import { getToken } from "../reducers";
import { joinQueryStr } from "../helper/util";
import { RootState } from "../config/store";
import {
  DEFAULT_SEARCH_DEBOUNCE_TIME,
  NOTIFICATION_SEVERITY_ERROR,
  NOTIFICATION_SEVERITY_SUCCESS,
} from "../const/const";
import * as notificationAction from "../actions/notification.action";
import { NOTIFICATION_BOTTOM } from "../const/ui";

export const fetchManyVpusEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(vpuAction.FETCH_MANY_VPUS),
    map((action) => action.payload),
    debounce((qs: VpuQs) =>
      timer(qs.search_value.length > 2 ? DEFAULT_SEARCH_DEBOUNCE_TIME : 0)
    ),
    map((qs: VpuQs) => {
      const { search_by, search_value, ...rest } = qs;
      if (!!search_by && !!search_value && search_value.length > 2) {
        return joinQueryStr(qs);
      } else {
        return joinQueryStr(rest);
      }
    }),
    distinctUntilChanged(),
    switchMap((qsStr: string) =>
      ajax
        .getJSON<ManyEntry<VpuEntry>>(`${VPU_API}?${qsStr}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(vpuAction.loadManyVpuAction),
          catchError((err) => of(vpuAction.fetchVpuFailedAction(err)))
        )
    )
  );

export const fetchVpuConfigsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(vpuAction.FETCH_VPU_CONFIGS),
    map((action) => action.payload),
    switchMap(({ id, isRubberTire }) =>
      ajax
        .getJSON<VpuConfig>(`${VPU_API}/get_configs/${id}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map((config) => vpuAction.loadVpuConfigsAction(config, isRubberTire)),
          catchError((err) => of(vpuAction.fetchVpuFailedAction(err)))
        )
    )
  );

export const updateVpuConfigsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(vpuAction.UPDATE_VPU_CONFIGS),
    map((action) => action.payload),
    switchMap(({ id, body }) =>
      ajax
        .put<VpuConfig>(`${VPU_API}/update_configs/${id}`, body, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map((res) => res.response),
          mergeMap((res) =>
            of(
              vpuAction.loadVpuConfigsAction(res),
              notificationAction.loadNotificationAction({
                type: `UPDATE_VPU_CONFIG_SUCCESS`,
                severity: NOTIFICATION_SEVERITY_SUCCESS,
                position: NOTIFICATION_BOTTOM,
                title: `VPU config update`,
                content: `configuration update for ${id} was successful`,
                dur: 10000,
                created: new Date().toISOString(),
              })
            )
          ),
          catchError((err) => {
            if (err.status === 400) {
              return of(
                notificationAction.loadNotificationAction({
                  type: `UPDATE_VPU_CONFIG_FAILURE`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: `VPU config update`,
                  content: `configuration update for ${id} failed`,
                  dur: 10000,
                  created: new Date().toISOString(),
                })
              );
            }
            return of(vpuAction.fetchVpuFailedAction(err));
          })
        )
    )
  );

export const vpuEpics = [
  fetchManyVpusEpic,
  fetchVpuConfigsEpic,
  updateVpuConfigsEpic,
];
