import * as videoAction from "../actions/video.action";

import { AnyAction } from "redux";
import { VideoEntry } from "../const/types";
import { SignedURLType } from "../const/types";
import { DEFAULT_VIDEO_QS } from "../const/ui";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  entries: VideoEntry[];
  allEntries: { [key: string]: VideoEntry };
  count: number;
  selected: VideoEntry | null;
  loading: boolean;
  video: {
    [id: string]: string[];
  };
  event: {
    [id: string]: string;
  };
}

const initialState: State = {
  entries: [],
  allEntries: {},
  count: 0,
  selected: null,
  loading: false,
  video: {},
  event: {},
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case videoAction.CREATE_VIDEO_REQUEST:
    case videoAction.FETCH_SINGLE_VIDEO: {
      return { ...state, loading: true };
    }
    case videoAction.FETCH_MANY_VIDEOS: {
      return { ...state, loading: true };
    }

    case videoAction.LOAD_MANY_VIDEOS: {
      const { rows, count } = action.payload;
      const newEntries = rows.reduce(
        (prev: any, curr: VideoEntry) => ({ ...prev, [curr.id]: curr }),
        {}
      );
      return {
        ...state,
        entries: rows,
        allEntries: { ...state.allEntries, ...newEntries },
        count,
        loading: false,
      };
    }

    case videoAction.LOAD_SINGLE_VIDEO: {
      if (!action.payload) {
        return state;
      }
      const newEntry = {
        ...state.allEntries[action.payload.id],
        ...action.payload,
      };
      return {
        ...state,
        entries: [
          newEntry,
          ...state.entries.filter((e) => e.id !== newEntry.id),
        ],
        allEntries: {
          ...state.allEntries,
          [action.payload.id]: newEntry,
        },
        loading: false,
      };
    }

    case videoAction.FETCH_SIGNED_URL_FOR_VIDEO_DOWNLOAD: {
      return { ...state, loading: true };
    }

    case videoAction.PROCESS_VIDEO_REQUEST: {
      return { ...state, loading: true };
    }

    case videoAction.LOAD_SIGNED_URL_FOR_DOWNLOAD: {
      const { id, urls, url, type } = action.payload;
      return {
        ...state,
        loading: false,
        [type as SignedURLType]: {
          ...state[type as SignedURLType],
          [id]: urls || url,
        },
      };
    }

    case videoAction.FETCH_SIGNED_URL_FAILED:
    case videoAction.FETCH_VIDEO_FAILED:
    case FORBIDDEN_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getVideos = (state: State) => ({
  entries: state.entries.slice(0, DEFAULT_VIDEO_QS().limit),
  count: state.count,
});
export const getAllVideos = (state: State) => state.allEntries;
export const getSpecificVideo = (state: State, id: string) =>
  state.allEntries[id];
export const getLoading = (state: State) => state.loading;
export const getVideoUrls = (state: State) => state.video;
export const getEventUrls = (state: State) => state.event;
