import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import {
  closePasswordDialogAction,
  openPasswordDialogAction,
  typePasswordAction,
  submitPasswordChangeAction,
  logoutAction,
} from "../../actions/auth.action";
import { toggleAlertNotificationsAction } from "../../actions/notification.action";
import { RootState } from "../../config/store";
import { getAuthState, getNotificationState } from "../../reducers";
import { Role } from "../../const/types";
import { Transition } from "../../components/common/Transition";

const AccountPage: React.FC<Props> = ({
  me,
  oldPassword,
  newPassword,
  confirmPassword,
  passwordSameAsOld,
  passwordNotMatching,
  dialogIsOpen,
  canSubmit,
  alertNotificationsEnabled,
  toggleAlertNotifications,
  openDialog,
  closeDialog,
  setPassword,
  submitPassword,
  dispatchLogout,
}) => {
  return (
    <>
      <List>
        <ListItem>
          <ListItemText primary="Name" secondary={me.name} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Email" secondary={me.email} />
        </ListItem>
        {me.provider === "local" && (
          <ListItem>
            <Button variant="contained" onClick={openDialog}>
              Change Password
            </Button>
          </ListItem>
        )}
        <ListItem>
          {me.Role?.label !== Role.AMTRAK && (
            <FormGroup>
              <FormControlLabel
                label="Alert Notifications"
                control={
                  <Switch
                    checked={alertNotificationsEnabled}
                    value="switch"
                    onChange={() =>
                      toggleAlertNotifications(!alertNotificationsEnabled)
                    }
                  />
                }
              />
            </FormGroup>
          )}
        </ListItem>
        <ListItem>
          <Button variant="contained" onClick={dispatchLogout}>
            Logout
          </Button>
        </ListItem>
      </List>
      <Dialog
        open={dialogIsOpen}
        onClose={closeDialog}
        sx={{ width: "300px", height: "600px", margin: "auto" }}
        TransitionComponent={Transition}
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <Stack spacing={1} marginTop="10px">
            <TextField
              required
              type="password"
              label="Current Password"
              helperText=" "
              value={oldPassword}
              onChange={(ev) => {
                setPassword(ev.target.value, newPassword, confirmPassword);
              }}
            ></TextField>
            <TextField
              required
              error={passwordSameAsOld}
              helperText={
                passwordSameAsOld
                  ? "New password is the same as current password"
                  : " "
              }
              type="password"
              label="New Password"
              value={newPassword}
              onChange={(ev) =>
                setPassword(oldPassword, ev.target.value, confirmPassword)
              }
            ></TextField>
            <TextField
              required
              error={passwordNotMatching}
              helperText={passwordNotMatching ? "Passwords do not match" : " "}
              type="password"
              label="Confirm New Password"
              value={confirmPassword}
              onChange={(ev) =>
                setPassword(oldPassword, newPassword, ev.target.value)
              }
            ></TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
          <Button disabled={!canSubmit} onClick={submitPassword}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  ...getAuthState(state),
  ...getNotificationState(state),
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPassword: (
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ) => dispatch(typePasswordAction(oldPassword, newPassword, confirmPassword)),
  openDialog: () => dispatch(openPasswordDialogAction()),
  closeDialog: () => dispatch(closePasswordDialogAction()),
  submitPassword: () => dispatch(submitPasswordChangeAction()),
  toggleAlertNotifications: (status: boolean) =>
    dispatch(toggleAlertNotificationsAction(status)),
  dispatchLogout: () => dispatch(logoutAction()),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(React.memo(AccountPage));
type Props = ConnectedProps<typeof connector>;
