import {
  DocumentScanner,
  Engineering,
  MapTwoTone,
  People,
  PrecisionManufacturing,
  SystemUpdate,
  TableRows,
  HeartBroken,
  VideoCameraBackOutlined,
} from "@mui/icons-material";
import {
  AlertIcon,
  BasestationIcon,
  DashboardIcon,
  EquipmentIcon,
  FleetIcon,
  MCUIcon,
  VpuIcon,
  VodIcon,
  CabCameraIcon,
  WorkIcon,
  SettingsIcon,
  AnchorIcon,
} from "../components/common/customIcons";
import {
  AlertQs,
  Align,
  BooleanInternal,
  FleetSearchQs,
  InstallationQs,
  McuStatus,
  NotificationPosition,
  Pagination,
  QueryString,
  Role,
  SearchQueryString,
  SideMenuItem,
  SwManifestQs,
  TableHeadRow,
  TestResultQs,
  UserQs,
  VehicleQs,
  VideoQs,
  VodQs,
  VpuQs,
  WorkGangQs,
  Yard,
  Yards,
  McuView,
} from "./types";
import { green, grey, purple, red, yellow } from "@mui/material/colors";
import { SortDirection } from "@mui/material/TableCell/TableCell";
import {
  ACTIVE,
  AMTRAK_AD,
  ASC,
  DESC,
  INACTIVE,
  PENDING,
  PER_PAGE_TEN,
  TEST_RESULTS_PER_PAGE,
  TESTS_PER_PAGE,
  TOTAL,
  UNINSTALLED,
  VIDEO_FILTER_REQUESTED,
  YES,
  HEALTH_OK,
  HEALTH_NOT_READY,
  HEALTH_USABLE,
  HEALTH_NOT_USABLE,
  HEALTH_FAILED,
  HEALTH_REPLACE,
  HEALTH_DISABLED,
  HEALTH_BAD_CONFIG,
} from "./const";
import { SxProps } from "@mui/system";
import { Typography } from "@mui/material";
import * as React from "react";
import { formatDate } from "../helper/util";
import dayjs from "dayjs";

// Navigation
export const ALL = "ALL";
export const ROLES = [
  Role.USER,
  Role.FOREMAN,
  Role.OPERATOR,
  Role.ADMIN,
  Role.BS_MONITOR,
  Role.BUILDER,
  Role.BASESTATION,
  Role.PICOAPP,
  Role.ASSEMBLER,
  Role.AMTRAK,
  Role.SUPERUSER,
  Role.SUPER_FOREMAN,
];
export const EXCEPT_ASSEMBLER = ROLES.filter((r) => r !== Role.ASSEMBLER);
export const INTERNAL: (Role | undefined)[] = ROLES.filter(
  (r) => r !== Role.ASSEMBLER && r !== Role.AMTRAK
);
export const ADMIN_LIKE: (Role | undefined)[] = [Role.ADMIN, Role.SUPERUSER];
export const FAT = [Role.PICOAPP, Role.ADMIN, Role.SUPERUSER, Role.ASSEMBLER];

export const DASHBOARD_PATH = "__dashboard";
export const FLEET_PATH = "fleet";
export const MCU_PATH = "__mcu";
export const VPU_PATH = "__vpu";
export const VOD_PATH = "__vod";
export const CAB_CAM_PATH = "__cabcamera";
export const VIDEO_PATH = "__video";
export const BASESTATION_PATH = "__basestation";
export const EQUIPMENT_PATH = "__equipment";
export const INSTALLATION_PATH = "__installation";
export const FORM_D_PATH = "__formd";
export const FAT_PATH = "__fat";
export const ALERT_PATH = "__alert";
export const USER_PATH = "__user";
export const DEVICE_MONITORING_PATH = "__monitoring";
export const WORK_GANG_PATH = "__work_gang";
export const SW_UPDATE_PATH = "__sw_update";
export const SW_MANIFEST_PATH = "__sw_manifest";
export const ANCHOR_PATH = "__anchor";
export const VEHICLE_PATH = "__vehicle";
export const FORBIDDEN_PATH = "/forbidden";
export const LOGIN_PATH = "/login";

export const DASHBOARD_PAGE: SideMenuItem = {
  label: "Dashboard",
  icon: DashboardIcon,
  path: DASHBOARD_PATH,
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
};
export const FLEET_PAGE: SideMenuItem = {
  label: "Fleet",
  icon: FleetIcon,
  path: FLEET_PATH,
  allowedTo: EXCEPT_ASSEMBLER,
  providersBlocked: [],
};
export const MCU_PAGE: SideMenuItem = {
  label: "MCU",
  icon: MCUIcon,
  path: MCU_PATH,
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
};
export const VPU_PAGE: SideMenuItem = {
  label: "VPU",
  icon: VpuIcon,
  path: VPU_PATH,
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
};
export const VOD_PAGE: SideMenuItem = {
  label: "VOD",
  icon: VodIcon,
  path: VOD_PATH,
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
};
export const CAB_CAM_PAGE: SideMenuItem = {
  label: "Cab Camera",
  icon: CabCameraIcon,
  path: CAB_CAM_PATH,
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
};
export const VIDEO_PAGE: SideMenuItem = {
  label: "VIDEO",
  icon: VideoCameraBackOutlined,
  path: VIDEO_PATH,
  allowedTo: ADMIN_LIKE,
  providersBlocked: [AMTRAK_AD],
};
export const BASESTATION_PAGE: SideMenuItem = {
  label: "Base Station",
  icon: BasestationIcon,
  path: BASESTATION_PATH,
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
};
export const ANCHOR_PAGE: SideMenuItem = {
  label: "Anchors",
  icon: AnchorIcon,
  path: ANCHOR_PATH,
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
};
export const VEHICLE_PAGE: SideMenuItem = {
  label: "Vehicles",
  icon: EquipmentIcon,
  path: VEHICLE_PATH,
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
};
export const EQUIPMENT_PAGE: SideMenuItem = {
  label: "Equipment",
  icon: EquipmentIcon,
  path: EQUIPMENT_PATH,
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
  children: [
    MCU_PAGE,
    VPU_PAGE,
    VOD_PAGE,
    CAB_CAM_PAGE,
    BASESTATION_PAGE,
    ANCHOR_PAGE,
    VEHICLE_PAGE,
  ],
};
export const INSTALLATION_PAGE: SideMenuItem = {
  label: "Installation",
  icon: Engineering,
  path: INSTALLATION_PATH,
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
};
export const FORM_D_PAGE: SideMenuItem = {
  label: "FormD",
  icon: DocumentScanner,
  path: FORM_D_PATH,
  allowedTo: ADMIN_LIKE,
  providersBlocked: [AMTRAK_AD],
};
export const FAT_PAGE: SideMenuItem = {
  label: "FAT",
  icon: PrecisionManufacturing,
  path: FAT_PATH,
  allowedTo: FAT,
  providersBlocked: [AMTRAK_AD],
};
export const ALERT_PAGE: SideMenuItem = {
  label: "Alert",
  icon: AlertIcon,
  path: ALERT_PATH,
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
};
export const USERS_PAGE: SideMenuItem = {
  label: "Users",
  icon: People,
  path: USER_PATH,
  allowedTo: ADMIN_LIKE,
  providersBlocked: [AMTRAK_AD],
};
export const WORK_GANG_PAGE: SideMenuItem = {
  label: "Work Gang",
  icon: WorkIcon,
  path: WORK_GANG_PATH,
  allowedTo: ADMIN_LIKE,
  providersBlocked: [AMTRAK_AD],
};
export const SW_UPDATE_PAGE: SideMenuItem = {
  label: "Software Update",
  icon: SystemUpdate,
  path: SW_UPDATE_PATH,
  allowedTo: ADMIN_LIKE,
  providersBlocked: [AMTRAK_AD],
};
export const TOOLS_PAGE: SideMenuItem = {
  label: "Tools",
  icon: SettingsIcon,
  path: "",
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
  children: process.env.REACT_APP_TEST_SERVER
    ? [SW_UPDATE_PAGE, VIDEO_PAGE]
    : [SW_UPDATE_PAGE, VIDEO_PAGE, FAT_PAGE],
};
export const DEVICE_MONITORING_PAGE: SideMenuItem = {
  label: "Fleet Monitoring",
  icon: HeartBroken,
  path: DEVICE_MONITORING_PATH,
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
  children: [ALERT_PAGE, INSTALLATION_PAGE, USERS_PAGE, WORK_GANG_PAGE],
};
export const SETTINGS_PAGE: SideMenuItem = {
  label: "Settings",
  icon: SettingsIcon,
  path: "/account",
  allowedTo: INTERNAL,
  providersBlocked: [AMTRAK_AD],
};

const DEFAULT_SIDE_MENU = [
  DASHBOARD_PAGE,
  FLEET_PAGE,
  EQUIPMENT_PAGE,
  DEVICE_MONITORING_PAGE,
  TOOLS_PAGE,
  SETTINGS_PAGE,
];

export const ASSEMBLER_SIDE_MENU: SideMenuItem[] = [FAT_PAGE];

export const SIDE_MENU: SideMenuItem[] = DEFAULT_SIDE_MENU;

export const OPERATION_MODES = ["park", "work", "travel"];

// installation
export const INSTALLATION_TABLE_HEAD: TableHeadRow[] = [
  { prop: "id", align: "left", label: "id", sort: true },
  { prop: "Mcu", align: "left", label: "MCU S/N", sort: true },
  { prop: "McuId", align: "left", label: "MCU ID", sort: true },
  { prop: "VehicleId", align: "left", label: "Vehicle", sort: true },
  { prop: "vpuIds", align: "left", label: "VPU", sort: false },
  { prop: "vodIds", align: "left", label: "VOD", sort: false },
  { prop: "status", align: "left", label: "Status", sort: false },
  {
    prop: "configVerified",
    align: "left",
    label: "Configuration",
    sort: false,
  },
  {
    prop: "telemetryVerified",
    align: "left",
    label: "Telemetry",
    sort: false,
  },
  {
    prop: "vehicleAssigned",
    align: "left",
    label: "Vehicle Install",
    sort: false,
  },
  { prop: "lidarCalibration", align: "left", label: "Lidar", sort: false },

  { prop: "uwbVerified", align: "left", label: "UWB", sort: false },
  { prop: "updatedAt", align: "left", label: "Updated", sort: true },
];

export const STATUS_TABLE_COLOR = {
  ACTIVE: green["700"],
  INACTIVE: red["500"],
  UNINSTALLED: yellow["900"],
  TOTAL: grey["900"],
};

// mcu
export const MCU_TABLE_PAGE_TABS = [
  {
    title: TOTAL as McuStatus,
    to: TOTAL,
    color: STATUS_TABLE_COLOR.TOTAL,
  },
  {
    title: ACTIVE as McuStatus,
    to: ACTIVE,
    color: STATUS_TABLE_COLOR.ACTIVE,
  },
  {
    title: INACTIVE as McuStatus,
    to: INACTIVE,
    color: STATUS_TABLE_COLOR.INACTIVE,
  },
  {
    title: UNINSTALLED as McuStatus,
    to: UNINSTALLED,
    color: STATUS_TABLE_COLOR.UNINSTALLED,
  },
];

export const MCU_TABLE_HEAD: TableHeadRow[] = [
  { prop: "Vehicle", align: "left", label: "Vehicle", sort: true },
  { prop: "sn", align: "left", label: "S/N", sort: false },
  { prop: "id", align: "left", label: "ID", sort: true },
  { prop: "installed", align: "left", label: "Installed", sort: false },
  { prop: "mode", align: "left", label: "OP Mode", sort: false },
  { prop: "swVersion", align: "left", label: "SW Ver", sort: false },
  { prop: "hwVersion", align: "left", label: "HW Ver", sort: false },
  { prop: "city", align: "left", label: "City", sort: false },
  { prop: "region", align: "left", label: "State", sort: false },
  { prop: "speed", align: "left", label: "Speed (km/h)", sort: false },
  { prop: "health", align: "left", label: "Network", sort: false },
  { prop: "gpsState", align: "left", label: "GPS Alarm", sort: false },
  { prop: "uwb", align: "left", label: "UWB Alarm", sort: false },
  { prop: "lidar", align: "left", label: "Lidar Alarm", sort: false },
  { prop: "updatedAt", align: "left", label: "Updated", sort: true },
];

export const MCU_TABLE_HEAD2: TableHeadRow[] = [
  { prop: "Vehicle.id", align: "left", label: "Vehicle ID", sort: true },
  { prop: "sn", align: "left", label: "S/N", sort: false },
  { prop: "id", align: "left", label: "ID", sort: true },
  {
    prop: "installed",
    align: "center",
    label: "Installed",
    sort: false,
    icon: true,
  },
  { prop: "mode", align: "left", label: "OP Mode", sort: false },
  { prop: "sw", align: "left", label: "SW Ver", sort: false },
  { prop: "hwVersion", align: "left", label: "HW Ver", sort: false },
  { prop: "city", align: "left", label: "City", sort: false },
  { prop: "region", align: "left", label: "State", sort: false },
  { prop: "speed", align: "left", label: "Speed (km/h)", sort: false },
  {
    prop: "network",
    align: "center",
    label: "Network",
    sort: false,
    icon: true,
  },
  { prop: "ssd", align: "center", label: "SSD", sort: false, icon: true },
  { prop: "gps", align: "center", label: "GPS Alarm", sort: false, icon: true },
  { prop: "uwb", align: "center", label: "UWB Alarm", sort: false, icon: true },
  {
    prop: "lidar",
    align: "center",
    label: "Lidar Alarm",
    sort: false,
    icon: true,
  },
  { prop: "updatedAt", align: "left", label: "Updated", sort: true },
];

export const FLEET_TABLE_HEAD: TableHeadRow[] = [
  { prop: "Vehicle.id", align: "left", label: "Equipment ID", sort: true },
  { prop: "equipType", align: "left", label: "Equipment Type", sort: true },
  { prop: "mode", align: "left", label: "OP Mode", sort: false },
  { prop: "updatedAt", align: "left", label: "Last Active", sort: true },
  { prop: "online", align: "left", label: "Status", sort: false },
];

export const BASESTATION_TABLE_HEAD: TableHeadRow[] = [
  { prop: "label", align: "left", label: "Station", sort: true },
  { prop: "name", align: "left", label: "Mount Name", sort: true },
  { prop: "avgMsgPerSec", align: "left", label: "RTCM Rate", sort: true },
  {
    prop: "statusMessage",
    align: "center",
    label: "Health",
    sort: false,
    icon: true,
  },
];

export const BASESTATION_DETAILS_FIELDS = [
  "Lat",
  "Lon",
  "Alt",
  "Last Upd",
  "Idle",
  "RSRP",
  "RSRQ",
  "Temp",
  "Uptime",
  "Free Mem",
  "Sig Qual",
  "S/N",
  "PPP Start",
  "PPP End",
];

export const BASESTATION_COLLAPSE_TABLE_HEAD: TableHeadRow[] = [
  { prop: "gcs", align: "left", label: "Lat", sort: true },
  { prop: "name", align: "left", label: "Lon", sort: true },
  { prop: "alt", align: "left", label: "Alt", sort: true },
  { prop: "updatedAt", align: "left", label: "Last Upd", sort: true },
];

export const ANCHOR_TABLE_HEAD: TableHeadRow[] = [
  { prop: "anchorNo", align: "left", label: "No.", sort: true },
  { prop: "location", align: "left", label: "Location", sort: false },
  { prop: "", align: "left", label: "Last Seen", sort: false },
  { prop: "designator", align: "left", label: "Uptime (Hrs)", sort: false },
  { prop: "picoTest", align: "center", label: "Health", sort: false },
];

export const DEFAULT_FLEET_SEARCH_QS: FleetSearchQs = {
  limit: PER_PAGE_TEN,
  order: "updatedAt",
  dir: DESC as SortDirection,
  offset: 0,
  search_by: "vehicle_id",
  search_value: "",
  status: "total",
};

export const MCU_VIEWS: McuView[] = [
  { name: "table", icon: TableRows, to: "/__mcu/list", label: "list view" },
  { name: "map", icon: MapTwoTone, to: "/__mcu/map", label: "map view" },
];

// VEHICLE
export const VEHICLE_TABLE_HEAD: TableHeadRow[] = [
  { prop: "id", align: "left", label: "ID", sort: true },
  { prop: "Mcu.sn", align: "left", label: "MCU S/N", sort: true },
  { prop: "McuId", align: "left", label: "MCU ID", sort: true },
  { prop: "VehicleTypeId", align: "left", label: "Vehicle Type", sort: false },
  { prop: "ServiceTypeId", align: "left", label: "Service Type", sort: false },
  { prop: "isRubberTire", align: "left", label: "Rubber tire", sort: false },
  { prop: "createdAt", align: "left", label: "Created", sort: true },
  { prop: "edit", align: "center", label: "", sort: false },
];
export const VEHICLE_TYPE_TABLE_HEAD: TableHeadRow[] = [
  { prop: "id", align: "left", label: "ID", sort: true },
  { prop: "label", align: "left", label: "Name", sort: true },
];
export const DEFAULT_VEHICLE_QS: VehicleQs = {
  limit: PER_PAGE_TEN,
  order: "updatedAt",
  dir: DESC as SortDirection,
  offset: 0,
  installed: YES,
  with_mcu_sn: YES,
  query: "",
  raw: YES,
};

// INSTALLATION
export const DEFAULT_INSTALLATION_QS: InstallationQs = {
  limit: PER_PAGE_TEN,
  order: "updatedAt",
  dir: DESC as SortDirection,
  offset: 0,
  status: PENDING,
  mcu_sn: "",
};

// VPU
export const VPU_TABLE_HEAD: TableHeadRow[] = [
  { prop: "sn", align: "left", label: "S/N", sort: true },
  { prop: "id", align: "left", label: "ID", sort: true },
  { prop: "swVersion", align: "left", label: "SW Ver", sort: true },
  { prop: "hwVersion", align: "left", label: "HW Ver.", sort: true },
  { prop: "Mcu", align: "left", label: "MCU S/N", sort: true },
  { prop: "McuId", align: "left", label: "MCU ID", sort: true },
  { prop: "gps", align: "left", label: "GPS", sort: true, icon: true },
  { prop: "lidar", align: "left", label: "LIDAR", sort: true, icon: true },
  { prop: "uwb", align: "left", label: "UWB", sort: true, icon: true },
  { prop: "imu", align: "left", label: "IMU", sort: true, icon: true },
  { prop: "camera", align: "left", label: "CAMERA", sort: true, icon: true },
  { prop: "ssd0", align: "left", label: "SSD 0", sort: true, icon: true },
  { prop: "ssd1", align: "left", label: "SSD 1", sort: true, icon: true },
  { prop: "location", align: "left", label: "Direction", sort: true },
  { prop: "menderId", align: "left", label: "Config", sort: true },
  { prop: "createdAt", align: "left", label: "Created", sort: true },
  { prop: "updatedAt", align: "left", label: "Updated", sort: true },
];

export const VPU_DEVICE_COMPONENTS = ["gps", "lidar", "uwb", "imu", "camera"];

export const VPU_COMPONENT_STATUS_TABLE_HEAD: TableHeadRow[] = [
  { prop: "id", align: "center", label: "ID", sort: true },
  { prop: "sn", align: "center", label: "S/N", sort: true },
  ...VPU_DEVICE_COMPONENTS.map((component) => ({
    prop: component,
    align: "center" as Align,
    label: component.toUpperCase(),
    sort: true,
  })),
  { prop: "location", align: "center", label: "Direction", sort: true },
];

export const DEFAULT_VPU_QS: VpuQs = {
  limit: PER_PAGE_TEN,
  order: "updatedAt",
  dir: DESC as SortDirection,
  offset: 0,
  search_by: "sn",
  search_value: "",
};

// VOD
export const VOD_TABLE_HEAD: TableHeadRow[] = [
  { prop: "id", align: "left", label: "ID", sort: true },
  { prop: "status", align: "left", label: "Status", sort: false, icon: true },
  { prop: "Mcu.sn", align: "left", label: "MCU S/N", sort: false },
  { prop: "McuId", align: "left", label: "MCU ID", sort: true },
  { prop: "sn", align: "left", label: "S/N", sort: true },
  { prop: "createdAt", align: "left", label: "CreatedAt", sort: true },
  { prop: "updatedAt", align: "left", label: "Updated", sort: true },
];

export const DEFAULT_VOD_QS: VodQs = {
  limit: PER_PAGE_TEN,
  order: "updatedAt",
  dir: DESC as SortDirection,
  offset: 0,
  search_by: "sn",
  search_value: "",
  with_mcu: YES,
  raw: YES,
};

// Cab Camera
export const CAB_CAM_TABLE_HEAD: TableHeadRow[] = [
  { prop: "id", align: "left", label: "ID", sort: true },
  { prop: "status", align: "left", label: "Status", sort: false, icon: true },
  { prop: "Mcu.sn", align: "left", label: "MCU S/N", sort: false },
  { prop: "McuId", align: "left", label: "MCU ID", sort: true },
  { prop: "mac", align: "left", label: "MAC", sort: true },
  { prop: "createdAt", align: "left", label: "CreatedAt", sort: true },
  { prop: "updatedAt", align: "left", label: "Updated", sort: true },
];

export const DEFAULT_CAB_CAM_QS: VodQs = {
  limit: PER_PAGE_TEN,
  order: "updatedAt",
  dir: DESC as SortDirection,
  offset: 0,
  search_by: "id",
  search_value: "",
  with_mcu: YES,
  raw: YES,
};

// ALERT
export const ALERT_TABLE_HEAD: TableHeadRow[] = [
  { prop: "id", align: "left", label: "ID", sort: true },
  { prop: "AlertTypeId", align: "left", label: "Alert Type", sort: true },
  { prop: "McuId", align: "left", label: "MCU", sort: true },
  { prop: "message", align: "left", label: "Message", sort: false },
  // { prop: "VpuId", align: "left", label: "VPU", sort: true },
  // { prop: "sensor", align: "left", label: "Sensor", sort: false },
  // { prop: "FormDId", align: "left", label: "FormD", sort: true },
  { prop: "timestamp", align: "left", label: "CreatedAt", sort: true },
  { prop: "videoUploaded", align: "center", label: "Video", sort: false },
  {
    prop: "telemetryUploadedAt",
    align: "center",
    label: "Event",
    sort: false,
  },
];

const to = dayjs();
const from = dayjs(to.subtract(1, "day"));
export const DEFAULT_ALERT_QS: AlertQs = {
  limit: PER_PAGE_TEN,
  order: "timestamp",
  dir: DESC as SortDirection,
  offset: 0,
  type: null,
  filter_by: "mcu_id",
  date_from: from,
  date_to: to,
};

// user
export const DEFAULT_USER_QS: UserQs = {
  limit: PER_PAGE_TEN,
  order: "updatedAt",
  dir: DESC as SortDirection,
  offset: 0,
  role: ALL,
  provider: ALL,
};

// notification
export const NOTIFICATION_TOP_RIGHT: NotificationPosition = {
  top: 80,
  right: 20,
};
export const NOTIFICATION_BOTTOM: NotificationPosition = {
  bottom: 20,
  left: "50%",
  transform: "translate(-50%, -50%)",
};

// table
export const DEFAULT_TABLE_ROW_SX: SxProps = { cursor: "pointer" };

// select yard
const GROTON: Yard = {
  name: "GROTON",
  center: { lat: 41.33686, lng: -72.024972 },
};

const WILMINGTON: Yard = {
  name: "WILMINGTON",
  center: { lat: 39.747786, lng: -75.5208925 },
};

const PENN_STATION: Yard = {
  name: "PENN_STATION",
  center: { lat: 40.752377, lng: -73.992643 },
};

export const YARDS: Yards = {
  [GROTON.name]: GROTON,
  [WILMINGTON.name]: WILMINGTON,
  [PENN_STATION.name]: PENN_STATION,
};

// FAT
export const TEST_RESULT_TABLE_HEAD: TableHeadRow[] = [
  // { prop: "mcu_id", align: "left", label: "ID", sort: true },
  { prop: "test_id", align: "left", label: "Test", sort: true },
  {
    prop: "test_target_id",
    align: "left",
    label: "Test Target ID",
    sort: true,
  },
  { prop: "host", align: "left", label: "Host", sort: true },
  {
    prop: "tester",
    align: "left",
    label: "Tester",
    sort: false,
  },
  { prop: "start", align: "left", label: "Start", sort: true },
  { prop: "end", align: "left", label: "End", sort: true },
  {
    prop: "harness_version",
    align: "left",
    label: "Harness Ver",
    sort: true,
  },
  { prop: "passed", align: "left", label: "Passed", sort: true },
  { prop: "output", align: "left", label: "Output", sort: true },
  { prop: "created_at", align: "left", label: "Created", sort: true },
];

export const DEFAULT_TEST_RESULT_QS: TestResultQs = {
  limit: TEST_RESULTS_PER_PAGE,
  order: "created_at",
  dir: DESC as SortDirection,
  offset: 0,
};

export const DEFAULT_TEST_QS: QueryString = {
  limit: TESTS_PER_PAGE,
  order: "created_at",
  dir: DESC as SortDirection,
  offset: 0,
};

// DB UPDATE
export const SW_MANIFEST_TABLE_HEAD: TableHeadRow[] = [
  { prop: "id", align: "left", label: "Id", sort: true },
  { prop: "version", align: "left", label: "To", sort: true },
  { prop: "fromVersion", align: "left", label: "From", sort: true },
  { prop: "manifest", align: "left", label: "Manifest", sort: false },
  { prop: "createdAt", align: "left", label: "Created", sort: true },
];

export const SW_UPDATE_DEPLOYMENT_TABLE_HEAD: TableHeadRow[] = [
  { prop: "id", align: "left", label: "Id", sort: true },
  { prop: "status", align: "left", label: "Status", sort: true },
  { prop: "Vehicle.id", align: "left", label: "Vehicle", sort: false },
  { prop: "McuId", align: "left", label: "MCU ID", sort: false },
  { prop: "createdAt", align: "left", label: "Created", sort: true },
  { prop: "updatedAt", align: "left", label: "Updated", sort: true },
  { prop: "DbUpdateId", align: "left", label: "Start Update", sort: false },
];

export const DEFAULT_SW_MANIFEST_QS: SwManifestQs = {
  limit: PER_PAGE_TEN,
  order: "createdAt",
  dir: DESC as SortDirection,
  offset: 0,
  version: "",
};

export const VEHICLE_ID = "Vehicle ID";
// export const MCU_ID = "MCU ID";
// export const MCU_SN = "MCU S/N";
// export const MCU_VEHICLE_SEARCH_BY_MENUS = [VEHICLE_ID, MCU_ID, MCU_SN];
export const DEFAULT_SW_UPDATE_VEHICLE_SEARCH_QUERY: SearchQueryString = {
  search_by: VEHICLE_ID,
  search_value: "",
  only_ids: true,
};

export const VEHICLE_GROUP = "vehicle group";
export const RELEASE = "release";
export const GROUP = "group";
export const SW_UPDATE_TABS = [
  {
    title: VEHICLE_GROUP,
    to: GROUP,
    color: STATUS_TABLE_COLOR.ACTIVE,
  },
  {
    title: RELEASE,
    to: RELEASE,
    color: STATUS_TABLE_COLOR.ACTIVE,
  },
];

export const DEFAULT_FLEET_TABLE_PAGINATION: Pagination = {
  rowsPerPage: 10,
  page: 0,
  dir: DESC,
  order: "updatedAt",
};

export const LAST_ACTIVE_OPTIONS = [
  { label: "All", value: 0 },
  { label: "3 days or longer", value: 72 },
  { label: "7 days or longer", value: 7 * 24 },
  { label: "14 days or longer", value: 14 * 24 },
  { label: "30 days or longer", value: 30 * 24 },
  { label: "90 days or longer", value: 90 * 24 },
];

export const DEFAULT_BASESTATION_TABLE_PAGINATION: Pagination = {
  rowsPerPage: 7,
  page: 0,
  dir: DESC,
  order: "updatedAt",
};

// OTA
export const MENDER_UPDATE_DEVICE_TABLE_HEAD: TableHeadRow[] = [
  { prop: "Vehicle.id", align: "left", label: "Equipment ID", sort: true },
  { prop: "id", align: "left", label: "MCU", sort: false },
  { prop: "menderId", align: "left", label: "MCU Update", sort: false },
  { prop: "Vpu.1", align: "left", label: "VPU-1", sort: false },
  { prop: "Vpu1menderId", align: "left", label: "VPU-1 Update", sort: false },
  { prop: "Vpu.2", align: "left", label: "VPU-2", sort: false },
  { prop: "Vpu2menderId", align: "left", label: "VPU-2 Update", sort: false },
  // { prop: "updatedAt", align: "left", label: "Updated", sort: true },
  { prop: "online", align: "center", label: "Status", sort: false },
];

export const DEFAULT_UPDATE_DEVICE_PAGINATION: Pagination = {
  rowsPerPage: 50,
  page: 0,
  dir: ASC,
  order: "Vehicle.id",
};

export const MENDER_DEPLOYMENT_TABLE_HEAD: TableHeadRow[] = [
  { prop: "id", align: "left", label: "ID", sort: false },
  { prop: "deploymentId", align: "left", label: "Deployment ID", sort: false },
  { prop: "name", align: "left", label: "Name", sort: false },
  {
    prop: "status",
    align: "left",
    label: "Status",
    sort: false,
    parse: (status: string) => {
      let color;
      if (status === "success") {
        color = green[900];
      } else if (status === "failure") {
        color = red[500];
      } else {
        color = purple[400];
      }
      return (
        <Typography variant={"inherit"} fontWeight={"bold"} color={color}>
          {status}
        </Typography>
      );
    },
  },
  { prop: "release", align: "left", label: "Release", sort: false },
  {
    prop: "createdAt",
    align: "left",
    label: "Created",
    sort: false,
    parse: formatDate,
  },
  {
    prop: "updatedAt",
    align: "left",
    label: "Updated",
    sort: false,
    parse: formatDate,
  },
];

export const PIPER_UPDATE_DEVICE_TABLE_HEAD: TableHeadRow[] = [
  { prop: "Vehicle.id", align: "left", label: "Equipment ID", sort: true },
  { prop: "id", align: "left", label: "MCU ID", sort: false },
  { prop: "sn", align: "left", label: "MCU S/N", sort: false },
  { prop: "swVersion", align: "left", label: "Current SW", sort: false },
  { prop: "menderId", align: "left", label: "Update in progress", sort: false },
  { prop: "mode", align: "center", label: "Action", sort: false },
  { prop: "online", align: "center", label: "Status", sort: false },
];

export const DEFAULT_SW_RELEASE_QS: QueryString = {
  limit: 100,
  offset: 0,
  dir: DESC,
  order: "createdAt",
};

export const DEFAULT_SW_DEPLOYMENT_QS: QueryString = {
  limit: 20,
  offset: 0,
  dir: DESC,
  order: "updatedAt",
};

export const SW_DEPLOYMENT_FOR_SELECTED_TABLE_HEAD: TableHeadRow[] = [
  { prop: "id", align: "left", label: "ID", sort: true },
  { prop: "status", align: "left", label: "Status", sort: false },
  { prop: "version", align: "left", label: "version", sort: false },
  { prop: "createdAt", align: "left", label: "Created", sort: true },
  { prop: "updatedAt", align: "left", label: "Updated", sort: true },
];

// VIDEO
export const DEFAULT_VIDEO_QS = (): VideoQs => {
  let requestedSaved = localStorage.getItem(VIDEO_FILTER_REQUESTED);
  if (requestedSaved === "null") requestedSaved = null;
  return {
    limit: PER_PAGE_TEN,
    order: "createdAt",
    dir: DESC as SortDirection,
    offset: 0,
    requested: requestedSaved as BooleanInternal | null,
    filter_by: "vehicle_id",
    filter_value: "",
  };
};

export const VIDEO_TABLE_HEAD: TableHeadRow[] = [
  { prop: "Mcu.Vehicle.id", align: "left", label: "Equipment ID", sort: false },
  { prop: "McuId", align: "left", label: "MCU ID", sort: false },
  { prop: "Mcu.sn", align: "left", label: "MCU SN", sort: false },
  { prop: "onRequest", align: "left", label: "On Request", sort: false },
  { prop: "from", align: "left", label: "Start Time", sort: false },
  { prop: "to", align: "left", label: "Duration", sort: false },
  { prop: "createdAt", align: "left", label: "Created", sort: true },
  { prop: "uploadedAt", align: "center", label: "Status", sort: false },
  { prop: "id", align: "center", label: "Download", sort: false },
  { prop: "Mcu.id", align: "center", label: "Filename", sort: false },
];

// system failure
export const SYSTEM_FAILURE_TABLE_HEAD: TableHeadRow[] = [
  { prop: "mcuSn", align: "left", label: "S/N", sort: false },
  { prop: "McuId", align: "left", label: "ID", sort: false },
  { prop: "vehicleId", align: "left", label: "Equipment #", sort: false },
  { prop: "mcuUpdatedAt", align: "left", label: "MCU Last Active", sort: true },
  {
    prop: "mcuOfflineFor",
    align: "left",
    label: "MCU Offline Time",
    sort: true,
  },
  {
    prop: "modemLastActive",
    align: "left",
    label: "Modem Last Active",
    sort: true,
  },
  {
    prop: "thinClientLastActive",
    align: "left",
    label: "Thin Client Last Active",
    sort: true,
  },
  { prop: "createdAt", align: "left", label: "Created", sort: true },
];

export const DEFAULT_SYSTEM_FAILURE_TABLE_PAGINATION: Pagination = {
  rowsPerPage: 20,
  page: 0,
  dir: DESC,
  order: "createdAt",
};

// workgang
export const WORKGANG_TABLE_HEAD: TableHeadRow[] = [
  { prop: "id", align: "left", label: "ID", sort: true },
  { prop: "name", align: "left", label: "Name", sort: true },
  { prop: "active", align: "left", label: "Active", sort: false },
  { prop: "Foreman", align: "left", label: "Foreman", sort: false },
  { prop: "createdAt", align: "left", label: "CreatedAt", sort: true },
  { prop: "updatedAt", align: "left", label: "Updated", sort: true },
];

export const DEFAULT_WORK_GANG_QS: WorkGangQs = {
  limit: PER_PAGE_TEN,
  order: "updatedAt",
  dir: DESC as SortDirection,
  offset: 0,
  with_foreman: YES,
};

export const NAV_MAIN_PAD = 16;
export const TITLE_BAR_PAD = 10;
export const TITLE_COMP_HEIGHT = "40px";

export const LCCAS_TABLE_ITEM_STYLE = {
  padding: "5px",
};

export const PHONE_WIDTH: number = 700;
export const TABLET_WIDTH: number = 1300;

export const TRACK_OPTIONS = {
  strokeColor: "#6a7de8",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#6a7de8",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 5,
};

export const MCU_DETAILS_FIELDS = {
  FleetPage: [
    "id",
    "sn",
    "SW",
    "Location",
    "City",
    "Region",
    "Speed (km/h)",
    "Updated",
  ],
  EquipmentPage: [
    "id",
    "sn",
    "HW",
    "SW",
    "Operating Mode",
    "Location",
    "Speed (km/h)",
    "Temp (°C)",
    "UWB Dist (m)",
    "LiDAR Dist (m)",
    "Re-Init Requested",
    "Free Memory (KB)",
    "LTE RSSI",
    "Last Updated",
    "SSD",
    "IMEI",
    "ICCID1",
    "ICCID2",
  ],
  McuPage: [
    "MCU ID",
    "MCU S/N",
    "HW",
    "SW",
    "Op Mode",
    "Location",
    "City",
    "State",
    "Speed (km/h)",
    "LTE RSSI",
    "SSD",
    "UWB Dist (m)",
    "Lidar Dist (m)",
    "Temp (˚C)",
    "Free Memory (KB)",
    "Updated",
    "Maintenance",
    "Re-Init Requested",
    "IMEI",
    "ICCID1",
    "ICCID2",
  ],
};

export const VEHICLE_INFO_FLEET_FIELDS = [
  { label: "Equipment Id", key: "Vehicle.id" },
  { label: "Equipment Type", key: "equipType" },
  { label: "LCCAS Installed", key: "createdAt" },
  { label: "Last Vehicle Check-in", key: "updatedAt" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Location", key: "gcs" },
  { label: "Operating Mode", key: "mode" },
  { label: "Speed", key: "speed mph" },
  { label: "Nearest Vehicle", key: "Nearest Vehicle" },
  { label: "Obstruction", key: "Obstruction" },
];

export const VEHICLE_INFO_FLEET_SENSOR_FIELDS = [
  { prop: "location", align: "left", label: " ", sort: true },
  { prop: "gps", align: "left", label: "GPS", sort: true, icon: true },
  { prop: "lidar", align: "left", label: "LIDAR", sort: true, icon: true },
  { prop: "uwb", align: "left", label: "UWB", sort: true, icon: true },
  { prop: "imu", align: "left", label: "IMU", sort: true, icon: true },
  { prop: "camera", align: "left", label: "Camera", sort: true, icon: true },
  { prop: "vod", align: "left", label: "VOD", sort: true, icon: true },
  {
    prop: "CabCamera",
    align: "left",
    label: "Cab Camera",
    sort: true,
    icon: true,
  },
];

export const MCU_DETAILS_FLEET_PAGE = [
  { prop: "id", align: "left", label: "MCU ID", icon: true },
  { prop: "sn", align: "left", label: "MCU S/N", icon: true },
  { prop: "hw", align: "left", label: "HW", icon: true },
  { prop: "sw", align: "left", label: "SW", icon: true },
  { prop: "lte", align: "left", label: "LTE RSSI", icon: true },
  { prop: "ssd", align: "left", label: "Solid-State Drive", icon: true },
  { prop: "temp", align: "left", label: "Temp", icon: true },
  { prop: "freemem", align: "left", label: "Free Memory", icon: true },
  { prop: "maint", align: "left", label: "Maintenance", icon: true },
  { prop: "re-init", align: "left", label: "Re-Init Requested", icon: true },
];

export const MCU_DETAILS_LINKS = {
  EquipmentPage: ["SSH Tunnel", "WLAN Setup", "Enable/Disable Lidar"],
  McuPage: [
    "Set Maintenance",
    "Send Config",
    "SSH Tunnel",
    "WLAN Setup",
    "Enable/Disable Lidar",
    "Online Notification",
    "Audible Alert",
  ],
};

export const HEALTH_CODE_COLORS = {
  [HEALTH_OK]: "#388e3c",
  [HEALTH_NOT_READY]: "yellow",
  [HEALTH_USABLE]: "#388e3c",
  [HEALTH_NOT_USABLE]: "#ff9800",
  [HEALTH_FAILED]: "#8e8a8a",
  [HEALTH_REPLACE]: "#000000",
  [HEALTH_DISABLED]: "#CA172C",
  [HEALTH_BAD_CONFIG]: "#ed6c02",
};

export const FLEET_DEFAULT_FILTERS = {
  region: {
    label: "Region",
    values: ["All Regions"],
  },
  lastAct: {
    label: "Last Acive",
    values: ["All"],
  },
};

/** Theme hex colors */
export const lightgrey = "#ACACAC";
export const primaryBlue = "#2c6bff";
export const primaryBlueLite = "#e0eaff";
export const darkgrey = "#242424";

export const FLEET_TABLE_ROW_HEIGHT = 45;

/** Component dimensions */
export const TITLE_COMPONENT_HEIGHT = 60;
