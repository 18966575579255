import * as messageAction from "../actions/message.action";
import { AnyAction } from "redux";
import {
  ChangeOpModeMessage,
  DeviceNotRespondingPayload,
  EnableLidarAckPayload,
  EnableSensorAckPayload,
  ReplaceSensorAckPayload,
  EnableLidarNotRespondingPayload,
  EnableLidarPayload,
  EnableSensorPayload,
  EnableLidarRequestState,
  ReplaceSensorRequestState,
  MessageEntry,
  SSHTunnelMessagePayload,
  SshTunnelPayload,
  SSHTunnelRequestState,
  WlanSetupPayload,
  WlanSetupRequestState,
  WlanSetupResultMessagePayload,
  ReplaceSensorPayload,
} from "../const/types";
import { FAILURE, PENDING, SUCCESS } from "../const/const";

export interface State {
  ssh_tunnel: {
    [mcuOrBsId: string]: SSHTunnelRequestState;
  };
  wlan_setup: {
    [mcuId: string]: WlanSetupRequestState;
  };
  lidar_enabled: {
    [mcuId: string]: EnableLidarRequestState;
  };
  gps_enabled: {
    [mcuId: string]: EnableLidarRequestState;
  };
  uwb_enabled: {
    [mcuId: string]: EnableLidarRequestState;
  };
  imu_enabled: {
    [mcuId: string]: EnableLidarRequestState;
  };
  camera_enabled: {
    [mcuId: string]: EnableLidarRequestState;
  };
  lidar_replace: {
    [mcuId: string]: ReplaceSensorRequestState;
  };
  gps_replace: {
    [mcuId: string]: ReplaceSensorRequestState;
  };
  uwb_replace: {
    [mcuId: string]: ReplaceSensorRequestState;
  };
  imu_replace: {
    [mcuId: string]: ReplaceSensorRequestState;
  };
  camera_replace: {
    [mcuId: string]: ReplaceSensorRequestState;
  };
  change_op_mode: {
    [mcuId: string]: ChangeOpModeMessage;
  };
  send_config: {
    [mcuId: string]: any;
  };
}

const initialState: State = {
  ssh_tunnel: {},
  wlan_setup: {},
  change_op_mode: {},
  lidar_enabled: {},
  gps_enabled: {},
  uwb_enabled: {},
  imu_enabled: {},
  camera_enabled: {},
  send_config: {},
  lidar_replace: {},
  gps_replace: {},
  uwb_replace: {},
  imu_replace: {},
  camera_replace: {},
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case messageAction.REQUEST_BS_SSH_TUNNEL:
    case messageAction.REQUEST_MCU_SSH_TUNNEL: {
      const payload: SshTunnelPayload = action.payload;
      return {
        ...state,
        ssh_tunnel: {
          ...state.ssh_tunnel,
          [payload.id]: {
            dur: payload.dur,
            status: PENDING,
          },
        },
      };
    }

    case messageAction.BS_SSH_TUNNEL_REQUESTED:
    case messageAction.MCU_SSH_TUNNEL_REQUESTED: {
      const { payload, createdAt } =
        action.payload as MessageEntry<SSHTunnelMessagePayload>;
      return {
        ...state,
        ssh_tunnel: {
          ...state.ssh_tunnel,
          [payload.mcu_id]: {
            requested: new Date(createdAt),
            remote_port: payload.remote_port,
            status: SUCCESS,
          },
        },
      };
    }

    case messageAction.DEVICE_NOT_RESPONDING: {
      const payload: DeviceNotRespondingPayload = action.payload;
      switch (payload.command) {
        case "ssh":
          return {
            ...state,
            ssh_tunnel: {
              ...state.ssh_tunnel,
              [payload.mcu_id]: {
                ...state.ssh_tunnel[payload.mcu_id],
                status: FAILURE,
              },
            },
          };
        case "wlan":
          return {
            ...state,
            wlan_setup: {
              ...state.wlan_setup,
              [payload.mcu_id]: {
                ...state.wlan_setup[payload.mcu_id],
                status: FAILURE,
              },
            },
          };
        case "send_config":
          return {
            ...state,
            send_config: {
              ...state.send_config,
              [payload.mcu_id]: {
                ...state.send_config[payload.mcu_id],
                status: FAILURE,
              },
            },
          };
        default:
          return state;
      }
    }

    case messageAction.ENABLE_LIDAR_NOT_RESPONDING: {
      const payload: EnableLidarNotRespondingPayload = action.payload;
      return {
        ...state,
        [`${payload.command}_enabled`]: {
          ...[`${payload.command}_enabled`],
          [payload.mcu_id]: {
            ...[payload.mcu_id],
            [payload.vpu_id]: {
              status: SUCCESS,
              enabled: undefined,
            },
          },
        },
      };
    }

    case messageAction.REPLACE_SENSOR_NOT_RESPONDING: {
      const payload: EnableLidarNotRespondingPayload = action.payload;
      return {
        ...state,
        [`${payload.command}_replace`]: {
          ...[`${payload.command}_replace`],
          [payload.mcu_id]: {
            ...[payload.mcu_id],
            [payload.vpu_id]: {
              status: FAILURE,
              replace: undefined,
            },
          },
        },
      };
    }

    case messageAction.REQUEST_WLAN_SETUP: {
      const payload: WlanSetupPayload = action.payload;
      return {
        ...state,
        wlan_setup: {
          ...state.wlan_setup,
          [payload.mcu_id]: {
            status: PENDING,
          },
        },
      };
    }

    case messageAction.REQUEST_SEND_CONFIG_TO_OLD_DEVICE: {
      const mcuId = action.payload;
      return {
        ...state,
        send_config: {
          ...state.send_config,
          [mcuId]: {
            status: PENDING,
          },
        },
      };
    }

    case messageAction.REQUEST_ENABLE_LIDAR: {
      const payload: EnableLidarPayload = action.payload;
      return {
        ...state,
        lidar_enabled: {
          ...state.lidar_enabled,
          [payload.mcu_id]: {
            [payload.vpu_id]: {
              status: PENDING,
              enabled:
                state.lidar_enabled[payload.mcu_id]?.[payload.vpu_id]?.enabled,
            },
          },
        },
      };
    }

    case messageAction.REQUEST_ENABLE_SENSOR: {
      const payload: EnableSensorPayload = action.payload;
      return {
        ...state,
        [`${payload.sensor_type}_enabled`]: {
          ...state[`${payload.sensor_type}_enabled` as keyof State],
          [payload.mcu_id]: {
            [payload.vpu_id]: {
              status: PENDING,
              enabled:
                state[`${payload.sensor_type}_enabled` as keyof State][
                  payload.mcu_id
                ]?.[payload.vpu_id]?.enabled,
            },
          },
        },
      };
    }

    case messageAction.REQUEST_REPLACE_SENSOR: {
      const payload: ReplaceSensorPayload = action.payload;
      return {
        ...state,
        [`${payload.sensor_type}_replace`]: {
          ...state[`${payload.sensor_type}_replace` as keyof State],
          [payload.mcu_id]: {
            [payload.vpu_id]: {
              status: PENDING,
              replace:
                state[`${payload.sensor_type}_replace` as keyof State][
                  payload.mcu_id
                ]?.[payload.vpu_id]?.replace,
            },
          },
        },
      };
    }

    case messageAction.LOAD_WLAN_SETUP_RESULT_MESSAGE: {
      const { payload } =
        action.payload as MessageEntry<WlanSetupResultMessagePayload>;
      return {
        ...state,
        wlan_setup: {
          ...state.wlan_setup,
          [payload.mcu_id]: {
            ...state.wlan_setup[payload.mcu_id],
            status: SUCCESS,
            reason: payload.reason,
          },
        },
      };
    }

    case messageAction.LOAD_REQUEST_SEND_CONFIG_TO_OLD_DEVICE_ACK_MESSAGE: {
      const { payload } = action.payload;
      return {
        ...state,
        send_config: {
          ...state.send_config,
          [payload.mcu_id]: {
            ...state.send_config[payload.mcu_id],
            status: SUCCESS,
          },
        },
      };
    }

    case messageAction.LOAD_ENABLE_LIDAR_ACK_MESSAGE: {
      const { payload } = action.payload as MessageEntry<EnableLidarAckPayload>;
      return {
        ...state,
        lidar_enabled: {
          ...state.lidar_enabled,
          [payload.mcu_id]: {
            ...state.lidar_enabled[payload.mcu_id],
            [payload.vpu_id]: {
              status: SUCCESS,
              enabled: payload.enable,
            },
          },
        },
      };
    }

    // enable/disable success
    case messageAction.LOAD_ENABLE_SENSOR_ACK_MESSAGE: {
      const { payload } =
        action.payload as MessageEntry<EnableSensorAckPayload>;
      return {
        ...state,
        [`${payload.sensor_type}_enabled`]: {
          ...state[`${payload.sensor_type}_enabled` as keyof State],
          [payload.mcu_id]: {
            ...state[`${payload.sensor_type}_enabled` as keyof State][
              payload.mcu_id
            ],
            [payload.vpu_id]: {
              status: SUCCESS,
              enabled: payload.enable,
            },
          },
        },
      };
    }

    // Replace sensor success
    case messageAction.LOAD_REPLACE_SENSOR_ACK_MESSAGE: {
      const { payload } =
        action.payload as MessageEntry<ReplaceSensorAckPayload>;
      return {
        ...state,
        [`${payload.sensor_type}_replace`]: {
          ...state[`${payload.sensor_type}_replace` as keyof State],
          [payload.mcu_id]: {
            ...state[`${payload.sensor_type}_replace` as keyof State][
              payload.mcu_id
            ],
            [payload.vpu_id]: {
              status: SUCCESS,
              enabled: payload.replace,
            },
          },
        },
      };
    }

    case messageAction.CHANGE_OP_MODE_MESSAGE: {
      const { mcu_id, mode } = action.payload as ChangeOpModeMessage;
      return {
        ...state,
        change_op_mode: {
          ...state.change_op_mode,
          [mcu_id]: {
            sent: null,
            mcu_id,
            mode,
          },
        },
      };
    }

    case messageAction.CHANGE_OP_MODE_SENT_MESSAGE: {
      const { mcu_id, mode, sent } = action.payload as ChangeOpModeMessage;
      return {
        ...state,
        change_op_mode: {
          ...state.change_op_mode,
          [mcu_id]: {
            sent,
            mcu_id,
            mode,
          },
        },
      };
    }

    case messageAction.UNLOAD_SSH_TUNNEL_OPEN_MESSAGE: {
      if (state.ssh_tunnel[action.payload]) {
        const ssh_tunnel = { ...state.ssh_tunnel };
        Reflect.deleteProperty(ssh_tunnel, action.payload);
        return {
          ...state,
          ssh_tunnel,
        };
      }
      return state;
    }

    default:
      return state;
  }
}

/**
 * selector
 */
export const getSshTunnels = (state: State, id: string) => state.ssh_tunnel[id];
export const getWlanSetup = (state: State, id: string) => state.wlan_setup[id];
export const getSendConfigState = (state: State, id: string) =>
  state.send_config[id];
export const getLidarEnabled = (state: State, id: string) =>
  state.lidar_enabled[id];
export const getSensorEnabled = (state: State, id: string, sensor: string) =>
  state[`${sensor}_enabled` as keyof State][id];
export const getSensorReplace = (state: State, id: string, sensor: string) =>
  state[`${sensor}_replace` as keyof State][id];
export const getChangeOpModeState = (state: State, id: string) =>
  state.change_op_mode[id];
