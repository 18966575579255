import { createTheme } from "@mui/material/styles";
export const sideBarTheme = createTheme({
  palette: {
    background: {
      paper: "#333333",
    },
    text: {
      primary: "#fff",
    },
  },
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "white",
        },
      },
    },
  },
});

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      offline: string;
    };
  }
  interface ThemeOptions {
    status?: {
      offline?: string;
    };
  }
}

export const chipTheme = createTheme({
  status: {
    offline: "#ADADAD",
  },
});

export const appTheme = createTheme({
  palette: {
    primary: {
      main: "#2C6BFF",
    },
  },
});
