import { AnyAction } from "redux";
import * as tableAction from "../actions/table.action";
import { TableHeadRow, LccasTableRowType, TabProps } from "../const/types";

export interface State {
  tabs: boolean;
  tabProps: TabProps[];
  selectedTab: any;
  tabStyle: {
    height: number;
    backgroundColor: any;
  };
  pagination: boolean;
  page: number;
  rowsPerPage: number;
  paginationCount: number;
  headers: TableHeadRow[];
  rows: LccasTableRowType[];
  tableStyle?: {};
  sortDirection: "asc" | "desc";
  sortField: string;
}

const initialState: State = {
  tabs: false,
  tabProps: [],
  selectedTab: "total",
  tabStyle: {
    height: 5,
    backgroundColor: "",
  },
  pagination: false,
  page: 0,
  rowsPerPage: 10,
  paginationCount: 0,
  headers: [],
  rows: [],
  tableStyle: {},
  sortDirection: "asc",
  sortField: "",
};

/**
 * Reducers
 */

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    // Tabs
    case tableAction.SET_TABS: {
      return { ...state, tabs: action.payload };
    }
    case tableAction.SET_TAB_PROPS: {
      return { ...state, tabProps: action.payload };
    }
    case tableAction.SET_TAB_STYLE: {
      return { ...state, tabStyle: action.payload };
    }
    case tableAction.SET_SELECTED_TAB: {
      return { ...state, selectedTab: action.payload };
    }

    // Pagination
    case tableAction.SET_PAGINATION: {
      return { ...state, pagination: action.payload };
    }
    case tableAction.SET_PAGE: {
      return { ...state, page: action.payload };
    }
    case tableAction.SET_ROWS_PER_PAGE: {
      return { ...state, rowsPerPage: action.payload };
    }
    case tableAction.SET_PAGINATION_COUNT: {
      return { ...state, paginationCount: action.payload };
    }

    // Table Data
    case tableAction.SET_HEADERS: {
      return { ...state, headers: action.payload };
    }
    case tableAction.SET_ROWS: {
      return {
        ...state,
        rows: action.payload,
        paginationCount: action.payload.length,
      };
    }
    case tableAction.SET_SORT_DIRECTION: {
      if (["asc", "ascending"].includes(action.payload.toLowerCase())) {
        return { ...state, sortDirection: "asc" };
      } else {
        return { ...state, sortDirection: "desc" };
      }
    }
    case tableAction.SET_SORT_FIELD: {
      return { ...state, sortField: action.payload };
    }

    // Reset
    case tableAction.RESET_TABLE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}

/**
 * selectors
 */

export const getTabs = (state: State) => state.tabs;
export const getTabProps = (state: State) => state.tabProps;
export const getTabStyle = (state: State) => state.tabStyle;
export const getSelectedTab = (state: State) => state.selectedTab;

export const getPagination = (state: State) => state.pagination;
export const getPage = (state: State) => state.page;
export const getRowsPerPage = (state: State) => state.rowsPerPage;
export const getPaginationCount = (state: State) => state.paginationCount;

export const getTableHeaders = (state: State) => state.headers;
export const getTableRows = (state: State) => state.rows;
export const getSortDirection = (state: State) => state.sortDirection;
export const getSortField = (state: State) => state.sortField;
