/**
 * Action types
 */
import {
  ChangeOpModeMessage,
  DeviceNotRespondingPayload,
  EnableLidarAckPayload,
  EnableSensorAckPayload,
  EnableLidarNotRespondingPayload,
  EnableLidarPayload,
  EnableSensorPayload,
  ReplaceSensorAckPayload,
  ManyEntry,
  MessageEntry,
  QueryString,
  SSHTunnelMessagePayload,
  SshTunnelPayload,
  WlanSetupPayload,
  WlanSetupResultMessagePayload,
} from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_MANY_MESSAGES = Symbol.for("FETCH_MANY_MESSAGES");
export const FETCH_MESSAGES_TYPES = Symbol.for("FETCH_MESSAGES_TYPES");
export const FETCH_SINGLE_MESSAGE = Symbol.for("FETCH_SINGLE_MESSAGE");
export const FETCH_MESSAGES_FAILED = Symbol.for("FETCH_MESSAGES_FAILED");
export const POST_MESSAGES_FAILED = Symbol.for("POST_MESSAGES_FAILED");
export const LOAD_MANY_MESSAGES = Symbol.for("LOAD_MANY_MESSAGES");
export const LOAD_MESSAGE = Symbol.for("LOAD_MESSAGE");
export const LOAD_OTHER_MESSAGE = Symbol.for("LOAD_OTHER_MESSAGE");
export const LOAD_WLAN_SETUP_RESULT_MESSAGE = Symbol.for(
  "LOAD_WLAN_SETUP_RESULT_MESSAGE"
);
export const LOAD_ENABLE_LIDAR_ACK_MESSAGE = Symbol.for(
  "LOAD_ENABLE_LIDAR_ACK_MESSAGE"
);
export const LOAD_ENABLE_SENSOR_ACK_MESSAGE = Symbol.for(
  "LOAD_ENABLE_SENSOR_ACK_MESSAGE"
);
export const LOAD_REPLACE_SENSOR_ACK_MESSAGE = Symbol.for(
  "LOAD_REPLACE_SENSOR_ACK_MESSAGE"
);
export const LOAD_REQUEST_SEND_CONFIG_TO_OLD_DEVICE_ACK_MESSAGE = Symbol.for(
  "LOAD_REQUEST_SEND_CONFIG_TO_OLD_DEVICE_ACK_MESSAGE"
);
export const UNLOAD_SSH_TUNNEL_OPEN_MESSAGE = Symbol.for(
  "UNLOAD_SSH_TUNNEL_OPEN_MESSAGE"
);
export const MCU_SSH_TUNNEL_REQUESTED = Symbol.for("MCU_SSH_TUNNEL_REQUESTED");
export const MCU_WLAN_SETUP_REQUESTED = Symbol.for("MCU_WLAN_SETUP_REQUESTED");
export const BS_SSH_TUNNEL_REQUESTED = Symbol.for("BS_SSH_TUNNEL_REQUESTED");
export const CHANGE_OP_MODE_MESSAGE = Symbol.for("CHANGE_OP_MODE_MESSAGE");
export const CHANGE_OP_MODE_SENT_MESSAGE = Symbol.for(
  "CHANGE_OP_MODE_SENT_MESSAGE"
);
export const REQUEST_MCU_SSH_TUNNEL = Symbol.for("REQUEST_MCU_SSH_TUNNEL");
export const DEVICE_NOT_RESPONDING = Symbol.for("DEVICE_NOT_RESPONDING");
export const ENABLE_LIDAR_NOT_RESPONDING = Symbol.for(
  "ENABLE_LIDAR_NOT_RESPONDING"
);
export const REPLACE_SENSOR_NOT_RESPONDING = Symbol.for(
  "REPLACE_SENSOR_NOT_RESPONDING"
);
export const SEND_CONFIG_TO_OLD_DEVICE_NOT_RESPONDING = Symbol.for(
  "SEND_CONFIG_TO_OLD_DEVICE_NOT_RESPONDING"
);
export const REQUEST_WLAN_SETUP = Symbol.for("REQUEST_WLAN_SETUP");
export const REQUEST_ENABLE_LIDAR = Symbol.for("REQUEST_ENABLE_LIDAR");
export const REQUEST_ENABLE_SENSOR = Symbol.for("REQUEST_ENABLE_SENSOR");
export const REQUEST_REPLACE_SENSOR = Symbol.for("REQUEST_REPLACE_SENSOR");
export const REQUEST_SEND_CONFIG_TO_OLD_DEVICE = Symbol.for(
  "REQUEST_SEND_CONFIG_TO_OLD_DEVICE"
);
export const REQUEST_BS_SSH_TUNNEL = Symbol.for("REQUEST_BS_SSH_TUNNEL");

/**
 * Actions
 */
export function fetchManyMessageAction(qs: QueryString) {
  return {
    type: FETCH_MANY_MESSAGES,
    payload: qs,
  };
}

export function fetchMessageTypesAction() {
  return {
    type: FETCH_MESSAGES_TYPES,
  };
}

export function fetchMessageFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_MESSAGES_FAILED,
    payload: err.toString(),
  };
}

export function deviceNotRespondingAction(payload: DeviceNotRespondingPayload) {
  return {
    type: DEVICE_NOT_RESPONDING,
    payload,
  };
}

export function enableLidarNotRespondingAction(
  payload: EnableLidarNotRespondingPayload
) {
  return {
    type: ENABLE_LIDAR_NOT_RESPONDING,
    payload,
  };
}

export function replaceSensorNotRespondingAction(
  payload: EnableLidarNotRespondingPayload
) {
  return {
    type: REPLACE_SENSOR_NOT_RESPONDING,
    payload,
  };
}

export function postMessageFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: POST_MESSAGES_FAILED,
    payload: err.toString(),
  };
}

export function loadManyMessageAction(payload: ManyEntry<MessageEntry<any>>) {
  return {
    type: LOAD_MANY_MESSAGES,
    payload,
  };
}

export function loadMessageAction(payload: MessageEntry<string>) {
  return {
    type: LOAD_MESSAGE,
    payload,
  };
}

export function loadWlanSetupResultMessageAction(
  payload: MessageEntry<WlanSetupResultMessagePayload>
) {
  return {
    type: LOAD_WLAN_SETUP_RESULT_MESSAGE,
    payload,
  };
}

export function loadEnableLidarAckMessageAction(
  payload: MessageEntry<EnableLidarAckPayload>
) {
  return {
    type: LOAD_ENABLE_LIDAR_ACK_MESSAGE,
    payload,
  };
}

export function loadEnableSensorAckMessageAction(
  payload: MessageEntry<EnableSensorAckPayload>
) {
  return {
    type: LOAD_ENABLE_SENSOR_ACK_MESSAGE,
    payload,
  };
}

export function loadReplaceSensorAckMessageAction(
  payload: MessageEntry<ReplaceSensorAckPayload>
) {
  return {
    type: LOAD_REPLACE_SENSOR_ACK_MESSAGE,
    payload,
  };
}

export function loadSendConfigToDeviceAckMessageAction(
  payload: MessageEntry<any>
) {
  return {
    type: LOAD_REQUEST_SEND_CONFIG_TO_OLD_DEVICE_ACK_MESSAGE,
    payload,
  };
}

export function unloadSSHTunnelOpenMessageAction(mcu_id: string) {
  return {
    type: UNLOAD_SSH_TUNNEL_OPEN_MESSAGE,
    payload: mcu_id,
  };
}

export function loadOtherMessageAction() {
  return {
    type: LOAD_OTHER_MESSAGE,
  };
}

export function mcuSshTunnelRequestedAction(
  payload: MessageEntry<SSHTunnelMessagePayload>
) {
  return {
    type: MCU_SSH_TUNNEL_REQUESTED,
    payload,
  };
}

export function bsSshTunnelRequestedAction(
  payload: MessageEntry<SSHTunnelMessagePayload>
) {
  return {
    type: BS_SSH_TUNNEL_REQUESTED,
    payload,
  };
}

export function changeOpModeAction(payload: ChangeOpModeMessage) {
  return {
    type: CHANGE_OP_MODE_MESSAGE,
    payload,
  };
}

export function changeOpModeSentAction(payload: ChangeOpModeMessage) {
  return {
    type: CHANGE_OP_MODE_SENT_MESSAGE,
    payload,
  };
}

export function requestMcuSshTunnelAction(payload: SshTunnelPayload) {
  return {
    type: REQUEST_MCU_SSH_TUNNEL,
    payload,
  };
}

export function requestWlanSetupAction(payload: WlanSetupPayload) {
  return {
    type: REQUEST_WLAN_SETUP,
    payload,
  };
}

export function requestEnableLidarAction(payload: EnableLidarPayload) {
  return {
    type: REQUEST_ENABLE_LIDAR,
    payload,
  };
}

export function requestEnableSensorAction(payload: EnableSensorPayload) {
  return {
    type: REQUEST_ENABLE_SENSOR,
    payload,
  };
}

export function requestReplaceSensorAction(payload: any) {
  return {
    type: REQUEST_REPLACE_SENSOR,
    payload,
  };
}

export function requestSendConfigToOldDeviceAction(mcuId: string) {
  return {
    type: REQUEST_SEND_CONFIG_TO_OLD_DEVICE,
    payload: mcuId,
  };
}

export function requestBsSshTunnelAction(payload: SshTunnelPayload) {
  return {
    type: REQUEST_BS_SSH_TUNNEL,
    payload,
  };
}
