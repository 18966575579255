import * as searchAction from "../actions/search.action";
import { AnyAction } from "redux";
import { LOCATION_CHANGE } from "redux-first-history";
import { SearchComponentType, SearchDeviceActionPayload } from "../const/types";

export interface SearchState {
  inputValues: {
    [k: string]: string;
  };
  count: number;
  entries: string[];
  loading: boolean;
}

export interface State {
  main: SearchState;
  dialog: SearchState;
}

const initialSearchState: SearchState = {
  inputValues: {
    mcu_id: "",
    mcu_sn: "",
    vehicle_id: "",
  },
  count: 0,
  entries: [],
  loading: false,
};

const initialState = {
  main: initialSearchState,
  dialog: initialSearchState,
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction): State {
  switch (action.type) {
    case searchAction.SET_DEVICE_SEARCH_INPUT: {
      const payload: SearchDeviceActionPayload = action.payload;
      return {
        ...state,
        [action.payload.component]: {
          ...state[payload.component],
          inputValues: {
            ...state[payload.component].inputValues,
            [action.payload.idType]: payload.value,
          },
        },
      };
    }

    case searchAction.SEARCH_DEVICE: {
      return {
        ...state,
        [action.payload.component]: {
          ...state[action.payload.component as SearchComponentType],
          loading: true,
        },
      };
    }

    case searchAction.LOAD_DEVICE_SEARCH_RESULTS: {
      const { count, rows, component } = action.payload;
      return {
        ...state,
        [component]: {
          ...state[component as SearchComponentType],
          count: count,
          entries: rows,
          loading: false,
        },
      };
    }

    case searchAction.RESET_DEVICE_SEARCH_INPUT:
    case LOCATION_CHANGE: {
      return initialState;
    }

    default:
      return state;
  }
}

/**
 * selector
 */
export const getMainSearchState = (state: State) => state.main;
export const getDialogSearchState = (state: State) => state.dialog;
