import * as React from "react";
import { ComponentType, memo, useEffect } from "react";
import {
  Alert as MuiAlert,
  AlertTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { getNotifications } from "../../reducers";
import { compose } from "redux";
import { unloadNotificationAction } from "../../actions/notification.action";
import { AlertColor } from "@mui/material/Alert/Alert";
import { Close } from "@mui/icons-material";
import { NotificationEntry } from "../../const/types";
import { formatDate } from "../../helper/util";

interface AlertProps {
  onClose: (event: React.SyntheticEvent) => void;
  severity: AlertColor;
  dur: number;
  children: any;
}
const Alert: React.FC<AlertProps> = ({ onClose, severity, dur, children }) => {
  useEffect(() => {
    const i = setInterval(onClose, dur);
    return () => clearInterval(i);
  });

  return (
    <MuiAlert
      action={
        <IconButton color="inherit" size="small" onClick={onClose}>
          <Close fontSize="inherit" />
        </IconButton>
      }
      severity={severity}
      elevation={6}
      variant="filled"
      sx={{
        zIndex: 99999,
      }}
    >
      {children}
    </MuiAlert>
  );
};

const Notification: React.FC<Props> = ({ notifications, unload }) => {
  const stackMap: Map<string, NotificationEntry[]> = new Map();
  const [positions, setPositions] = React.useState<string[]>([]);
  positions.forEach((p) => stackMap.set(p, []));
  notifications.forEach((n) => {
    const posStr = JSON.stringify(n.position);
    if (!stackMap.get(posStr)) {
      stackMap.set(posStr, []);
      setPositions((prev) => [...prev, posStr]);
    }
    stackMap.get(posStr)?.push(n);
  });

  return (
    <>
      {Array.from(stackMap).map(([pos, notifs], i) => (
        <Stack
          key={i}
          sx={{ position: "fixed", zIndex: 99999, ...JSON.parse(pos) }}
          spacing={1}
        >
          {notifs.map(({ dur, title, severity, content, created }, i) => (
            <Alert
              key={i}
              onClose={() => unload(i)}
              severity={severity}
              dur={dur}
            >
              <AlertTitle>{`${formatDate(created)} - ${title}`}</AlertTitle>
              {content}
            </Alert>
          ))}
        </Stack>
      ))}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  notifications: getNotifications(state),
});
const mapDispatchToProps = { unload: unloadNotificationAction };
const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose<ComponentType>(connector, memo)(Notification);
type Props = ConnectedProps<typeof connector>;
