import * as swUpdateAction from "../actions/swupdate.action";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import {
  SWManifestEntry,
  SWDeploymentEntry,
  ManyEntry,
  QueryString,
  SWReleaseEntry,
  InitiateSWUpdatePayload,
  McuEntry,
} from "../const/types";
import { ajax, AjaxError } from "rxjs/ajax";
import { SW_UPDATE_API } from "../const/api";
import { getToken } from "../reducers";
import { joinQueryStr } from "../helper/util";
import { RootState } from "../config/store";
import * as notificationAction from "../actions/notification.action";
import {
  NOTIFICATION_SEVERITY_ERROR,
  NOTIFICATION_SEVERITY_SUCCESS,
} from "../const/const";
import { NOTIFICATION_BOTTOM } from "../const/ui";

export const fetchManySwReleaseEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(swUpdateAction.FETCH_MANY_SW_RELEASE),
    map((action) => action.payload),
    switchMap((id: string) =>
      ajax
        .getJSON<ManyEntry<SWReleaseEntry>>(`${SW_UPDATE_API}/release`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(swUpdateAction.loadManySwReleasesAction),
          catchError((err) => of(swUpdateAction.fetchSwUpdateFailedAction(err)))
        )
    )
  );

export const fetchSwManifestEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(swUpdateAction.FETCH_SW_MANIFEST),
    map((action) => action.payload),
    switchMap((id: string) =>
      ajax
        .getJSON<SWManifestEntry>(`${SW_UPDATE_API}/manifest/${id}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(swUpdateAction.loadSwManifestAction),
          catchError((err) => of(swUpdateAction.fetchSwUpdateFailedAction(err)))
        )
    )
  );

export const fetchManySwManifestsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(swUpdateAction.FETCH_MANY_SW_MANIFEST),
    map((action) => action.payload),
    switchMap((qs: QueryString) =>
      ajax
        .getJSON<ManyEntry<SWManifestEntry>>(
          `${SW_UPDATE_API}/manifest?${joinQueryStr(qs)}`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(swUpdateAction.loadManySwManifestsAction),
          catchError((err) => of(swUpdateAction.fetchSwUpdateFailedAction(err)))
        )
    )
  );

export const fetchLatestSwDeploymentsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(swUpdateAction.FETCH_LATEST_SW_DEPLOYMENTS),
    switchMap(() =>
      ajax
        .getJSON<ManyEntry<SWDeploymentEntry>>(
          `${SW_UPDATE_API}/deployment/all_latest`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(swUpdateAction.loadLatestSwDevelopmentsAction),
          catchError((err) => of(swUpdateAction.fetchSwUpdateFailedAction(err)))
        )
    )
  );

export const fetchCurrentInstalledSwEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(swUpdateAction.FETCH_CURRENT_INSTALLED_SW),
    switchMap(() =>
      ajax
        .getJSON<McuEntry[]>(`${SW_UPDATE_API}/current_installed_versions`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(swUpdateAction.loadCurrentInstalledSwAction),
          catchError((err) => of(swUpdateAction.fetchSwUpdateFailedAction(err)))
        )
    )
  );

export const createSwManifestEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(swUpdateAction.CREATE_SW_MANIFEST),
    map((action) => action.payload),
    switchMap((payload: any) =>
      ajax
        .post<SWManifestEntry>(`${SW_UPDATE_API}`, payload, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map((res) => res.response),
          mergeMap((dbUpdate) =>
            of(
              swUpdateAction.loadCreatedSwManifestAction(dbUpdate),
              notificationAction.loadNotificationAction({
                type: `CREATE_SW_UPDATE_SUCCESS`,
                severity: NOTIFICATION_SEVERITY_SUCCESS,
                position: NOTIFICATION_BOTTOM,
                title: `SW Update Created`,
                content: `db update ${dbUpdate.id} (${dbUpdate.version}) was successfully created`,
                dur: 5000,
                created: new Date().toISOString(),
              })
            )
          ),
          catchError((err: AjaxError) => {
            if (err.status === 400 && Array.isArray(err.response?.errors)) {
              return of(
                notificationAction.loadNotificationAction({
                  type: `CREATE_SW_UPDATE_FAILED`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: "Failed to create a db update",
                  content: err.response?.errors
                    .map((m: any) => m.msg)
                    .join(","),
                  dur: 5000,
                  created: new Date().toISOString(),
                })
              );
            }

            return of(swUpdateAction.fetchSwUpdateFailedAction(err));
          })
        )
    )
  );

export const startOrCancelSwUpdateEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(swUpdateAction.START_SW_UPDATE),
    map((action) => action.payload),
    switchMap(({ id, start }) =>
      ajax
        .getJSON<SWDeploymentEntry>(
          `${SW_UPDATE_API}/deployment/${id}/${
            start ? "start_update" : "cancel_update"
          }`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map((d: SWDeploymentEntry) =>
            swUpdateAction.loadSwDeploymentChangeAction({
              id: d.id,
              status: d.status,
              version: d.version,
              reason: d.reason,
              created_at: d.createdAt,
              updated_at: d.updatedAt,
              sw_manifest_id: d.SwManifestId,
              mcu_id: d.McuId,
            })
          ),
          catchError((err) => of(swUpdateAction.fetchSwUpdateFailedAction(err)))
        )
    )
  );

export const initiateSwUpdate = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(swUpdateAction.INITIATE_SW_UPDATE),
    map((action) => action.payload),
    switchMap((payload: InitiateSWUpdatePayload) =>
      ajax
        .post(`${SW_UPDATE_API}/initiate_update`, payload, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          mergeMap((_) =>
            of(
              notificationAction.loadNotificationAction({
                type: `INITIATE_SW_UPDATE`,
                severity: NOTIFICATION_SEVERITY_SUCCESS,
                position: NOTIFICATION_BOTTOM,
                title: `SW Update Initiated`,
                content: `db update (${payload.to_version}) was initiated successfully`,
                dur: 5000,
                created: new Date().toISOString(),
              })
            )
          ),
          catchError((err: AjaxError) => {
            if (Array.isArray(err.response?.errors)) {
              return of(
                notificationAction.loadNotificationAction({
                  type: `INITIATE_SW_UPDATE_FAILED`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: `Failed to initiate db update (${payload.to_version})`,
                  content: err.response?.errors.join(","),
                  dur: 5000,
                  created: new Date().toISOString(),
                }),
                swUpdateAction.openCreatedSwManifestDialogAction(false)
              );
            }

            return of(swUpdateAction.fetchSwUpdateFailedAction(err));
          })
        )
    )
  );

export const fetchManySwDeploymentsForSelectedMcuEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(swUpdateAction.FETCH_MANY_SW_DEPLOYMENTS_FOR_SELECTED_MCU),
    map((action) => action.payload),
    switchMap(({ qs, id }: { qs: QueryString; id: string }) =>
      ajax
        .getJSON<ManyEntry<SWDeploymentEntry>>(
          `${SW_UPDATE_API}/deployment?${joinQueryStr(qs)}&mcu_id=${id}`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(swUpdateAction.loadManySwDeploymentsForSelectedMcuAction),
          catchError((err) => of(swUpdateAction.fetchSwUpdateFailedAction(err)))
        )
    )
  );

export const dbUpdateEpics = [
  fetchManySwReleaseEpic,
  fetchSwManifestEpic,
  fetchManySwManifestsEpic,
  fetchCurrentInstalledSwEpic,
  createSwManifestEpic,
  startOrCancelSwUpdateEpic,
  initiateSwUpdate,
  fetchLatestSwDeploymentsEpic,
  fetchManySwDeploymentsForSelectedMcuEpic,
];
