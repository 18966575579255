import * as vodAction from "../actions/vod.action";
import {
  catchError,
  debounce,
  distinctUntilChanged,
  map,
  switchMap,
} from "rxjs/operators";
import { Observable, of, timer } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import { ManyEntry, VodEntry, VodQs } from "../const/types";
import { ajax } from "rxjs/ajax";
import { VOD_API } from "../const/api";
import { getToken } from "../reducers";
import { joinQueryStr } from "../helper/util";
import { RootState } from "../config/store";
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from "../const/const";

export const fetchManyVodsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(vodAction.FETCH_MANY_VODS),
    map((action) => action.payload),
    debounce((qs: VodQs) =>
      timer(qs.search_value.length > 2 ? DEFAULT_SEARCH_DEBOUNCE_TIME : 0)
    ),
    map((qs: VodQs) => {
      const { search_by, search_value, ...rest } = qs;
      if (!!search_by && !!search_value && search_value.length > 2) {
        return joinQueryStr(qs);
      } else {
        return joinQueryStr(rest);
      }
    }),
    distinctUntilChanged(),
    switchMap((qsStr: string) =>
      ajax
        .getJSON<ManyEntry<VodEntry>>(`${VOD_API}?${qsStr}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(vodAction.loadManyVodAction),
          catchError((err) => of(vodAction.fetchVodFailedAction(err)))
        )
    )
  );

export const vodEpics = [fetchManyVodsEpic];
