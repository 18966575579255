import * as fatActions from "../actions/fat.action";
import { AnyAction } from "redux";
import { Test, TestResultQs, TestResults, Tests } from "../const/types";
import { DEFAULT_TEST_RESULT_QS } from "../const/ui";
import { SetStateAction } from "react";
import { SELECTED_FAT_COMPONENT } from "../const/const";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  components: string[];
  tests: {
    [component: string]: {
      [id: string]: Test;
    };
  };
  testResultQs: TestResultQs;
  testResults: TestResults;
  selectedComponent: string;
  searchComponentInput: string;
  searchComponentResults: string[];
  searchLoading: boolean;
}

const selectedComponent =
  localStorage.getItem(SELECTED_FAT_COMPONENT) || "uwb_board";
const initialState: State = {
  components: [selectedComponent],
  tests: {},
  testResults: {
    rows: [],
    count: 0,
  },
  testResultQs: DEFAULT_TEST_RESULT_QS,
  selectedComponent,
  searchComponentInput: "",
  searchComponentResults: [],
  searchLoading: false,
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case fatActions.SELECT_COMPONENT: {
      return {
        ...state,
        testResults: initialState.testResults,
        selectedComponent: action.payload,
        testResultQs: DEFAULT_TEST_RESULT_QS,
      };
    }

    case fatActions.LOAD_COMPONENT_LIST: {
      return { ...state, components: action.payload };
    }
    case fatActions.SET_TEST_RESULT_QUERY_STRING: {
      const setter: SetStateAction<TestResultQs> = action.payload;
      return {
        ...state,
        searchComponentInput: "",
        testResultQs:
          typeof setter === "function" ? setter(state.testResultQs) : setter,
      };
    }

    case fatActions.LOAD_TESTS: {
      const payload: { component: string; tests: Tests } = action.payload;
      return {
        ...state,
        tests: {
          ...state.tests,
          [payload.component]: payload.tests.rows.reduce<{
            [id: string]: Test;
          }>((acc, t) => ({ ...acc, [t.id]: t }), {}),
        },
      };
    }

    case fatActions.LOAD_TEST_RESULTS: {
      const payload: TestResults = action.payload;
      return {
        ...state,
        searchLoading: false,
        testResults: payload,
      };
    }

    case fatActions.SEARCH_COMPONENT: {
      return {
        ...state,
        searchComponentInput: action.payload,
        searchLoading:
          action.payload.length > 3 &&
          state.testResultQs.test_target_id !== action.payload,
      };
    }

    case fatActions.LOAD_SEARCH_COMPONENT_RESULT: {
      const payload: string[] = action.payload;
      return {
        ...state,
        searchComponentResults: payload,
        searchLoading: false,
      };
    }

    case fatActions.FETCH_FAT_DATA_FAILED:
    case FORBIDDEN_ERROR:
      return { ...state, searchLoading: false };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getFATComponentList = (state: State) => state.components;
export const getFATests = (state: State) =>
  state.tests[state.selectedComponent] || {};
export const getSelectedComponent = (state: State) => state.selectedComponent;
export const getTestResultQs = (state: State) => state.testResultQs;
export const getTestResults = (state: State) => state.testResults;
export const getComponentSearchResults = (state: State) =>
  state.searchComponentResults;
export const getSearchLoading = (state: State) => state.searchLoading;
export const getSearchComponentInput = (state: State) =>
  state.searchComponentInput;
