/**
 * Action types
 */
import {
  ComponentSearchResult,
  QueryString,
  TestResultQs,
  TestResults,
  Tests,
} from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";
import { SetStateAction } from "react";

export const SELECT_COMPONENT = Symbol.for("SELECT_COMPONENT");
export const FETCH_TESTS = Symbol.for("FETCH_TESTS");
export const LOAD_TESTS = Symbol.for("LOAD_TESTS");
export const FETCH_COMPONENT_LIST = Symbol.for("FETCH_COMPONENT_LIST");
export const LOAD_COMPONENT_LIST = Symbol.for("LOAD_COMPONENT_LIST");
export const SET_TEST_RESULT_QUERY_STRING = Symbol.for(
  "SET_TEST_RESULT_QUERY_STRING"
);
export const FETCH_TEST_RESULTS = Symbol.for("FETCH_TEST_RESULTS");
export const LOAD_TEST_RESULTS = Symbol.for("LOAD_TEST_RESULTS");
export const FETCH_COMPONENTS = Symbol.for("FETCH_COMPONENTS");
export const FETCH_FAT_DATA_FAILED = Symbol.for("FETCH_FAT_DATA_FAILED");
export const SEARCH_COMPONENT = Symbol.for("SEARCH_COMPONENT");
export const LOAD_SEARCH_COMPONENT_RESULT = Symbol.for(
  "LOAD_SEARCH_COMPONENT_RESULT"
);

/**
 * Actions
 */

export function selectComponentAction(component: string) {
  return {
    type: SELECT_COMPONENT,
    payload: component,
  };
}

export function setTestResultQsAction(payload: SetStateAction<TestResultQs>) {
  return {
    type: SET_TEST_RESULT_QUERY_STRING,
    payload,
  };
}

export function fetchComponentListAction() {
  return {
    type: FETCH_COMPONENT_LIST,
  };
}

export function loadComponentListAction(components: string[]) {
  return {
    type: LOAD_COMPONENT_LIST,
    payload: components,
  };
}

export function fetchTestsAction(component: string, qs: QueryString) {
  return {
    type: FETCH_TESTS,
    payload: {
      component,
      qs,
    },
  };
}

export function loadTestsAction(component: string, tests: Tests) {
  return {
    type: LOAD_TESTS,
    payload: { component, tests },
  };
}

export function fetchTestResultsAction(component: string, qs: TestResultQs) {
  return {
    type: FETCH_TEST_RESULTS,
    payload: {
      component,
      qs,
    },
  };
}

export function loadTestResultsAction(results: TestResults) {
  return {
    type: LOAD_TEST_RESULTS,
    payload: results,
  };
}

export function searchComponentAction(idLike: string) {
  return {
    type: SEARCH_COMPONENT,
    payload: idLike,
  };
}

export function loadSearchComponentResult(sr: ComponentSearchResult) {
  return {
    type: LOAD_SEARCH_COMPONENT_RESULT,
    payload: sr.ids,
  };
}

export function fetchFATFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_FAT_DATA_FAILED,
    payload: err.toString(),
  };
}
