import * as deviceMonitoringAction from "../actions/device_monitoring.action";
import { AnyAction } from "redux";
import { HeartbeatDropoutEntry } from "../const/types";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  entries: HeartbeatDropoutEntry[] | null;
  selected: HeartbeatDropoutEntry | null;
  loading: boolean;
}

const initialState: State = {
  entries: null,
  selected: null,
  loading: false,
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case deviceMonitoringAction.FETCH_MANY_HB_DROPOUTS: {
      return { ...state, loading: true };
    }

    case deviceMonitoringAction.LOAD_MANY_HB_DROPOUTS: {
      return {
        ...state,
        entries: action.payload.map(({ data, ...rest }: any) => {
          const modemLastActive = data.modemOnline
            ? new Date(rest.updatedAt).getTime()
            : new Date(data.modemOfflineAt).getTime();
          const mcuOfflineFor =
            Math.max(
              new Date(data.thinClientLastActive).getTime(),
              modemLastActive
            ) - new Date(data.mcuUpdatedAt).getTime();
          return Object.assign(rest, {
            ...data,
            mcuOfflineFor,
            modemLastActive,
          });
        }),
        loading: false,
        mcuFailureCount: action.payload.length,
      };
    }

    case deviceMonitoringAction.FETCH_HB_DROPOUT_FAILED:
    case FORBIDDEN_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getHeartbeatDropouts = (state: State) => state.entries;
