import { combineEpics } from "redux-observable";
import { authEpics } from "./auth.epic";
import { catchError } from "rxjs/operators";
import { mcuEpics } from "./mcu.epic";
import { vehicleEpics } from "./vehicle.epic";
import { installationEpics } from "./installation.epic";
import { userEpics } from "./user.epic";
import { vpuEpics } from "./vpu.epic";
import { vodEpics } from "./vod.epic";
import { notificationEpics } from "./notification.epic";
import { alertEpics } from "./alert.epic";
import { messageEpics } from "./message.epic";
import { basestationEpics } from "./basestation.epic";
import { trackEpics } from "./track.epic";
import { fatEpics } from "./fat.epic";
import { settingsEpics } from "./settings.epic";
import { dbUpdateEpics } from "./swupdate.epic";
import { deviceMonitoringEpics } from "./device_monitoring.epic";
import { otaEpics } from "./mender.epic";
import { videoEpics } from "./video.epic";
import { searchEpics } from "./search.epic";
import { cabCameraEpics } from "./cabcamera.epic";
import { workGangEpics } from "./workgang.epic";

export default function rootEpic(action$: any, store$: any, dependencies: any) {
  return combineEpics(
    ...authEpics,
    ...mcuEpics,
    ...vehicleEpics,
    ...installationEpics,
    ...userEpics,
    ...vpuEpics,
    ...vodEpics,
    ...notificationEpics,
    ...alertEpics,
    ...messageEpics,
    ...basestationEpics,
    ...trackEpics,
    ...fatEpics,
    ...settingsEpics,
    ...dbUpdateEpics,
    ...deviceMonitoringEpics,
    ...otaEpics,
    ...videoEpics,
    ...searchEpics,
    ...cabCameraEpics,
    ...workGangEpics
  )(action$, store$, dependencies).pipe(
    catchError((error, source) => {
      console.error(error);
      return source;
    })
  );
}
