import * as alertAction from "../actions/alert.action";
import { AnyAction } from "redux";
import {
  AlertEntry,
  AlertQs,
  AlertTypeEntry,
  AlertTypeObject,
  ManyEntry,
} from "../const/types";
import { DEFAULT_ALERT_QS } from "../const/ui";
import { SetStateAction } from "react";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  entries: AlertEntry[];
  count: number;
  selected: AlertEntry | null;
  loading: boolean;
  types: AlertTypeObject;
  qs: AlertQs;
  searchInputValue: string;
  searchCount: number;
  searchEntries: string[];
  searchLoading: boolean;
}

const initialState: State = {
  entries: [],
  count: 0,
  selected: null,
  loading: false,
  types: {},
  qs: DEFAULT_ALERT_QS,
  searchInputValue: "",
  searchCount: 0,
  searchEntries: [],
  searchLoading: false,
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction): State {
  switch (action.type) {
    case alertAction.LOAD_MANY_ALERTS: {
      const { rows, count } = action.payload as ManyEntry<AlertEntry>;
      return { ...state, entries: rows, count, loading: false };
    }

    case alertAction.LOAD_ALERT_TYPES: {
      const types = (
        action.payload as AlertTypeEntry[]
      ).reduce<AlertTypeObject>((acc, t) => ({ ...acc, [t.id]: t }), {});
      return { ...state, types };
    }

    case alertAction.APPEND_ALERT: {
      if (
        state.qs.offset !== 0 ||
        state.qs.order !== "createdAt" ||
        state.qs.dir === "desc" ||
        state.qs.filter_value
      ) {
        return state;
      }

      if (state.entries.length === state.qs.limit) {
        state.entries.pop();
      }
      const entries = [action.payload, ...state.entries];
      return { ...state, entries, count: state.count + 1 };
    }

    case alertAction.SET_SEARCH_INPUT_VALUE: {
      return {
        ...state,
        searchInputValue: action.payload,
        searchLoading: action.payload.length >= 3,
      };
    }

    case alertAction.SET_SEARCH_RESULTS: {
      const { count, rows } = action.payload;
      return {
        ...state,
        searchCount: count,
        searchEntries: rows,
        searchLoading: false,
      };
    }

    case alertAction.SET_ALERT_QUERY_STRING: {
      const setStateAction: SetStateAction<AlertQs> = action.payload;
      let qs: AlertQs;
      if (typeof setStateAction === "function") {
        qs = setStateAction(state.qs);
      } else {
        qs = setStateAction;
      }
      return { ...state, searchCount: 0, searchEntries: [], qs };
    }

    case alertAction.FETCH_ALERTS_FAILED:
    case FORBIDDEN_ERROR:
      return { ...state, loading: false, searchLoading: false };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getAlerts = (state: State) => ({
  entries: state.entries,
  count: state.count,
});
export const getAlertQs = (state: State) => state.qs;
export const getAlertTypes = (state: State) => state.types;
export const getAlertSearch = (state: State) => ({
  entries: state.searchEntries,
  count: state.searchCount,
  inputValue: state.searchInputValue,
  loading: state.searchLoading,
});
