import * as otaAction from "../actions/mender.action";
import { catchError, first, map, mergeMap, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import {
  createMenderDeploymentPayload,
  MenderDeploymentEntry,
  ReleaseEntry,
} from "../const/types";
import { ajax } from "rxjs/ajax";
import { MENDER_API } from "../const/api";
import { getToken } from "../reducers";
import { RootState } from "../config/store";
import * as notificationAction from "../actions/notification.action";
import {
  NOTIFICATION_SEVERITY_ERROR,
  NOTIFICATION_SEVERITY_SUCCESS,
} from "../const/const";
import { NOTIFICATION_BOTTOM } from "../const/ui";

export const fetchCurrentReleaseEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(otaAction.FETCH_CURRENT_RELEASE),
    first(),
    switchMap(() =>
      ajax
        .getJSON<ReleaseEntry>(`${MENDER_API}/current_release`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(otaAction.loadCurrentReleaseAction),
          catchError((err) => of(otaAction.fetchOTAFailedAction(err)))
        )
    )
  );

export const createMenderDeploymentsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(otaAction.CREATE_MENDER_DEPLOYMENTS),
    map((action) => action.payload),
    switchMap((payload: createMenderDeploymentPayload) =>
      ajax
        .post<any>(`${MENDER_API}/deployment_with_mender_ids`, payload, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map((res) => res.response),
          map(({ id }) =>
            notificationAction.loadNotificationAction({
              type: `CREATE_DEPLOYMENT`,
              severity: NOTIFICATION_SEVERITY_SUCCESS,
              position: NOTIFICATION_BOTTOM,
              title: "create deployment",
              content: `deployment ${id} was created`,
              dur: 10000,
              created: new Date().toISOString(),
            })
          ),
          catchError((err) => {
            return of(
              notificationAction.loadNotificationAction({
                type: `DEPLOYMENT_FAILED`,
                severity: NOTIFICATION_SEVERITY_ERROR,
                position: NOTIFICATION_BOTTOM,
                title: "failed to create a deployment",
                content: JSON.stringify(err.response),
                dur: 10000,
                created: new Date().toISOString(),
              })
            );
          })
        )
    )
  );

export const fetchManyMenderDeploymentsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(otaAction.FETCH_MANY_MENDER_DEPLOYMENTS),
    map((action) => action.payload),
    mergeMap((id: string) =>
      ajax
        .getJSON<MenderDeploymentEntry[]>(
          `${MENDER_API}/deployment_created_by_hem?device_id=${id}`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map((deployments) =>
            otaAction.loadManyMenderDeploymentsAction(id, deployments)
          ),
          catchError((err) => of(otaAction.fetchOTAFailedAction(err)))
        )
    )
  );

export const otaEpics = [
  fetchCurrentReleaseEpic,
  createMenderDeploymentsEpic,
  fetchManyMenderDeploymentsEpic,
];
