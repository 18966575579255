/**
 * Action types
 */
import {
  ManyEntry,
  SearchComponentType,
  SearchDeviceActionPayload,
} from "../const/types";

export const SET_DEVICE_SEARCH_INPUT = Symbol.for("SET_DEVICE_SEARCH_INPUT");
export const RESET_DEVICE_SEARCH_INPUT = Symbol.for(
  "RESET_DEVICE_SEARCH_INPUT"
);
export const SEARCH_DEVICE = Symbol.for("SEARCH_DEVICE");
export const LOAD_DEVICE_SEARCH_RESULTS = Symbol.for("SET_SEARCH_RESULTS");

/**
 * Actions
 */
export function setDeviceSearchInputAction(payload: SearchDeviceActionPayload) {
  return {
    type: SET_DEVICE_SEARCH_INPUT,
    payload,
  };
}

export function resetDeviceSearchInputAction() {
  return {
    type: RESET_DEVICE_SEARCH_INPUT,
  };
}

export function searchDeviceAction(payload: SearchDeviceActionPayload) {
  return {
    type: SEARCH_DEVICE,
    payload,
  };
}

export function loadDeviceSearchResultsAction(
  component: SearchComponentType,
  payload: ManyEntry<string>
) {
  return {
    type: LOAD_DEVICE_SEARCH_RESULTS,
    payload: {
      ...payload,
      component,
    },
  };
}
