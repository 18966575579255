import * as authAction from "../actions/auth.action";
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { EMPTY, from, Observable, of, timer } from "rxjs";
import { ajax, AjaxError } from "rxjs/ajax";
import { push } from "redux-first-history";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { AUTH_API, DASHBOARD_API, LOCAL_TOKEN, USER_API } from "../const/api";
import { getMe, getAuthState, getToken } from "../reducers";
import {
  APP_ID,
  LOCAL,
  NOTIFICATION_SEVERITY_ERROR,
  NOTIFICATION_SEVERITY_SUCCESS,
  ONE_MINUTE,
} from "../const/const";
import { RootState } from "../config/store";
import { AccountInfo } from "@azure/msal-common";
import { loadNotificationAction } from "../actions/notification.action";
import {
  FAT_PATH,
  FLEET_PATH,
  FORBIDDEN_PATH,
  NOTIFICATION_BOTTOM,
  NOTIFICATION_TOP_RIGHT,
} from "../const/ui";
import { scopes } from "../config/msal";

interface Injected {
  msalInstance: PublicClientApplication;
}

export const loginWithActiveDirectoryEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>,
  { msalInstance }: Injected
) =>
  action$.pipe(
    ofType(authAction.LOGIN_WITH_AZURE_AD),
    map((action) => action.payload),
    switchMap(() =>
      from(msalInstance.loginRedirect({ scopes })).pipe(
        ignoreElements(),
        catchError((err) => of(authAction.loginWithAzureAdFailedAction(err)))
      )
    )
  );

export const handleADLoginRedirect = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>,
  { msalInstance }: Injected
) =>
  from(msalInstance.handleRedirectPromise()).pipe(
    switchMap((authenticationResult) => {
      if (authenticationResult === null) {
        return EMPTY;
      } else {
        const { accessToken, account, expiresOn } = authenticationResult;
        return of(
          authAction.loadTokenAction(accessToken),
          authAction.fetchMyInfoAction(accessToken, "/fleet"),
          authAction.refreshADTokenAction(account, expiresOn)
        ).pipe(
          catchError((err) => of(authAction.loginWithAzureAdFailedAction(err)))
        );
      }
    }),
    catchError((err) => of(authAction.loginWithAzureAdFailedAction(err)))
  );

export const acquireADIdTokenEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>,
  { msalInstance }: Injected
) =>
  action$.pipe(
    ofType(authAction.ACQUIRE_AD_ID_TOKEN),
    map((action) => action.payload),
    switchMap(({ account }) =>
      from(msalInstance.acquireTokenSilent({ scopes, account })).pipe(
        mergeMap((authenticationResult) => {
          const { accessToken, account, expiresOn } = authenticationResult;
          const actions: any[] = [
            authAction.loadTokenAction(accessToken),
            authAction.fetchMyInfoAction(accessToken),
            authAction.refreshADTokenAction(account, expiresOn),
          ];
          return of(...actions).pipe(
            catchError((err) =>
              of(authAction.acquireADIDTokenFailedAction(err))
            )
          );
        }),
        catchError((err) =>
          of({
            type: authAction.ACQUIRE_AD_ID_TOKEN_FAILED,
            payload: err.toString(),
            error: true,
          })
        )
      )
    )
  );

export const loginLocalEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    ofType(authAction.LOGIN),
    map((action) => action.payload), //credential
    switchMap(({ email, password }) =>
      ajax.post(AUTH_API + "/signin", { email, password }).pipe(
        map((res: any) => res.response),
        mergeMap(({ token }) =>
          of(
            authAction.storeTokenInSessionStorageAction(token),
            authAction.loadTokenAction(token),
            authAction.fetchMyInfoAction(token),
            push(
              email === "assembler@pipernetworks.com" ? FAT_PATH : FLEET_PATH // TODO: HACK
            )
          )
        ),
        catchError((err) => {
          /// 401 error
          return of(authAction.handleLogInError("invalid email or password"));
        })
      )
    )
  );

export const storeTokenInSessionStorageEpic = (
  action$: Observable<AnyAction>
) =>
  action$.pipe(
    ofType(authAction.STORE_TOKEN_FROM_SESSION_STORAGE),
    map((action) => action.payload),
    map((token) => sessionStorage.setItem(LOCAL_TOKEN, token)),
    ignoreElements()
  );

export const getTokenFromSessionStorageEpic = (
  action$: Observable<AnyAction>,
  _: any,
  { msalInstance }: Injected
) =>
  of(null).pipe(
    map(() => sessionStorage.getItem(LOCAL_TOKEN)),
    mergeMap((token) => {
      const currentPath = window.location.pathname;
      if (currentPath === "/login") {
        return EMPTY;
      } else if (token) {
        return of(
          authAction.loadTokenAction(token),
          authAction.fetchMyInfoAction(token),
          push(currentPath)
        ).pipe(
          catchError((err) => {
            return of(authAction.fetchMyInfoFailedAction(err));
          })
        );
      } else {
        const adAccounts = msalInstance.getAllAccounts();
        if (adAccounts.length > 0) {
          return of(authAction.acquireADIDTokenAction(adAccounts[0]));
        } else {
          return of(push("/login"));
        }
      }
    }),
    catchError(() => of(authAction.logoutAction()))
  );

export const fetchMyInfoEpic = (action$: Observable<AnyAction>) =>
  action$.pipe(
    filter((action) => action.type === authAction.FETCH_MY_INFO),
    map((action) => action.payload), //credential
    switchMap(({ token, path }) =>
      ajax
        .getJSON(USER_API + "/me", {
          Authorization: `Bearer ${token}`,
          "app-id": APP_ID,
        })
        .pipe(
          mergeMap((me) => {
            if (path) {
              return of(push(path), authAction.loadMyInfoAction(me));
            }
            return of(authAction.loadMyInfoAction(me));
          }),
          catchError((err) => {
            if (err.status === 403) {
              return of(
                push(FORBIDDEN_PATH, { error: err.response?.errors?.[0] })
              );
            }
            return of(authAction.logoutAction());
          })
        )
    )
  );

export const submitPasswordChangeEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(authAction.SUBMIT_PASSWORD_CHANGE),
    switchMap(() => {
      const state = getAuthState(state$.value);
      return ajax({
        url: AUTH_API + "/change_password",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: state.me.email,
          password: state.oldPassword,
          new_password: state.newPassword,
        }),
      }).pipe(
        switchMap(() =>
          of(
            loadNotificationAction({
              type: "PASSWORD_CHANGE_SUCCESS",
              severity: NOTIFICATION_SEVERITY_SUCCESS,
              position: NOTIFICATION_BOTTOM,
              title: "Changed password",
              content: `Password changed successfully.`,
              dur: 5000,
              created: new Date().toISOString(),
            }),
            authAction.closePasswordDialogAction()
          )
        ),
        catchError((error) => {
          if (error.status === 401) {
            return of(
              loadNotificationAction({
                type: "WRONG_PASSWORD",
                severity: NOTIFICATION_SEVERITY_ERROR,
                position: NOTIFICATION_BOTTOM,
                title: "Wrong Password",
                content: `You did not enter the correct password for ${state.me.email}`,
                dur: 5000,
                created: new Date().toISOString(),
              }),
              authAction.closePasswordDialogAction()
            );
          }
          return of(
            loadNotificationAction({
              type: "PASSWORD_CHANGE_ERROR",
              severity: NOTIFICATION_SEVERITY_ERROR,
              position: NOTIFICATION_BOTTOM,
              title: "Password Change Error",
              content: "There was an error while changing your password",
              dur: 5000,
              created: new Date().toISOString(),
            }),
            authAction.closePasswordDialogAction()
          );
        })
      );
    })
  );

export const logOutEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>,
  { msalInstance }: Injected
) =>
  action$.pipe(
    ofType(authAction.LOG_OUT),
    tap(() => sessionStorage.removeItem(LOCAL_TOKEN)),
    switchMap(() => {
      const after = { type: authAction.AFTER_LOG_OUT };
      const me = getMe(state$.value);
      if (me.provider !== LOCAL && msalInstance.getAllAccounts().length > 0) {
        return of(after).pipe(
          switchMap(() =>
            from(msalInstance.logoutRedirect()).pipe(ignoreElements())
          )
        );
      } else {
        return of(after, push("/login"));
      }
    })
  );

export const handleUnauthorizedErrorEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>,
  { msalInstance }: Injected
) =>
  action$.pipe(
    ofType(authAction.UNAUTHORIZED_ERROR),
    tap(() => sessionStorage.removeItem(LOCAL_TOKEN)),
    switchMap(() => {
      const provider = getMe(state$.value).provider;
      if (provider) {
        return of(
          authAction.logoutAction(),
          loadNotificationAction({
            type: "SESSION_EXPIRED",
            severity: NOTIFICATION_SEVERITY_ERROR,
            position: NOTIFICATION_TOP_RIGHT,
            title: "session expired",
            content: "please login again",
            dur: 1000 * 60 * 60 * 24,
            created: new Date().toISOString(),
          })
        );
      } else {
        return of(push("/login"));
      }
    })
  );

export const handleForbiddenErrorEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(authAction.FORBIDDEN_ERROR),
    switchMap(() => {
      const provider = getMe(state$.value).provider;
      if (provider) {
        return of(
          loadNotificationAction({
            type: "FORBIDDEN",
            severity: NOTIFICATION_SEVERITY_ERROR,
            position: NOTIFICATION_TOP_RIGHT,
            title: "Forbidden",
            content: "Error: This action is forbidden",
            dur: 1000 * 60 * 60 * 24,
            created: new Date().toISOString(),
          })
        );
      } else {
        return of(push("/login"));
      }
    })
  );

export const refreshADTokenEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>,
  { msalInstance }: Injected
) =>
  action$.pipe(
    ofType(authAction.REFRESH_AD_TOKEN),
    map((action) => action.payload),
    filter((payload) => !!payload.account),
    switchMap((payload: { account: AccountInfo; exp: Date }) => {
      return timer(payload.exp.getTime() - Date.now() - ONE_MINUTE).pipe(
        takeUntil(action$.pipe(ofType(authAction.LOG_OUT))),
        map(() => {
          return authAction.acquireADIDTokenAction(payload.account);
        })
      );
    })
  );

export const checkVersionEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
) =>
  action$.pipe(
    ofType(authAction.CHECK_VERSION),
    switchMap(() =>
      ajax({
        url: DASHBOARD_API + "/version",
        method: "POST",
        headers: {
          Authorization: `Bearer ${getToken(state$.value)}`,
        },
        body: {
          app_version: process.env.REACT_APP_VERSION,
        },
      }).pipe(
        map((res: any) => authAction.loadVersionsAction(res.response)),
        catchError((err: AjaxError) => {
          return of(authAction.loadVersionsAction(err.response));
        })
      )
    )
  );

export const authEpics = [
  loginWithActiveDirectoryEpic,
  acquireADIdTokenEpic,
  handleADLoginRedirect,
  loginLocalEpic,
  storeTokenInSessionStorageEpic,
  getTokenFromSessionStorageEpic,
  logOutEpic,
  fetchMyInfoEpic,
  handleUnauthorizedErrorEpic,
  handleForbiddenErrorEpic,
  refreshADTokenEpic,
  submitPasswordChangeEpic,
  checkVersionEpic,
];
