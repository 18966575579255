/**
 * Action types
 */
import { ManyEntry, VodEntry, VodQs } from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_MANY_VODS = Symbol.for("FETCH_MANY_VODS");
export const FETCH_SINGLE_VOD = Symbol.for("FETCH_SINGLE_VOD");
export const FETCH_VODS_FAILED = Symbol.for("FETCH_VODS_FAILED");
export const LOAD_MANY_VODS = Symbol.for("LOAD_MANY_VODS");

/**
 * Actions
 */
export function fetchManyVodAction(qs: VodQs) {
  return {
    type: FETCH_MANY_VODS,
    payload: qs,
  };
}

export function fetchVodFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_VODS_FAILED,
    payload: err.toString(),
  };
}

export function loadManyVodAction(payload: ManyEntry<VodEntry>) {
  return {
    type: LOAD_MANY_VODS,
    payload,
  };
}
