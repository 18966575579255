import { ONE_SECOND } from "../const/const";
import { McuEntry, Pins, VpuEntry } from "../const/types";
import redPinSmall from "../asset/red-pin-small.png";
import greenPinSmall from "../asset/green-pin-small.png";
import grayPinSmall from "../asset/gray-pin-small.png";
import bluePinSmall from "../asset/blue-pin-small.png";
import redPinLarge from "../asset/red-pin-large.png";
import greenPinLarge from "../asset/green-pin-large.png";
import grayPinLarge from "../asset/gray-pin-large.png";
import bluePinLarge from "../asset/blue-pin-large.png";
import yellowPinSmall from "../asset/yellow-pin-small.png";
import yellowPinLarge from "../asset/yellow-pin-large.png";
import ufoLarge from "../asset/ufo_large.png";
import ufoSmall from "../asset/ufo_small.png";
import orangeTrainPin from "../asset/orange-pin_nr.svg";
import greenTrainPin from "../asset/green-pin_nr.svg";
import grayTrainPin from "../asset/grey-pin_nr.svg";
import { usingDetailHealthPackets } from "./util";
import mp_flag from "../asset/mp-flag.png";

const redPins: Pins = { small: redPinSmall, large: redPinLarge };
const greenPins: Pins = { small: greenPinSmall, large: greenPinLarge };
const grayPins: Pins = { small: grayPinSmall, large: grayPinLarge };
const bluePins: Pins = { small: bluePinSmall, large: bluePinLarge };
const yellowPins: Pins = { small: yellowPinSmall, large: yellowPinLarge };
const ufoPins: Pins = { small: ufoSmall, large: ufoLarge };

export const getSymbol = (
  symbol: string,
  selected?: boolean,
  status?: any,
  time?: number,
  color?: string
): google.maps.Symbol => {
  switch (symbol) {
    case "mcuCircle": 
      return {
        path: google.maps.SymbolPath.CIRCLE,
        scale: selected ? 20 : 17,
        fillColor: status === true ? "#0ea410" : "#b61d1d",
        fillOpacity: selected ? 1 : 0.8,
        strokeWeight: selected ? 2 : 0.5,
        strokeColor: "white",
      };
    

    case "mcuPageCircle": 
      return {
        path: google.maps.SymbolPath.CIRCLE,
        scale: selected ? 20 : 17,
        fillColor: time
          ? time < ONE_SECOND * 10
            ? "#0ea410"
            : "#b61d1d"
          : "gray",
        fillOpacity: selected ? 1 : 0.8,
        strokeWeight: selected ? 2 : 0.5,
        strokeColor: "white",
      };
    

    default:
      return {
        path: google.maps.SymbolPath.CIRCLE,
        scale: selected ? 20 : 17,
        fillColor: status === true ? "#0ea410" : "#b61d1d",
        fillOpacity: selected ? 1 : 0.8,
        strokeWeight: selected ? 2 : 0.5,
        strokeColor: "white",
        labelOrigin: new google.maps.Point(33, 33),
      };
  }
};

export const getIcon = (
  icon?: string,
  color?: string,
  selected = false,
  labelText?: string
): google.maps.Icon => {
  switch (icon) {
    case "pin": {
      let result: Pins = grayPins;
      const colorLower = color?.toLowerCase();

      switch (colorLower) {
        case "red":
          result = redPins;
          break;

        case "green":
          result = greenPins;
          break;

        case "blue":
          result = bluePins;
          break;

        case "grey":
        case "gray":
          result = grayPins;
          break;

        case "ufo":
          result = ufoPins;
          break;

        default:
          result = yellowPins;
      }
      return {
        url: selected ? result.large : result.small,
        labelOrigin: selected
          ? new google.maps.Point(17, 17)
          : new google.maps.Point(13, 13),
      };
    }

    case "mpRect":
      const mp_flag_width = 33;
      const mp_flag_height = 16;
      // TODO change labelOrigin based on labelText.length
      // After milepost symbology is finalized
      return {
        url: mp_flag,
        labelOrigin: new google.maps.Point(
          mp_flag_width / 2,
          mp_flag_height / 2
        ),
        scaledSize: new google.maps.Size(mp_flag_width, mp_flag_height),
      };

    default:
      return {
        url: grayPinSmall,
        labelOrigin: new google.maps.Point(13, 13),
      };
  }
};

export const getTrainIconColor = (entry: McuEntry): string => {
  if (entry.maintenance) {
    return "secondary";
  }
  if (!entry.online) {
    return "error";
  }
  if (!usingDetailHealthPackets(entry.swVersion)) {
    const working_comps = entry.Vpus.reduce(
      (acc: any, v: VpuEntry) =>
        acc + v.gps + v.imu + v.camera + v.uwb + (v.lidar > 0 ? 1 : 0),
      0
    );
    if (working_comps === entry.Vpus.length * 5) {
      return "success";
    } else {
      return "warning";
    }
  } else {
    const normal = entry.Vpus.every((v: VpuEntry) => {
      const normSt = [0, 20];
      return (
        normSt.includes(+v.gps) &&
        normSt.includes(+v.imu) &&
        normSt.includes(+v.camera) &&
        normSt.includes(+v.uwb) &&
        normSt.includes(v.lidar)
      );
    });
    if (normal) {
      return "success";
    } else {
      return "warning";
    }
  }
};

export const getTrainPin = (entry: McuEntry, selected: boolean) => {
  const color = getTrainIconColor(entry);
  let url = orangeTrainPin;
  switch (color) {
    case "success":
      url = greenTrainPin;
      break;
    case "warning":
      url = orangeTrainPin;
      break;
    case "error":
      url = grayTrainPin;
      break;
  }
  /** Train pin sizes*/
  const TRN_PIN_SEL = 50;
  const TRN_PIN_UNSEL = 40;
  return {
    url: url,
    scaledSize: selected
      ? new google.maps.Size(TRN_PIN_SEL, TRN_PIN_SEL)
      : new google.maps.Size(TRN_PIN_UNSEL, TRN_PIN_UNSEL),
    labelOrigin: new google.maps.Point(
      TRN_PIN_SEL / (selected ? 2 : 2.5), // x-offset
      -8 // y-offset
    ),
  };
};

export const getLabel = (label: string, selected?: boolean, status?: any) => {
  switch (label) {
    case "mcuCircle": {
      return {
        fontFamily: "Monospace",
        fontSize: selected ? "10px" : "8px",
        color: "white",
        fontWeight: "bold",
      };
    }

    case "milepost": {
      return {
        fontFamily: "Monospace",
        fontSize: "10px",
        color: "white",
      };
    }

    case "pin": {
      return {
        fontFamily: "Monospace",
        fontSize: selected ? "10px" : "8px",
        labelOrigin: [24, 45],
      };
    }

    case "trainPin": {
      return {
        fontFamily: "Monospace",
        fontSize: selected ? "16px" : "13px",
        color: "black",
        fontWeight: "bold",
        labelOrigin: [24, 45],
        className: "trainPin",
      };
    }

    default:
      return {
        fontFamily: "Monospace",
        fontSize: "8px",
        color: "white",
        fontWeight: "bold",
      };
  }
};
