/**
 * Action types
 */
import { HeartbeatDropoutEntry, ManyEntry } from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_MANY_HB_DROPOUTS = Symbol.for("FETCH_MANY_HB_DROPOUTS");
export const LOAD_MANY_HB_DROPOUTS = Symbol.for("LOAD_MANY_HB_DROPOUTS");
export const FETCH_HB_DROPOUT_FAILED = Symbol.for("FETCH_HB_DROPOUT_FAILED");

/**
 * Actions
 */
export function fetchManyHeartbeatDropoutsAction() {
  return {
    type: FETCH_MANY_HB_DROPOUTS,
  };
}

export function loadManyHeartbeatDropoutsAction(
  payload: ManyEntry<HeartbeatDropoutEntry>
) {
  return {
    type: LOAD_MANY_HB_DROPOUTS,
    payload,
  };
}

export function fetchHeartbeatDropoutFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_HB_DROPOUT_FAILED,
    payload: err.toString(),
  };
}
