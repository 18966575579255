import * as cabCameraAction from "../actions/cabcamera.action";
import {
  catchError,
  debounce,
  distinctUntilChanged,
  map,
  switchMap,
} from "rxjs/operators";
import { Observable, of, timer } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import { ManyEntry, CabCameraEntry, CabCameraQs } from "../const/types";
import { ajax } from "rxjs/ajax";
import { CAB_CAM_API } from "../const/api";
import { getToken } from "../reducers";
import { joinQueryStr } from "../helper/util";
import { RootState } from "../config/store";
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from "../const/const";

export const fetchManyCabCamerasEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(cabCameraAction.FETCH_MANY_CAB_CAMS),
    map((action) => action.payload),
    debounce((qs: CabCameraQs) =>
      timer(qs.search_value.length > 2 ? DEFAULT_SEARCH_DEBOUNCE_TIME : 0)
    ),
    map((qs: CabCameraQs) => {
      const { search_by, search_value, ...rest } = qs;
      if (!!search_by && !!search_value && search_value.length > 2) {
        return joinQueryStr(qs);
      } else {
        return joinQueryStr(rest);
      }
    }),
    distinctUntilChanged(),
    switchMap((qsStr: string) =>
      ajax
        .getJSON<ManyEntry<CabCameraEntry>>(`${CAB_CAM_API}?${qsStr}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(cabCameraAction.loadManyCabCameraAction),
          catchError((err) =>
            of(cabCameraAction.fetchCabCameraFailedAction(err))
          )
        )
    )
  );

export const cabCameraEpics = [fetchManyCabCamerasEpic];
