import { MapPoint } from "../const/types";

/**
 * Action types
 */
export const SET_MAP = Symbol.for("SET_MAP");
export const SET_MAP_CENTER = Symbol.for("SET_MAP_CENTER");
export const SET_MAP_ZOOM = Symbol.for("SET_MAP_ZOOM");
export const SET_MAP_POINTS = Symbol.for("SET_MAP_POINTS");
export const SET_MAP_CLUSTER_POINTS = Symbol.for("SET_MAP_CLUSTER_POINTS");
export const SET_MAP_LINES = Symbol.for("SET_MAP_LINES");
export const SET_MAP_ON_BOUNDS_CHANGE = Symbol.for("SET_MAP_ON_BOUNDS_CHANGE");
export const SET_MAP_ON_ZOOM_CHANGE = Symbol.for("SET_MAP_ON_ZOOM_CHANGE");
export const SET_MAP_ON_CENTER_CHANGE = Symbol.for("SET_MAP_ON_CENTER_CHANGE");
export const SET_MAP_ON_DRAG_END = Symbol.for("SET_MAP_ON_DRAG_END");
export const RESET_MAP = Symbol.for("RESET_MAP");

/**
 * Actions
 */

export function setMapAction(payload: google.maps.Map | null) {
  return {
    type: SET_MAP,
    payload,
  };
}
export function setMapCenterAction(
  payload: google.maps.LatLngLiteral | google.maps.LatLng | undefined
) {
  return {
    type: SET_MAP_CENTER,
    payload,
  };
}
export function setMapZoomAction(payload: number | undefined) {
  return {
    type: SET_MAP_ZOOM,
    payload,
  };
}
export function setMapPointsAction(payload: MapPoint[][]) {
  return {
    type: SET_MAP_POINTS,
    payload,
  };
}
export function setMapClusterPointsAction(payload: MapPoint[][]) {
  return {
    type: SET_MAP_CLUSTER_POINTS,
    payload,
  };
}
export function setMapLinesAction(payload: any[]) {
  return {
    type: SET_MAP_LINES,
    payload,
  };
}
export function setMapBoundsChangedAction(payload: Function) {
  return {
    type: SET_MAP_ON_BOUNDS_CHANGE,
    payload,
  };
}
export function setMapZoomChangedAction(payload: Function) {
  return {
    type: SET_MAP_ON_ZOOM_CHANGE,
    payload,
  };
}
export function setMapCenterChangedAction(payload: Function) {
  return {
    type: SET_MAP_ON_CENTER_CHANGE,
    payload,
  };
}
export function setMapOnDragEndAction(payload: Function) {
  return {
    type: SET_MAP_ON_DRAG_END,
    payload,
  };
}
export function resetMapAction() {
  return {
    type: RESET_MAP,
  };
}
