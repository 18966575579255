import * as basestationAction from "../actions/basestation.action";
import * as notificationAction from "../actions/notification.action";
import { catchError, map, switchMap, mergeMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import { BasestationEntry, EditBasestationPayload } from "../const/types";
import { ajax } from "rxjs/ajax";
import { BASESTATION_API } from "../const/api";
import { NOTIFICATION_SEVERITY_SUCCESS } from "../const/const";
import { NOTIFICATION_BOTTOM } from "../const/ui";
import { getToken } from "../reducers";
import { RootState } from "../config/store";

export const fetchAllBasestationsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(basestationAction.FETCH_ALL_BASESTATIONS),
    map((action) => action.payload),
    switchMap(() =>
      ajax
        .getJSON<BasestationEntry[]>(`${BASESTATION_API}/all`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(basestationAction.loadAllBasestationAction),
          catchError((err) =>
            of(basestationAction.fetchBasestationFailedAction(err))
          )
        )
    )
  );

export const editBasestationNameEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(basestationAction.EDIT_BASESTATION),
    map((action) => action.payload),
    mergeMap(({id, label, name}) =>
      ajax
        .put<EditBasestationPayload>(
          `${BASESTATION_API}/${id}`,
          { label, name },
          { Authorization: `Bearer ${getToken(state$.value)}` }
        )
        .pipe(
          mergeMap(({response}) => [
            notificationAction.loadNotificationAction({
              type: "EDIT BASESTATION",
              severity: NOTIFICATION_SEVERITY_SUCCESS,
              position: NOTIFICATION_BOTTOM,
              title: "Edit Basestation",
              content: `Edit name of ${response.name} successful`,
              dur: 5000,
              created: new Date().toISOString(),
            }),
            basestationAction.editBasestationSuccessAction(response)
          ]),
          catchError(err => of(basestationAction.editBasestationFailedAction(err)))
        ),
    )
  );

export const basestationEpics = [
  fetchAllBasestationsEpic,
  editBasestationNameEpic
];
