/**
 * Action types
 */

export const ENABLE_DARK_MODE = Symbol.for("ENABLE_DARK_MODE");

/**
 * Actions
 */
export function enableDarkModeAction(darkMode: boolean) {
  return {
    type: ENABLE_DARK_MODE,
    payload: darkMode,
  };
}
