/**
 * Action types
 */
import {
  SWManifestEntry,
  SWDeploymentEntry,
  ManyEntry,
  QueryString,
  SWReleaseEntry,
  InitiateSWUpdatePayload,
  SWDeploymentChangeEntry,
  McuEntry,
} from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_MANY_SW_RELEASE = Symbol.for("FETCH_MANY_SW_RELEASE");
export const LOAD_MANY_SW_RELEASE = Symbol.for("LOAD_MANY_SW_RELEASE");
export const FETCH_SW_MANIFEST = Symbol.for("FETCH_SW_MANIFEST");
export const FETCH_MANY_SW_MANIFEST = Symbol.for("FETCH_MANY_SW_MANIFEST");
export const FETCH_SW_UPDATE_FAILED = Symbol.for("FETCH_SW_UPDATE_FAILED");
export const LOAD_SW_MANIFEST = Symbol.for("LOAD_SW_MANIFEST");
export const LOAD_MANY_SW_MANIFESTS = Symbol.for("LOAD_MANY_SW_MANIFESTS");
export const FETCH_MANY_SW_DEPLOYMENTS_FOR_SELECTED_MCU = Symbol.for(
  "FETCH_MANY_SW_DEPLOYMENTS_FOR_SELECTED_MCU"
);
export const FETCH_LATEST_SW_DEPLOYMENTS = Symbol.for(
  "FETCH_LATEST_SW_DEPLOYMENTS"
);
export const FETCH_CURRENT_INSTALLED_SW = Symbol.for(
  "FETCH_CURRENT_INSTALLED_SW"
);
export const LOAD_MANY_SW_DEPLOYMENTS_FOR_SELECTED_MCU = Symbol.for(
  "LOAD_MANY_SW_DEPLOYMENTS_FOR_SELECTED_MCU"
);
export const LOAD_LATEST_SW_DEPLOYMENTS = Symbol.for(
  "LOAD_LATEST_SW_DEPLOYMENTS"
);
export const LOAD_CURRENT_INSTALLED_SW = Symbol.for(
  "LOAD_CURRENT_INSTALLED_SW"
);
export const SELECT_SW_UPDATE = Symbol.for("SELECT_SW_UPDATE");
export const OPEN_CREATE_SW_UPDATE_DIALOG = Symbol.for(
  "OPEN_CREATE_SW_UPDATE_DIALOG"
);
export const CREATE_SW_MANIFEST = Symbol.for("CREATE_SW_MANIFEST");
export const LOAD_CREATED_SW_MANIFEST = Symbol.for("LOAD_CREATED_SW_MANIFEST");
export const START_SW_UPDATE = Symbol.for("START_SW_UPDATE");
export const CANCEL_SW_UPDATE = Symbol.for("CANCEL_SW_UPDATE");
export const INITIATE_SW_UPDATE = Symbol.for("INITIATE_SW_UPDATE");
export const LOAD_SW_DEPLOYMENT_CHANGE = Symbol.for(
  "LOAD_SW_DEPLOYMENT_CHANGE"
);

/**
 * Actions
 */
export function fetchManySwReleasesAction(qs: QueryString) {
  return {
    type: FETCH_MANY_SW_RELEASE,
    payload: qs,
  };
}

export function loadManySwReleasesAction(entries: ManyEntry<SWReleaseEntry>) {
  return {
    type: LOAD_MANY_SW_RELEASE,
    payload: entries,
  };
}

export function fetchSwManifestAction(id: string) {
  return {
    type: FETCH_SW_MANIFEST,
    payload: id,
  };
}

export function loadSwManifestAction(entries: SWManifestEntry) {
  return {
    type: LOAD_SW_MANIFEST,
    payload: entries,
  };
}

export function fetchManySwManifestsAction(qs: QueryString) {
  return {
    type: FETCH_MANY_SW_MANIFEST,
    payload: qs,
  };
}

export function loadManySwManifestsAction(entries: ManyEntry<SWManifestEntry>) {
  return {
    type: LOAD_MANY_SW_MANIFESTS,
    payload: entries,
  };
}
export function fetchLatestSwDevelopmentsAction() {
  return {
    type: FETCH_LATEST_SW_DEPLOYMENTS,
  };
}

export function fetchCurrentInstalledSwAction() {
  return {
    type: FETCH_CURRENT_INSTALLED_SW,
  };
}

export function loadLatestSwDevelopmentsAction(
  deployments: ManyEntry<SWDeploymentEntry>
) {
  return {
    type: LOAD_LATEST_SW_DEPLOYMENTS,
    payload: deployments,
  };
}

export function loadCurrentInstalledSwAction(mcus: McuEntry[]) {
  return {
    type: LOAD_CURRENT_INSTALLED_SW,
    payload: mcus,
  };
}

export function fetchSwUpdateFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_SW_UPDATE_FAILED,
    payload: err.toString(),
  };
}

export function fetchManySwDeploymentsForMcuAction(
  id: string,
  qs: QueryString
) {
  return {
    type: FETCH_MANY_SW_DEPLOYMENTS_FOR_SELECTED_MCU,
    payload: { id, qs },
  };
}

export function loadManySwDeploymentsForSelectedMcuAction(
  vehicles: ManyEntry<SWDeploymentEntry>
) {
  return {
    type: LOAD_MANY_SW_DEPLOYMENTS_FOR_SELECTED_MCU,
    payload: vehicles,
  };
}

export function initiateSwUpdateAction(payload: InitiateSWUpdatePayload) {
  return {
    type: INITIATE_SW_UPDATE,
    payload,
  };
}

export function loadSwDeploymentChangeAction(payload: SWDeploymentChangeEntry) {
  return {
    type: LOAD_SW_DEPLOYMENT_CHANGE,
    payload,
  };
}

export function selectSwManifestAction(manifest: SWManifestEntry | null) {
  return {
    type: SELECT_SW_UPDATE,
    payload: manifest,
  };
}

export function createSwManifestAction(payload: any) {
  return {
    type: CREATE_SW_MANIFEST,
    payload,
  };
}

export function loadCreatedSwManifestAction(manifest: SWManifestEntry | null) {
  return {
    type: LOAD_CREATED_SW_MANIFEST,
    payload: manifest,
  };
}

export function openCreatedSwManifestDialogAction(open: boolean) {
  return {
    type: OPEN_CREATE_SW_UPDATE_DIALOG,
    payload: open,
  };
}

export function startOrCancelSwUpdateAction(id: string, start: boolean) {
  return {
    type: START_SW_UPDATE,
    payload: { id, start },
  };
}
