/**
 * Action types
 */
import { ManyEntry, CabCameraEntry, CabCameraQs } from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_MANY_CAB_CAMS = Symbol.for("FETCH_MANY_CAB_CAMS");
export const FETCH_SINGLE_CAB_CAM = Symbol.for("FETCH_SINGLE_CAB_CAM");
export const FETCH_CAB_CAMS_FAILED = Symbol.for("FETCH_CAB_CAMS_FAILED");
export const LOAD_MANY_CAB_CAMS = Symbol.for("LOAD_MANY_CAB_CAMS");

/**
 * Actions
 */
export function fetchManyCabCameraAction(qs: CabCameraQs) {
  return {
    type: FETCH_MANY_CAB_CAMS,
    payload: qs,
  };
}

export function fetchCabCameraFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_CAB_CAMS_FAILED,
    payload: err.toString(),
  };
}

export function loadManyCabCameraAction(payload: ManyEntry<CabCameraEntry>) {
  return {
    type: LOAD_MANY_CAB_CAMS,
    payload,
  };
}
