import * as vehicleAction from "../actions/vehicle.action";
import {
  catchError,
  debounce,
  first,
  map,
  mergeMap,
  switchMap,
} from "rxjs/operators";
import { Observable, of, timer } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import {
  ManyEntry,
  VehicleEntry,
  VehicleQs,
  VehicleServiceTypeEntry,
  VehicleServiceTypes,
} from "../const/types";
import { ajax } from "rxjs/ajax";
import { VEHICLE_API } from "../const/api";
import { getToken } from "../reducers";
import { joinQueryStr } from "../helper/util";
import { RootState } from "../config/store";
import {
  DEFAULT_SEARCH_DEBOUNCE_TIME,
  NOTIFICATION_SEVERITY_SUCCESS,
} from "../const/const";
import * as notificationAction from "../actions/notification.action";
import { NOTIFICATION_BOTTOM } from "../const/ui";

export const fetchVehicleTypesEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(vehicleAction.FETCH_VEHICLE_TYPES),
    first(),
    switchMap(() =>
      ajax
        .getJSON<VehicleServiceTypes>(VEHICLE_API + "/types", {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(vehicleAction.loadVehicleTypesAction),
          catchError((err) => of(vehicleAction.vehicleFetchFailedAction(err)))
        )
    )
  );

export const fetchManyVehiclesEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(vehicleAction.FETCH_MANY_VEHICLES),
    map((action) => action.payload),
    debounce((qs: VehicleQs) =>
      timer(qs.query.length > 2 ? DEFAULT_SEARCH_DEBOUNCE_TIME : 0)
    ),
    switchMap((qs: VehicleQs) =>
      ajax
        .getJSON<ManyEntry<VehicleEntry>>(
          `${VEHICLE_API}?${joinQueryStr(qs)}`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(vehicleAction.loadManyVehicleAction),
          catchError((err) => of(vehicleAction.vehicleFetchFailedAction(err)))
        )
    )
  );

export const createVehicleTypeEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(vehicleAction.CREATE_VEHICLE_TYPE),
    map((action) => action.payload),
    switchMap((label: string) =>
      ajax
        .post<VehicleServiceTypeEntry>(
          `${VEHICLE_API}/vehicle_type`,
          { label },
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map((res) => res.response),
          mergeMap((entry) =>
            of(
              vehicleAction.loadVehicleTypeAction(entry),
              notificationAction.loadNotificationAction({
                type: `CREATE_VEHICLE_TYPE`,
                severity: NOTIFICATION_SEVERITY_SUCCESS,
                position: NOTIFICATION_BOTTOM,
                title: "Create Equipment Type",
                content: `${entry.label} was created`,
                dur: 5000,
                created: new Date().toISOString(),
              })
            )
          ),
          catchError((err) => of(vehicleAction.vehicleFetchFailedAction(err)))
        )
    )
  );

export const editVehicleEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(vehicleAction.EDIT_VEHICLE),
    map((action) => action.payload),
    switchMap(({ id, payload }) =>
      ajax
        .put<VehicleEntry>(`${VEHICLE_API}/${id}`, payload, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map((res) => res.response),
          mergeMap((entry) =>
            of(
              vehicleAction.loadVehicleAction(id, entry),
              notificationAction.loadNotificationAction({
                type: `EDIT_VEHICLE_TYPE`,
                severity: NOTIFICATION_SEVERITY_SUCCESS,
                position: NOTIFICATION_BOTTOM,
                title: "Edit Equipment",
                content: `${entry.id} was modified`,
                dur: 5000,
                created: new Date().toISOString(),
              })
            )
          ),
          catchError((err) => of(vehicleAction.vehicleFetchFailedAction(err)))
        )
    )
  );

export const vehicleEpics = [
  fetchVehicleTypesEpic,
  fetchManyVehiclesEpic,
  createVehicleTypeEpic,
  editVehicleEpic,
];
