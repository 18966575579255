import * as trackAction from "../actions/track.action";
import { AnyAction } from "redux";
import {
  AssetEntry,
  NationalRouteEntry,
  NationalRouteObject,
  TrackEntry,
} from "../const/types";
import { polygon } from "@turf/helpers";
import { Feature, Polygon } from "@turf/helpers/dist/js/lib/geojson";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  tracks: TrackEntry[];
  assets: AssetEntry[];
  routes: NationalRouteObject;
  boundaries: Feature<Polygon, { id: number }>[];
  loading: boolean;
}

const initialState: State = {
  tracks: [],
  assets: [],
  routes: {},
  boundaries: [],
  loading: false,
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case trackAction.LOAD_TRACKS: {
      const tracks = action.payload as TrackEntry[];
      return { ...state, tracks, loading: false };
    }

    case trackAction.LOAD_ASSETS: {
      const assets = action.payload as AssetEntry[];
      return { ...state, assets, loading: false };
    }

    case trackAction.LOAD_NATIONAL_ROUTE: {
      const payload = action.payload as NationalRouteEntry[];
      const routes = payload.reduce((acc, r) => {
        const path = r.geometry.coordinates.map(([lng, lat]) => ({ lat, lng }));
        return { ...acc, [r.id]: { name: r.name, path } };
      }, state.routes);
      return { ...state, routes, loading: false };
    }

    case trackAction.LOAD_NATIONAL_ROUTE_BOUNDARIES: {
      const payload = action.payload as NationalRouteEntry[];
      const boundaries = payload.map(({ id, box }) =>
        polygon(box.coordinates, { id })
      );
      return { ...state, boundaries, loading: false };
    }

    case trackAction.FETCH_TRACKS_FAILED:
    case FORBIDDEN_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getTracks = (state: State) => state.tracks;
export const getAssets = (state: State) => state.assets;
export const getNationalRoute = (state: State) => state.routes;
export const getNationalRouteBoundaries = (state: State) => state.boundaries;
