import { Typography } from "@mui/material";
import React from "react";

export default function TitleComponent({ title, align = "center" }: any) {
  return (
    <Typography align={align} variant={"h4"} marginBottom={1}>
      {title}
    </Typography>
  );
}
