import * as videoAction from "../actions/video.action";
import {
  catchError,
  debounce,
  map,
  mergeMap,
  switchMap,
} from "rxjs/operators";
import { Observable, of, timer } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import {
  ManyEntry,
  SignedURLType,
  VideoEntry,
  VideoQs,
  VideoRequestPayload,
} from "../const/types";
import { ajax } from "rxjs/ajax";
import { ALERT_API, VIDEO_API } from "../const/api";
import { getToken } from "../reducers";
import { joinQueryStr } from "../helper/util";
import { RootState } from "../config/store";
import {
  DEFAULT_SEARCH_DEBOUNCE_TIME,
  NOTIFICATION_SEVERITY_SUCCESS,
} from "../const/const";
import * as notificationAction from "../actions/notification.action";
import { NOTIFICATION_BOTTOM } from "../const/ui";
import { getSpecificVideo } from "../reducers/video.reducer";

export const fetchManyVideosEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(videoAction.FETCH_MANY_VIDEOS),
    map((action) => action.payload),
    debounce((qs: VideoQs) =>
      timer(qs.filter_value.length ? DEFAULT_SEARCH_DEBOUNCE_TIME : 0)
    ),
    switchMap((qs: VideoQs) =>
      ajax
        .getJSON<ManyEntry<VideoEntry>>(`${VIDEO_API}?${joinQueryStr(qs)}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(videoAction.loadManyVideoAction),
          catchError((err) => of(videoAction.videoFetchFailedAction(err)))
        )
    )
  );

export const createVideoRequestEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(videoAction.CREATE_VIDEO_REQUEST),
    map((action) => action.payload),
    switchMap((payload: VideoRequestPayload) =>
      ajax
        .post<VideoEntry>(`${VIDEO_API}/video_request`, payload, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map((r) => r.response),
          switchMap((vr) =>
            of(
              videoAction.loadSingleVideoAction(vr),
              notificationAction.loadNotificationAction({
                type: `CREATE_VIDEO_REQUEST`,
                severity: NOTIFICATION_SEVERITY_SUCCESS,
                position: NOTIFICATION_BOTTOM,
                title: "create video request",
                content: `Video request ${vr.id} was created`,
                dur: 10000,
                created: new Date().toISOString(),
              })
            )
          ),
          catchError((err) => of(videoAction.videoFetchFailedAction(err)))
        )
    )
  );

export const processVideoRequestActionEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(videoAction.PROCESS_VIDEO_REQUEST),
    map((action) => action.payload),
    switchMap((id: string) =>
      ajax
        .getJSON<VideoEntry>(`${VIDEO_API}/process_event_video/${id}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(videoAction.loadSingleVideoAction),
          catchError((err) => of(videoAction.videoFetchFailedAction(err)))
        )
    )
  );

export const cancelVideoRequestActionEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(videoAction.CANCEL_VIDEO_REQUEST),
    map((action) => action.payload),
    switchMap((id: string) =>
      ajax
        .put<VideoEntry>(
          `${VIDEO_API}/update_video_process_status/${id}`,
          { status: "CANCELED" },
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(({ response }) => videoAction.loadSingleVideoAction(response)),
          catchError((err) => of(videoAction.videoFetchFailedAction(err)))
        )
    )
  );

export const fetchSingleVideoActionEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(videoAction.FETCH_SINGLE_VIDEO),
    map((action) => action.payload),
    mergeMap((id: string) =>
      ajax
        .getJSON<VideoEntry>(`${VIDEO_API}/${id}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          //filter((v) => !!v),
          map(videoAction.loadSingleVideoAction),
          catchError((err) => of(videoAction.videoFetchFailedAction(err)))
        )
    )
  );

export const fetchSignedUrlForVideoDownloadEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(videoAction.FETCH_SIGNED_URL_FOR_VIDEO_DOWNLOAD),
    map((action) => action.payload),
    switchMap(
      ({
        id,
        type,
        fromAlert,
      }: {
        id: string;
        type: SignedURLType;
        fromAlert?: boolean;
      }) => {
        if (type === "video") {
          const video = getSpecificVideo(state$.value!.video, id);
          if (!video && !fromAlert) return of();
          if (video?.files || fromAlert) {
            return ajax
              .getJSON<{ url?: string; urls?: string[] }>(
                `${VIDEO_API}/get_processed_video_signed_urls/${id}?expires_in_seconds=3600`,
                {
                  Authorization: `Bearer ${getToken(state$.value)}`,
                }
              )
              .pipe(
                map((payload) => {
                  const { urls, url } = payload;
                  return videoAction.loadSignedUrlForDownloadAction(
                    id,
                    type,
                    urls,
                    url
                  );
                }),
                catchError((err) =>
                  of(videoAction.downloadFetchFailedAction(err))
                )
              );
          }
        }
        return ajax
          .getJSON<{ url?: string; urls?: string[] }>(
            `${type === "video" ? VIDEO_API : ALERT_API}/get_signed_url/${id}`,
            {
              Authorization: `Bearer ${getToken(state$.value)}`,
            }
          )
          .pipe(
            map(({ url, urls }) =>
              videoAction.loadSignedUrlForDownloadAction(id, type, urls, url)
            ),
            catchError((err) => of(videoAction.downloadFetchFailedAction(err)))
          );
      }
    )
  );

export const videoEpics = [
  fetchManyVideosEpic,
  fetchSingleVideoActionEpic,
  createVideoRequestEpic,
  fetchSignedUrlForVideoDownloadEpic,
  processVideoRequestActionEpic,
  cancelVideoRequestActionEpic,
];
