/**
 * Action types
 */

export const SET_SEARCH_VAL = Symbol.for("SET_SEARCH_VAL");
export const SET_INPUT_VAL = Symbol.for("SET_INPUT_VAL");
export const SET_SUGGESTIONS = Symbol.for("SET_SUGGESTIONS");
export const RESET_SEARCH_BAR = Symbol.for("RESET_SEARCH_BAR");
export const SET_SEARCH_PREFIX = Symbol.for("SET_SEARCH_PREFIX");

/**
 * Actions
 */

export function setSearchValAction(payload: string | null) {
  return {
    type: SET_SEARCH_VAL,
    payload,
  };
}

export function setInputValAction(payload: string | null) {
  return {
    type: SET_INPUT_VAL,
    payload,
  };
}

export function setSuggestionsAction(payload: string[] | undefined) {
  return {
    type: SET_SUGGESTIONS,
    payload,
  };
}

export function resetSearchBarAction() {
  return {
    type: RESET_SEARCH_BAR,
  };
}

export function setSearchPrefixAction(payload: string | undefined) {
  return {
    type: SET_SEARCH_PREFIX,
    payload,
  };
}
