import React, {
  useEffect,
  useRef,
  ComponentType,
  memo,
  useCallback,
  useState,
} from "react";
import Box from "@mui/material/Box";
import SideBar from "./SideBar";
import { Outlet } from "react-router-dom";
import { useStream } from "../../hooks/useStream";
import { STREAM_API } from "../../const/api";
import useObservable from "../../hooks/useObservable";
import { connect, ConnectedProps } from "react-redux";
import { getAuthState } from "../../reducers";
import { compose } from "redux";
import { RootState } from "../../config/store";
import { AlertEntry, MessageEntry, Role } from "../../const/types";
import { newAlertAction } from "../../actions/alert.action";
import {
  checkVersionAction,
  unauthorizedErrorAction,
} from "../../actions/auth.action";
import { loadMessageAction } from "../../actions/message.action";
import { useWindowDimensions } from "../../hooks/UseWindowDimensions";
import { LOCAL, ONE_HOUR } from "../../const/const";
import RefreshPageModal from "../common/RefreshPageModal";
import { isValidVersion } from "../../helper/util";

const Navigation: React.FC<Props> = ({
  me,
  token,
  newAlert,
  loadMessage,
  unauthorizedError,
  checkVersion,
  allowedVersions,
}) => {
  const { height, width } = useWindowDimensions();
  const streamDataHandler = useCallback(
    (data: AlertEntry | MessageEntry<any>) => {
      if (data.hasOwnProperty("alert_type_id")) {
        // Alert
        newAlert(data as AlertEntry);
      } else if (data.hasOwnProperty("payload")) {
        // Message
        loadMessage(data as MessageEntry<any>);
      }
    },
    [newAlert, loadMessage]
  );
  const data$ = useStream(
    `${STREAM_API}/alert`,
    me.Role?.label !== Role.ASSEMBLER && me.provider === LOCAL
      ? me.id && token
      : null
  );
  useObservable<AlertEntry | MessageEntry<any>>(
    data$,
    streamDataHandler,
    unauthorizedError
  );

  const sideBarRef = useRef<HTMLDivElement>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const timerRef = useRef<any>();
  useEffect(() => {
    checkVersion();
    timerRef.current = setInterval(() => {
      checkVersion();
    }, ONE_HOUR);
    return () => clearInterval(timerRef.current);
  }, [checkVersion]);

  useEffect(() => {
    if (
      allowedVersions &&
      typeof process.env.REACT_APP_VERSION === "string" &&
      process.env.REACT_APP_VERSION.match(/^(\d+\.)(\d+\.)(\d+)$/g) &&
      !isValidVersion(process.env.REACT_APP_VERSION ?? "0.0.0", allowedVersions)
    ) {
      setModalOpen(true);
    }
  }, [allowedVersions]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box ref={sideBarRef}>
        <SideBar open={true} setOpen={() => undefined} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: height,
          width: sideBarRef.current
            ? width - sideBarRef.current.offsetWidth
            : width,
        }}
      >
        {!!token && <Outlet />}
        <RefreshPageModal open={modalOpen} />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: RootState) => ({ ...getAuthState(state) });
const mapDispatchToProps = {
  newAlert: newAlertAction,
  loadMessage: loadMessageAction,
  unauthorizedError: unauthorizedErrorAction,
  checkVersion: checkVersionAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default compose<ComponentType>(connector, memo)(Navigation);
type Props = ConnectedProps<typeof connector>;
