import * as userAction from "../actions/user.action";
import { catchError, first, map, switchMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import {
  ManyEntry,
  RoleEntry,
  UserEntry,
  UserQsModified,
} from "../const/types";
import { ajax } from "rxjs/ajax";
import { USER_API } from "../const/api";
import { getToken } from "../reducers";
import { joinQueryStr } from "../helper/util";
import { RootState } from "../config/store";

export const fetchManyUsersEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(userAction.FETCH_MANY_USERS),
    map((action) => action.payload),
    switchMap((qs: UserQsModified) =>
      ajax
        .getJSON<ManyEntry<UserEntry>>(`${USER_API}?${joinQueryStr(qs)}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(userAction.loadManyUserAction),
          catchError((err) => of(userAction.fetchUserFailedAction(err)))
        )
    )
  );

export const fetchRolesEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(userAction.FETCH_ROLES),
    first(),
    switchMap(() =>
      ajax
        .getJSON<RoleEntry[]>(`${USER_API}/roles`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(userAction.loadRolesAction),
          catchError((err) => of(userAction.fetchUserFailedAction(err)))
        )
    )
  );

export const userEpics = [fetchManyUsersEpic, fetchRolesEpic];
