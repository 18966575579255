import { SortDirection } from "@mui/material/TableCell/TableCell";
import { AlertColor } from "@mui/material/Alert/Alert";
import { Position } from "@turf/helpers/dist/js/lib/geojson";
import {
  ACTIVE,
  INACTIVE,
  INSTALLED,
  ONLINE,
  OFFLINE,
  MAINTENANCE,
} from "./const";
import { Dayjs } from "dayjs";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }
}

export interface ManyEntry<T> {
  rows: T[];
  count: number;
}

export interface QueryString {
  limit: number;
  order: string;
  dir: SortDirection;
  offset: number;
  raw?: BooleanInternal;
}

export interface LimitOffset {
  limit: number;
  offset: number;
}

export interface SearchQueryString {
  search_by: string;
  search_value: string;
  only_ids: boolean;
}

export interface QsAction {
  type: string;
  payload: QsPayload;
}

type QsPayload = {
  [key: string]: any;
};

export type Align = "inherit" | "left" | "center" | "right" | "justify";

export interface TableHeadRow {
  prop: string;
  align: Align;
  label: string;
  sort: boolean;
  parse?: Function;
  icon?: boolean;
}

export interface LccasTableRowType {
  onClick?: Function;
  cells: LccasTableCell[];
  collapseEle?: JSX.Element;
  expanded?: boolean;
  refObject?: any;
  selected?: boolean;
}

export interface FleetTableRowType {
  onClick?: Function;
  cells: LccasTableCell[];
  selected?: boolean;
  mcuId: string;
}

export interface LccasTableCell {
  label: string | JSX.Element | boolean | number;
  align?: Align;
  onClick?: Function;
}

export interface TableRowFilter {
  align: Align;
  index: number;
}

export type BooleanInternal = "YES" | "NO";

// MCU

export type McuEntry = {
  id: string;
  installed: boolean;
  swVersion: string;
  hwVersion: string;
  packetVersion: string;
  gcs: any;
  createdAt: string;
  updatedAt: string;
  speed: number;
  uwb: any;
  lidar: any;
  health: any;
  formDInfo: any;
  Vpus: any;
  Vods: any;
  Vehicle: any;
  "Vehicle.id": any;
  gpsState: string;
  sn: string;
  mode: string;
  needToReInit: boolean;
  lastConfigSentAt: string;
  city: string;
  region: string;
  address: string;
  online: boolean;
  menderId: string;
  maintenance: boolean;
  deployment: null;
  Vpu1menderId: string;
  Vpu2menderId: string;
  audibleAlerts: boolean;
  onlineAlert: boolean;
  CabCameras: any;
  OnlineAlerts: OnlineAlert[];
  modem: {
    imei: string;
    iccid1: string;
    iccid2: string;
    McuId: string;
    createdAt: string;
    updatedAt: string;
  };
  equipType?: string;
};

export interface OnlineAlert {
  id: string;
  McuId: string;
  note: string;
  createdBy: string;
}

export interface GCS {
  coordinates: number[];
}

export interface SelectedMCU {
  id: string;
  entry: McuEntry | null;
}

export interface SelectedBasestation {
  id: string;
  entry: BasestationEntry | null;
}

export interface SshTunnelPayload {
  id: string;
  dur: number;
}

interface SSID {
  ssid: string;
  psk: string;
}

export interface WlanSetupPayload {
  mcu_id: string;
  ssids: SSID[];
}

export interface EnableLidarPayload {
  mcu_id: string;
  vpu_id: string;
  enable: boolean;
}

export interface EnableSensorPayload {
  mcu_id: string;
  vpu_id: string;
  sensor_type: string;
  enable: boolean;
}

export interface ReplaceSensorPayload {
  mcu_id: string;
  vpu_id: string;
  sensor_type: string;
  replace: boolean;
}

export interface SetOnlineNotificationPayload {
  id: string;
  online_alert_id?: string;
  enable: boolean;
  note: string;
}

export interface SetAudibleAlertPayload {
  id: string;
  audible_alerts: boolean;
}

export type EnableLidarAckPayload = EnableLidarPayload;
export type EnableSensorAckPayload = EnableSensorPayload;
export type ReplaceSensorAckPayload = ReplaceSensorPayload;

export interface LidarId {
  mcu_id: string;
  vpu_id: string;
}

export interface DeviceNotRespondingPayload {
  mcu_id: string;
  command: string;
}

export interface EnableLidarNotRespondingPayload
  extends DeviceNotRespondingPayload {
  vpu_id: string;
}

// VEHICLE
export interface VehicleQs extends QueryString {
  installed: BooleanInternal;
  with_mcu_sn: BooleanInternal;
  query: String;
  raw: BooleanInternal;
}

export interface VehicleServiceTypes {
  vehicleTypes: VehicleServiceTypeEntry[];
  serviceTypes: VehicleServiceTypeEntry[];
}

export interface VehicleServiceTypeEntry {
  id: string;
  label: string;
}

export interface VehicleServiceTypeObject {
  [id: number]: string;
}

export interface VehicleEntry {
  id: string;
  VehicleTypeId: number;
  ServiceTypeId: number;
  McuId: string;
  createdAt: string;
  updatedAt: string;
  isRubberTire: boolean;
  Mcu: McuEntry;
  "Mcu.sn": string;
  "Mcu.id": string;
  edit: null;
}

export interface EditVehiclePayload {
  new_id?: string;
  vehicle_type_id?: number;
  is_rubber_tire?: boolean;
}

export interface InstallationQs extends QueryString {
  status: string;
  mcu_sn: string;
}

export interface InstallationEntry {
  id: string;
  Mcu: any;
  McuId: string;
  VehicleId: string;
  vpuIds: string[];
  vodIds: string[];
  cabCameraIds: string[];
  status: string;
  configVerified: boolean;
  unlockKeyReceived: boolean;
  lidarCalibration: boolean;
  telemetryVerified: boolean;
  vehicleAssigned: boolean;
  uwbVerified: boolean;
  createdAt: string;
  updatedAt: string;

  vpus: VpuEntry[];
  vods: VodEntry[];
  cabCameras: CabCameraEntry[];
}

// user
export enum Role {
  USER = "USER",
  FOREMAN = "FOREMAN",
  OPERATOR = "OPERATOR",
  ADMIN = "ADMIN",
  BUILDER = "BUILDER",
  BASESTATION = "BASESTATION",
  BS_MONITOR = "BS_MONITOR",
  PICOAPP = "PICOAPP",
  ASSEMBLER = "ASSEMBLER",
  AMTRAK = "AMTRAK",
  SUPERUSER = "SUPERUSER",
  SUPER_FOREMAN = "SUPER_FOREMAN",
}

export type Roles = {
  [key in Role | "ALL"]?: number;
};

export type RoleIds = {
  [key: string]: Role;
};

export interface RoleEntry {
  id: string;
  label: Role;
}

export interface UserQs extends QueryString {
  role: Role | "ALL";
  provider: string;
}

export interface UserQsModified extends QueryString {
  role: number;
}

export interface UserEntry {
  id: string;
  RoleId: number;
  Role?: RoleEntry;
  email?: string;
  name: string;
  provider: string;
  createdAt: string;
  updatedAt: string;
}

// mcu
export interface FleetQs extends QueryString {
  status?: string;
  intvl?: number;
}

export type McuStatus = "active" | "inactive" | "uninstalled" | "total";

export interface FleetSearchQs extends QueryString {
  region?: string;
  status?: McuStatus;
  search_by: string;
  search_value: string;
}

export type McuStatusObj = {
  [k in McuStatus]: number;
};

// VPU
export interface VpuQs extends QueryString {
  search_by: string;
  search_value: string;
}

export interface VpuEntry {
  sn: string;
  id: string;
  swVersion: string;
  hwVersion: string;
  McuId: string;
  Mcu: any;
  createdAt: string;
  updatedAt: string;
  location: string;
  menderId: string;
  VpuConfig: VpuConfig;
  lidar: number;
  uwb: boolean | number;
  gps: boolean | number;
  imu: boolean | number;
  camera: boolean | number;
  health: {
    ssd: {
      used: number;
      status: number;
      fs_options: string;
    };
    ssd0?: {
      used: number;
      status: number;
      fs_options: string;
    };
    ssd1?: {
      used: number;
      status: number;
      fs_options: string;
    };
    idle: number;
    temp: number;
    backup: {
      used: number;
      status: number;
      fs_options: string;
    };
    core_v: number;
    freemem: number;
    throttled: string;
    gps_restarts: number;
    imu_restarts: number;
    uwb_restarts: number;
    lidar_restarts: number;
    camera_restarts: number;
  };
}

export interface VpuConfig {
  VpuId: string;
  updatedAt: string;
  createdAt: string;
  lidarBumperOffset?: number;
  lidarCenterOffset?: number;
  lidarDisabled: boolean;
  lidarHeight?: number;
  lidarPitch?: number;
  lidarRoll?: number;
  lidarYaw?: number;
  vpuBumperOffset?: number;
  vpuBumperOffsetTimestamp: number;
  vpuCenterOffset?: number;
  vpuHeight?: number;
  tx_power: number;
  imu_trigger: number;
  imu_delta: number;
  uwb_c_mm?: number;
  uwb_bu_mm?: number;
  uwb_h_mm?: number;
  lidar_c_mm?: number;
  lidar_bu_mm?: number;
  lidar_h_mm?: number;
  lidar_roll?: number;
  lidar_pitch?: number;
  lidar_yaw?: number;
  gps_location?: number;
  gps_c_mm?: number;
  gps_f_mm?: number;
  gps_b_mm?: number;
  gps_h_mm?: number;
}

// VOD
export type VodEntry = {
  id: string;
  sn: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  McuId: string;
  "Mcu.id": string;
  "Mcu.sn": string;
  "Mcu.swVersion": string;
};

export interface VodQs extends QueryString {
  with_mcu: BooleanInternal;
  search_by: string;
  search_value: string;
  raw: BooleanInternal;
}

// CAB CAMERA
export type CabCameraEntry = {
  id: string;
  mac: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  configUpdatedAt: string;
  value: number;
  blocked: boolean;
  McuId: string;
  restarts: number;
  "Mcu.id": string;
  "Mcu.sn": string;
  "Mcu.swVersion": string;
};

export interface CabCameraQs extends QueryString {
  with_mcu: BooleanInternal;
  search_by: string;
  search_value: string;
  raw: BooleanInternal;
}

// export interface VpuComponent {
//   lidar: number;
//   uwb: boolean;
//   gps: boolean;
//   imu: boolean;
//   camera: boolean;
// }

export enum VpuLocation {
  FRONT = "FRONT",
  BACK = "BACK",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  MOBILE = "MOBILE",
}

export type NotificationPosition = {
  top?: number | string;
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
  transform?: string;
};

// Notification
export interface NotificationEntry {
  type: string;
  severity: AlertColor;
  title: string;
  content: string;
  dur: number;
  created: string;
  position: NotificationPosition;
}

// Alert
export interface AlertQs extends QueryString {
  type: string | number | null;
  date_from: Dayjs;
  date_to: Dayjs;
  filter_by: "mcu_id" | "mcu_sn" | "vpu_id" | "vpu_sn" | "vehicle_id";
  filter_value?: string;
  mcu_id?: string | null;
}

export interface AlertEntry {
  id: string;
  AlertTypeId: number;
  access?: string;
  McuId?: string | null;
  VehicleId?: string | null;
  timestamp: number;
  VpuId?: string | null;
  status?: string | null;
  sensor?: string | null;
  mode?: string | null;
  FormDId?: string | null; // UUID?
  spd?: number | null;
  maxSpd?: number | null;
  gcs?: GCS | null;
  n?: number | null;
  distance?: number | null;
  location?: VpuLocation | null;
  identifier?: string | null;
  track?: string | null;
  milepost?: number | null;
  updatedAt: string;
  createdAt: string;
  vpuTime?: number | null;
  message: string;
  color: string;
  videoUploaded?: boolean;
  videoStatus?:
    | null
    | "UPLOADED"
    | "PROCESS_REQUESTED"
    | "PROCESSING"
    | "FAILED"
    | "SUCCESS";
  telemetryUploadedAt?: string;
}

export interface RawAlertEntry {
  id: string;
  access?: string;
  timestamp: number;
  status: string | null;
  sensor: string | null;
  mode: string | null;
  spd: number | null;
  max_spd: number | null;
  gcs: GCS;
  alt: number | null;
  n: number | null;
  distance: number | null;
  location: string | null;
  identifier: string | null;
  track: string | null;
  milepost: number | null;
  created_at: string;
  updated_at: string;
  vpu_time: number | null;
  mcu_id: string | null;
  vehicle_id: string | null;
  vpu_id: string | null;
  form_d_id: string | null;
  alert_type_id: number;
}

export interface AlertTypeEntry {
  id: string;
  label: string;
  template?: string | null;
  color?: string | null;
}

export interface AlertTypeObject {
  [id: string]: AlertTypeEntry;
}

// MESSAGE
export interface MessageEntry<T> {
  id: string;
  payload: T;
  to: string;
  sent: boolean;
  updatedAt: string;
  createdAt: string;
  created_at?: string;
}

export interface SSHTunnelMessagePayload {
  type: string;
  mcu_id: string;
  req_id: string;
  dur: number;
  host: string;
  user: string;
  remote_port: number;
  port: number;
}

export interface WlanSetupResultMessagePayload {
  type: string;
  mcu_id: string;
  req_id: string;
  success: boolean;
  reason: string;
}

export interface ChangeOpModeMessage {
  mcu_id: string;
  mode: string;
  sent?: boolean | null;
}

export interface SSHTunnelRequestState {
  dur: number;
  status: "PENDING" | "SUCCESS" | "FAILURE";
  remote_port: number;
}

export interface WlanSetupRequestState {
  status: "PENDING" | "SUCCESS" | "FAILURE";
  reason: string;
}

export interface EnableLidarRequestState {
  [vpuId: string]: {
    status: "PENDING" | "SUCCESS" | "FAILURE";
    enabled: boolean;
  };
}

export interface ReplaceSensorRequestState {
  [vpuId: string]: {
    status: "PENDING" | "SUCCESS" | "FAILURE";
    replace: boolean;
  };
}

// base station

export enum BasestationStatus {
  PPP_START = "PPP_START",
  PPP_END = "PPP_END",
  LTE_RECOVERED = "LTE_RECOVERED",
  BS_TEST_COMPLETED = "TEST_COMPLETED",
  BS_GPS_MODULE_FAILURE = "GPS_MODULE_FAILURE",
  BS_GPS_MODULE_SUCCESS = "GPS_MODULE_SUCCESS",
  BS_CLIENT_MODE_START = "CLIENT_MODE_START",
  BS_FORWARDING_PUBLIC_NETWORK = "FORWARDING_PUBLIC_NETWORK",
  NULL = "",
}

export interface BasestationEntry {
  id: string;
  name: string;
  label: string;
  installed: boolean;
  status: BasestationStatus;
  statusMessage: any;
  gcs: GCS;
  alt: number;
  position: google.maps.LatLngLiteral;
  createdAt: string;
  updatedAt: string;
  statusUpdatedAt: string;
  rtcmNum: number;
  rtcmFrom: number;
  rtcmTo: number;
  avgMsgPerSec: number;
  heartbeat: {
    idle: number | string;
    rsrp: string;
    rsrq: string;
    temp: string;
    core_v: string;
    uptime: string;
    freemem: string;
    throttled: string;
    signal_quality: string;
  };
  sn: string;
}

export interface EditBasestationPayload {
  id: string;
  name: string;
  label: string;
}

// track

export interface TrackQs {
  latlon: string;
  radius: number;
}

export interface Boundary {
  sw: string;
  ne: string;
}

export interface LineString {
  coordinates: number[][];
}

export interface Line {
  lla: number[];
  mp: {
    milepost: number;
    offset: number;
  };
  offset: number;
}

export interface Polygon {
  coordinates: Position[][];
}

export interface TrackEntry {
  geo: LineString;
  line: Line[];
  EdgeId: number;
  Edge: {
    name: string;
  };
  idx: number;
}

export interface AssetEntry {
  id: number;
  type: string;
  name: string;
  lineCode: string;
  point: any;
}

export interface NationalRouteEntry {
  id: number;
  box: Polygon;
  geometry: LineString;
  name: string;
}

export interface NationalRouteEntryForView {
  path: google.maps.LatLngLiteral[];
  name: string;
}

export interface NationalRouteObject {
  [id: string]: NationalRouteEntryForView;
}

export interface MapCenterZoom {
  center: google.maps.LatLngLiteral;
  zoom: number;
  sw?: google.maps.LatLngLiteral;
  ne?: google.maps.LatLngLiteral;
}

export interface Yard {
  name: string;
  center: google.maps.LatLngLiteral;
}

export interface Yards {
  [name: string]: Yard;
}

export interface Pins {
  small: string;
  large: string;
}

// fat
export interface Test {
  id: string;
  name: string;
  description: string;
  created_at: string;
}

export interface Tests {
  rows: Test[];
  count: number;
}

export interface TestResult {
  id: string;
  test_id: string;
  test_target_id: string;
  host: string;
  tester: string;
  start: string;
  end: string;
  output: string;
  data: string;
  passed: boolean;
  harness_version: string;
  created_at: string;
}

export interface TestResults {
  rows: TestResult[];
  count: number;
}

export interface TestResultQs extends QueryString {
  test_target_id?: string;
  test_id?: string;
  passed?: boolean;
  date_from?: string;
  date_to?: string;
  test_sw_version?: string;
}

export interface ComponentSearchResult {
  ids: string[];
}

// sw update
export interface SWReleaseEntry {
  id: string;
  name: string;
  latest: boolean;
  createdAt: string;
}
export interface SWManifestEntry {
  id: string;
  version: string;
  manifest: any;
  createdAt: string;
  SwDeployment: ManyEntry<SWDeploymentEntry>;
}

export interface CheckedEntryObj {
  [id: string]: boolean;
}

export interface McuWithFromVersion {
  id: string;
  from_version: string;
}

export interface InitiateSWUpdatePayload {
  to_version: string;
  type?: string;
  mcus: McuWithFromVersion[];
}

export interface SWDeploymentMap {
  [id: string]: SWDeploymentEntry;
}

export enum SwDeploymentStatus {
  BUILD_REQUESTED = "BUILD_REQUESTED",
  INITIATED = "INITIATED",
  ACKED = "ACKED",
  READY = "READY",
  START_REQUESTED = "START_REQUESTED",
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  CANCELED = "CANCELED",
}

export interface SWDeploymentEntry {
  id: string;
  status: SwDeploymentStatus;
  version: string;
  reason: string;
  createdAt: string;
  updatedAt: string;
  SwManifestId: string;
  McuId: string;
}

export interface SWDeploymentChangeEntry {
  id: string;
  status: SwDeploymentStatus;
  version: string;
  reason: string;
  created_at: string;
  updated_at: string;
  sw_manifest_id: string;
  mcu_id: string;
}

export interface SwManifestQs extends QueryString {
  version: string;
}

export interface FleetCounts {
  [ACTIVE]: number;
  [INACTIVE]: number;
  [INSTALLED]: number | [number, number];
}

export interface BasestationCounts {
  [INSTALLED]: number | [number, number];
  [ONLINE]: number;
  [OFFLINE]: number;
  [MAINTENANCE]: number;
}

export interface Pagination {
  rowsPerPage: number;
  page: number;
  dir: SortDirection;
  order: string;
}

// device monitoring
export interface SensorFailureMcu {
  id: string;
  McuId: string;
  mcuSn: string;
  vehicleId: string;
  data: {
    vpus: SensorFailureVpu[];
    cabCams: CabCamFailure[];
    packetVersion: string;
  };
  createdAt: string;
}

export interface CabCamFailure {
  id: string;
  blocked: boolean;
  status: number;
}

export interface SensorFailureVpu extends VpuSensors {
  id: string;
  sn: string;
}
export interface VpuSensors {
  gps: number;
  imu: number;
  lidar: number;
  uwb: number;
  camera: number;
}

export interface DeviceMonitoringTab {
  title: string;
  color: string;
  count?: number;
}

export interface HeartbeatDropoutEntry {
  id: string;
  McuId: string;
  mcuSn: string;
  vehicleId: string;
  modemId: string;
  mcuUpdatedAt: string;
  modemOnline: boolean;
  modemOnlineAt: string;
  modemOfflineAt: string;
  modemLastActive: number;
  mcuOfflineFor: number;
  thinClientOnlineAt: string;
  thinClientLastActive: string;
  createdAt: string;
}

// WorkGang
export interface WorkGangQs extends QueryString {
  active?: BooleanInternal;
  with_foreman: BooleanInternal;
}

export interface WorkGangEntry {
  id: string;
  name: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  ForemanId: string;
  Foreman: {
    name: string;
  };
}

// OTA
export interface ReleaseEntry {
  id: string;
  name: string;
  createdAt: string;
  latest: boolean;
}

export interface MenderDeploymentDevice {
  id: string;
  device_type: string;
}

export interface createMenderDeploymentPayload {
  release: string;
  name: string;
  devices: MenderDeploymentDevice[];
}

export interface MenderDeploymentEntry {
  id: string;
  deploymentId: string;
  deviceId: string;
  deviceType: string;
  name: string;
  status: string;
  release: string;
  createdAt: string;
  updatedAt: string;
}

export interface MenderDeploymentMap {
  [id: string]: MenderDeploymentEntry;
}

// video
export interface VideoEntry {
  id: string;
  onRequest: boolean;
  from: number;
  to: number;
  requestSent: boolean;
  createdAt: string;
  uploadedAt: string;
  Mcu: McuEntry;
  McuId: string;
  "Mcu.id": string;
  "Mcu.sn": string;
  "Mcu.Vehicle.id": string;
  files: boolean[] | null;
  processStatus: "PROCESSING" | "SUCCESS" | "FAILED" | "CANCELED";
  justAdded?: boolean;
}

export interface VideoQs extends QueryString, SearchQs {
  requested: BooleanInternal | null;
}

export interface VideoRequestPayload {
  from: number;
  to: number;
  id_kind: string;
  id_value: String;
  currentQs: VideoQs;
  requested_by?: string;
  notification?: "EMAIL" | "SMS" | "SLACK";
}

// search
export type SearchBy = "mcu_id" | "mcu_sn" | "vehicle_id";
export type SearchComponentType = "main" | "dialog";
export interface SearchDeviceActionPayload {
  component: SearchComponentType;
  idType: SearchBy;
  value: string;
}

export interface SearchQs {
  filter_by: SearchBy;
  filter_value: string;
}

// signed url
export type SignedURLType = "video" | "event";

// dashboard
export interface DegradedUnitsInfo {
  degraded: number;
  sightedTotal: number;
}
export interface McuFailureInfo {
  failure: number;
  success: number;
}

export interface DashboardInfo {
  unitCount: UnitCount;
  degradedUnits: DegradedUnitsInfo;
  mcuFailures: McuFailureInfo;
}

export interface UnitCount {
  active: number;
  inactive: number;
  uninstalled: number;
}

export interface SideMenuItem {
  label: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  path: string;
  allowedTo: Role[] | (Role | undefined)[];
  providersBlocked: string[];
  children?: SideMenuItem[];
}

export interface LccasTableItem {
  type: string;
  data?: any;
}

export interface TabProps {
  label: string;
  count: any;
  color: any;
}

export interface MapPoint {
  position: {
    lat: number;
    lng: number;
  };
  labelStyle?: any;
  labelText?: string;
  onClick?: Function | undefined;
  selected?: boolean;
  icon?: string | google.maps.Icon | google.maps.Symbol | undefined;
  zIdx?: number | undefined;
  key?: string;
  title?: string;
}

export interface MapLine {
  path: google.maps.LatLngLiteral[][];
  options: {
    strokeColor: string;
    strokeOpacity: number;
    strokeWeight: number;
    fillColor: string;
    fillOpacity: number;
    clickable: boolean;
    draggable: boolean;
    editable: boolean;
    visible: boolean;
    zIndex: number;
  };
  onClick?: Function | undefined;
}

export type AutocompleteOption = { label: string } | string;

export interface McuView {
  name: string;
  icon: SvgIconComponent | React.FC;
  to: string;
  label: string;
}

export interface AnchorType {
  designator: string;
  anchorNo: number;
  anchorSerialNo: string;
  picoTest: string;
  latitude: string;
  longitude: string;
  latDecimal: number;
  lngDecimal: number;
  location: string;
}

export interface FilterProp {
  label: string;
  onlyOne?: boolean;
  default?: string;
  autocomplete?: boolean;
  values: {
    [label: string]: string;
  };
}

export interface SelectedFilters {
  [label: string]: {
    label: string;
    values: string[];
    msg?: string;
  };
}

export interface FleetStream {
  fleet: McuEntry[];
  workGangs: WorkGangEntry[];
}

export interface AllowedVersionsType {
  minAppVersion: string;
  maxAppVersion: string;
}
