/**
 * Action types
 */
import {
  ManyEntry,
  VideoEntry,
  VideoQs,
  VideoRequestPayload,
} from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

import { SignedURLType } from "../const/types";

export const FETCH_MANY_VIDEOS = Symbol.for("FETCH_MANY_VIDEOS");
export const FETCH_SINGLE_VIDEO = Symbol.for("FETCH_SINGLE_VIDEO");
export const FETCH_VIDEO_FAILED = Symbol.for("FETCH_VIDEO_FAILED");
export const LOAD_MANY_VIDEOS = Symbol.for("LOAD_MANY_VIDEOS");
export const LOAD_SINGLE_VIDEO = Symbol.for("LOAD_SINGLE_VIDEO");
export const CREATE_VIDEO_REQUEST = Symbol.for("CREATE_VIDEO_REQUEST");
export const LOAD_CREATED_VIDEO_REQUEST = Symbol.for(
  "LOAD_CREATED_VIDEO_REQUEST"
);
export const PROCESS_VIDEO_REQUEST = Symbol.for("PROCESS_VIDEO_REQUEST");
export const CANCEL_VIDEO_REQUEST = Symbol.for("CANCEL_VIDEO_REQUEST");
export const FETCH_SIGNED_URL_FOR_VIDEO_DOWNLOAD = Symbol.for(
  "FETCH_SIGNED_URL_FOR_VIDEO_DOWNLOAD"
);
export const LOAD_SIGNED_URL_FOR_DOWNLOAD = Symbol.for(
  "LOAD_SIGNED_URL_FOR_DOWNLOAD"
);
export const FETCH_SIGNED_URL_FAILED = Symbol.for("FETCH_SIGNED_URL_FAILED");
/**
 * Actions
 */

export function fetchSingleVideoAction(id: string) {
  return {
    type: FETCH_SINGLE_VIDEO,
    payload: id,
  };
}

export function fetchManyVideoAction(qs: VideoQs) {
  return {
    type: FETCH_MANY_VIDEOS,
    payload: qs,
  };
}

export function videoFetchFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_VIDEO_FAILED,
    payload: err.toString(),
  };
}

export function loadManyVideoAction(payload: ManyEntry<VideoEntry>) {
  return {
    type: LOAD_MANY_VIDEOS,
    payload,
  };
}
export function loadSingleVideoAction(payload: VideoEntry | null) {
  return {
    type: LOAD_SINGLE_VIDEO,
    payload,
  };
}

export function createVideoRequestAction(payload: VideoRequestPayload) {
  return {
    type: CREATE_VIDEO_REQUEST,
    payload,
  };
}

export function loadCreatedVideoRequestAction(payload: VideoEntry) {
  return {
    type: LOAD_CREATED_VIDEO_REQUEST,
    payload,
  };
}

export function processVideoRequestAction(payload: string) {
  return { type: PROCESS_VIDEO_REQUEST, payload };
}

export function cancelVideoRequestAction(payload: string) {
  return { type: CANCEL_VIDEO_REQUEST, payload };
}

export function fetchSignedUrlForDownloadAction(
  id: string,
  type: SignedURLType,
  fromAlert?: boolean
) {
  return {
    type: FETCH_SIGNED_URL_FOR_VIDEO_DOWNLOAD,
    payload: {
      id,
      type,
      fromAlert,
    },
  };
}

export function loadSignedUrlForDownloadAction(
  id: string,
  type: SignedURLType,
  urls?: string[],
  url?: string
) {
  return {
    type: LOAD_SIGNED_URL_FOR_DOWNLOAD,
    payload: {
      id,
      url,
      urls,
      type,
    },
  };
}

export function downloadFetchFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_SIGNED_URL_FAILED,
    payload: err.toString(),
  };
}
