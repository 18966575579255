import * as React from "react";
import { SvgIcon } from "@mui/material";

// https://uxwing.com/ssh-icon/
// All icons are free to use any personal and commercial projects without any attribution or credit.
export const SSHIcon: React.FC<any> = ({ darkMode, ...props }) => {
  return (
    <SvgIcon viewBox={"0 0 122.88 103.53"} {...props}>
      <path
        fillRule="evenodd"
        d="M5.47,0H117.41a5.48,5.48,0,0,1,5.47,5.47V98.06a5.48,5.48,0,0,1-5.47,5.47H5.47A5.48,5.48,0,0,1,0,98.06V5.47A5.48,5.48,0,0,1,5.47,0Z"
      />
      <path
        fill={darkMode ? "#000" : "#fff"}
        fillRule="evenodd"
        d="M122.88,26.19v8.14H97.27a4.07,4.07,0,1,1,0-8.14ZM0,34.33V26.19H44.17a4.07,4.07,0,1,1,0,8.14Z"
      />
      <path
        fill={darkMode ? "#000" : "#fff"}
        d="M15.83,88l1.44-8.49a35.39,35.39,0,0,0,8.51,1.19,49,49,0,0,0,6.09-.31V77.85l-4.63-.41q-6.27-.57-8.61-3T16.29,67.2q0-6.57,2.86-9.05t9.69-2.47A54.19,54.19,0,0,1,41.18,57L39.89,65.2a49.74,49.74,0,0,0-7.66-.77,39.52,39.52,0,0,0-4.88.25V67.2l3.7.36q6.73.68,9.31,3.22a9.5,9.5,0,0,1,2.57,7.07,14.89,14.89,0,0,1-.88,5.47A9.29,9.29,0,0,1,40,86.72a7.94,7.94,0,0,1-3.42,1.77,21.09,21.09,0,0,1-3.88.75c-1.12.08-2.6.13-4.45.13A54.28,54.28,0,0,1,15.83,88Zm30.83,0,1.44-8.49a35.39,35.39,0,0,0,8.51,1.19,49,49,0,0,0,6.09-.31V77.85l-4.63-.41q-6.27-.57-8.61-3T47.12,67.2q0-6.57,2.85-9.05t9.7-2.47A54.19,54.19,0,0,1,72,57L70.72,65.2a49.85,49.85,0,0,0-7.66-.77,39.81,39.81,0,0,0-4.89.25V67.2l3.71.36q6.74.68,9.3,3.22a9.46,9.46,0,0,1,2.57,7.07,15.12,15.12,0,0,1-.87,5.47,9.42,9.42,0,0,1-2.08,3.4,8,8,0,0,1-3.42,1.77,21.25,21.25,0,0,1-3.88.75c-1.12.08-2.6.13-4.45.13A54.28,54.28,0,0,1,46.66,88Zm50.11.56V77.23H89.05V88.59H78.77V56.46H89.05V67.82h7.72V56.46h10.28V88.59Z"
      />
      <path
        fill={darkMode ? "#000" : "#fff"}
        fillRule="evenodd"
        d="M16.41,7.94A5.08,5.08,0,1,1,11.33,13a5.08,5.08,0,0,1,5.08-5.08Z"
      />
      <path
        fill={darkMode ? "#000" : "#fff"}
        fillRule="evenodd"
        d="M35.65,7.94A5.08,5.08,0,1,1,30.57,13a5.08,5.08,0,0,1,5.08-5.08Z"
      />
      <path
        fill={darkMode ? "#000" : "#fff"}
        d="M64.79,11.57,78.57,25.84l1.64,1.63a1.23,1.23,0,0,1,0,1.75L78.6,30.88,64.68,45.64a1.25,1.25,0,0,1-1.76,0L59,41.8A1.25,1.25,0,0,1,59,40L70.37,28.41,59.12,17.23a1.26,1.26,0,0,1,0-1.76L63,11.56a1.23,1.23,0,0,1,1.75,0l0,0Z"
      />
    </SvgIcon>
  );
};

export const DashboardIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 24 24"} {...props}>
    <path d="M9 16.5C9 15.103 9.956 13.927 11.208 13.594L11.208 4.458C11.208 4.086 11.587 3.708 11.958 3.708C12.413 3.708 12.75 4.086 12.75 4.458L12.75 13.594C14.044 13.927 15 15.103 15 16.5C15 18.155 13.655 19.5 12 19.5C10.345 19.5 9 18.155 9 16.5V16.5zM12 18C12.83 18 13.5 17.33 13.5 16.5C13.5 15.67 12.83 15 12 15C11.17 15 10.5 15.67 10.5 16.5C10.5 17.33 11.17 18 12 18zM5.625 6.75C5.625 6.127 6.127 5.625 6.75 5.625C7.373 5.625 7.875 6.127 7.875 6.75C7.875 7.373 7.373 7.875 6.75 7.875C6.127 7.875 5.625 7.373 5.625 6.75zM5.625 12C5.625 12.623 5.123 13.125 4.5 13.125C3.879 13.125 3.375 12.623 3.375 12C3.375 11.377 3.879 10.875 4.5 10.875C5.123 10.875 5.625 11.377 5.625 12zM18.375 12C18.375 11.377 18.877 10.875 19.5 10.875C20.123 10.875 20.625 11.377 20.625 12C20.625 12.623 20.123 13.125 19.5 13.125C18.877 13.125 18.375 12.623 18.375 12zM18.375 6.75C18.375 7.373 17.873 7.875 17.25 7.875C16.627 7.875 16.125 7.373 16.125 6.75C16.125 6.127 16.627 5.625 17.25 5.625C17.873 5.625 18.375 6.127 18.375 6.75zM0 12C0 5.372 5.372 0 12 0C18.628 0 24 5.372 24 12C24 18.628 18.628 24 12 24C5.372 24 0 18.628 0 12zM12 22.5C17.798 22.5 22.5 17.798 22.5 12C22.5 6.202 17.798 1.5 12 1.5C6.202 1.5 1.5 6.202 1.5 12C1.5 17.798 6.202 22.5 12 22.5z" />
  </SvgIcon>
);
export const FleetIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 24 24"} {...props}>
    <path d="M12 0C18.628 0 24 5.372 24 12C24 18.628 18.628 24 12 24C5.372 24 0 18.628 0 12C0 5.372 5.372 0 12 0zM12 22.5C12.783 22.5 13.894 21.825 14.902 19.814C15.366 18.886 15.755 17.723 15.989 16.5H7.969C8.245 17.723 8.634 18.886 9.098 19.814C10.106 21.825 11.217 22.5 12 22.5V22.5zM7.702 15H16.298C16.43 14.053 16.5 13.045 16.5 12C16.5 10.955 16.43 9.947 16.298 9H7.702C7.57 9.947 7.5 10.955 7.5 12C7.5 13.045 7.57 14.053 7.702 15V15zM15.989 7.5C15.755 6.234 15.366 5.114 14.902 4.185C13.894 2.174 12.783 1.5 12 1.5C11.217 1.5 10.106 2.174 9.098 4.185C8.634 5.114 8.245 6.234 7.969 7.5H15.989zM17.77 9C17.934 9.961 18 10.964 18 12C18 13.036 17.934 14.039 17.77 15H22.064C22.35 14.048 22.5 13.041 22.5 12C22.5 10.959 22.35 9.952 22.064 9H17.77zM15.352 2.047C16.336 3.375 17.114 5.269 17.564 7.5H21.487C20.278 4.945 18.061 2.959 15.352 2.047V2.047zM8.648 2.047C5.939 2.959 3.724 4.945 2.511 7.5H6.436C6.886 5.269 7.664 3.375 8.648 2.047V2.047zM1.5 12C1.5 13.041 1.652 14.048 1.935 15H6.188C6.066 14.039 6 13.036 6 12C6 10.964 6.066 9.961 6.188 9H1.935C1.652 9.952 1.5 10.959 1.5 12V12zM21.487 16.5H17.564C17.114 18.731 16.336 20.625 15.352 21.952C18.061 21.042 20.278 19.055 21.487 16.5zM6.436 16.5H2.511C3.724 19.055 5.939 21.042 8.648 21.952C7.664 20.625 6.886 18.731 6.436 16.5V16.5z" />
  </SvgIcon>
);
export const VpuIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 21 24"} {...props}>
    <path d="M10.5 24C4.701 24 0 22.322 0 20.25V3.75C0 1.679 4.701 0 10.5 0C16.299 0 21 1.679 21 3.75V20.25C21 22.322 16.299 24 10.5 24zM18.75 4.033L18.721 4.01C18.474 3.798 17.977 3.506 17.166 3.217C15.567 2.646 13.209 2.25 10.5 2.25C7.791 2.25 5.433 2.646 3.832 3.217C3.023 3.506 2.527 3.798 2.277 4.01L2.25 4.033V8.189C2.873 8.489 3.733 8.855 4.819 9.122C6.408 9.474 8.368 9.75 10.5 9.75C12.633 9.75 14.592 9.474 16.181 9.122C17.269 8.855 18.127 8.489 18.75 8.189L18.75 4.033zM18.75 10.669C18.127 10.917 17.442 11.128 16.72 11.306C14.93 11.747 12.787 12 10.5 12C8.213 12 6.071 11.747 4.28 11.306C3.559 11.128 2.874 10.917 2.25 10.669V14.189C2.873 14.489 3.733 14.855 4.819 15.122C6.408 15.474 8.368 15.75 10.5 15.75C12.633 15.75 14.592 15.474 16.181 15.122C17.269 14.855 18.127 14.489 18.75 14.189V10.669zM2.277 19.992C2.527 20.161 3.022 20.493 3.832 20.785C5.433 21.352 7.791 21.75 10.458 21.75C13.209 21.75 15.567 21.352 17.166 20.785C17.977 20.493 18.474 20.161 18.721 19.992L18.75 19.926V16.669C18.127 16.917 17.442 17.128 16.72 17.307C14.93 17.747 12.787 18 10.458 18C8.213 18 6.071 17.747 4.28 17.307C3.559 17.128 2.874 16.917 2.208 16.669V19.926L2.277 19.992zM18.844 19.87L18.839 19.88C18.844 19.833 18.848 19.87 18.848 19.87L18.844 19.87zM2.161 19.88C2.156 19.833 2.154 19.87 2.153 19.87L2.161 19.88zM2.161 4.12C2.155 4.127 2.153 4.13 2.153 4.13L2.161 4.12zM18.848 4.13C18.848 4.13 18.844 4.127 18.839 4.12z" />
  </SvgIcon>
);
export const VodIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 21 24"} {...props}>
    <path d="M0 3C0 1.345 1.345 0 3 0h15c1.655 0 3 1.345 3 3v18c0 1.655 -1.345 3 -3 3H3c-1.655 0 -3 -1.345 -3 -3V3zm7.5 18c0 0.413 0.338 0.75 0.75 0.75h4.5c0.413 0 0.75 -0.338 0.75 -0.75s-0.338 -0.75 -0.75 -0.75h-4.5c-0.413 0 -0.75 0.338 -0.75 0.75zM18 3H3v15h15V3z" />
  </SvgIcon>
);
export const CabCameraIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 24 21"} {...props}>
    <path d="M9.333 5a3 3 0 1 0 -6 0 3 3 0 1 0 6 0zm5 5H6.333c-2.762 0 -5 -2.237 -5 -5S3.571 0 6.333 0c1.637 0 3.087 0.783 4 2 0.912 -1.212 2.362 -2 4 -2 2.762 0 5 2.237 5 5s-2.237 5 -5 5zm-3 -5a3 3 0 1 0 6 0 3 3 0 1 0 -6 0zM4.667 14v4.667c0 0.367 0.3 0.667 0.667 0.667h9.333c0.367 0 0.667 -0.3 0.667 -0.667V14c0 -0.367 -0.3 -0.667 -0.667 -0.667H5.333c-0.367 0 -0.667 0.3 -0.667 0.667zm1.333 -2.667h8.667c1.471 0 2.667 1.196 2.667 2.667v4.667c0 1.471 -1.196 2.667 -2.667 2.667H5.333c-1.471 0 -2.667 -1.196 -2.667 -2.667V14c0 -0.229 0.029 -0.454 0.083 -0.667H1c-0.554 0 -1 -0.446 -1 -1s0.446 -1 1 -1h5zm16 1.525 -3.333 1.5V12.167l3.15 -1.417c0.121 -0.054 0.25 -0.092 0.379 -0.117 0.942 -0.158 1.804 0.571 1.804 1.525v7.529c0 0.908 -0.737 1.646 -1.646 1.646 -0.233 0 -0.462 -0.05 -0.675 -0.146L18.667 19.833v-2.192l3.333 1.5V12.858z" />
  </SvgIcon>
);
export const BasestationIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 24 24"} {...props}>
    <path d="M10.422 11.5C10.422 10.903 10.903 10.422 11.5 10.422C12.097 10.422 12.578 10.903 12.578 11.5C12.578 12.097 12.097 12.578 11.5 12.578C10.903 12.578 10.422 12.097 10.422 11.5zM15.66 5.521C16.783 5.148 17.852 6.218 17.479 7.34L15.283 13.922C15.072 14.564 14.564 15.072 13.922 15.283L7.34 17.479C6.218 17.852 5.148 16.783 5.521 15.66L7.717 9.078C7.928 8.436 8.436 7.928 9.078 7.717L15.66 5.521zM16.113 6.887L9.533 9.078C9.322 9.15 9.15 9.322 9.078 9.533L6.887 16.113L13.467 13.922C13.678 13.85 13.85 13.678 13.922 13.467L16.113 6.887zM0 11.5C0 5.148 5.148 0 11.5 0C17.852 0 23 5.148 23 11.5C23 17.852 17.852 23 11.5 23C5.148 23 0 17.852 0 11.5zM11.5 21.563C17.056 21.563 21.563 17.056 21.563 11.5C21.563 5.944 17.056 1.438 11.5 1.438C5.944 1.438 1.438 5.944 1.438 11.5C1.438 17.056 5.944 21.563 11.5 21.563z" />
  </SvgIcon>
);
export const WorkIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 24 24"} {...props}>
    <path d="M8.667 2.667a2 2 0 1 1 4 0 2 2 0 1 1 -4 0zM18.367 12c0.342 0.012 0.654 0.196 0.825 0.492l4.333 7.333c0.183 0.308 0.188 0.692 0.008 1.004s-0.508 0.504 -0.867 0.504H12c-0.321 0 -0.625 -0.154 -0.813 -0.417s-0.237 -0.596 -0.137 -0.9l0.667 -2c0.137 -0.408 0.517 -0.683 0.95 -0.683h1.475l0.846 -1.229L0.95 10.258c-0.508 -0.212 -0.75 -0.796 -0.537 -1.308s0.796 -0.75 1.308 -0.537L2.542 8.754l0.825 -1.508C4.008 6.067 5.242 5.333 6.583 5.333h0.158c2.179 0 4.104 1.408 4.767 3.483l1.346 4.233 3.292 1.371 1.367 -1.988c0.192 -0.283 0.517 -0.446 0.858 -0.433zm-7.933 0.042l-0.833 -2.617c-0.396 -1.246 -1.554 -2.092 -2.858 -2.092H6.583c-0.608 0 -1.171 0.333 -1.462 0.867l-0.721 1.325L10.433 12.042zm7.837 2.817l-2.779 4.042L15.192 19.333H20.912L18.271 14.858zM3.392 12.546l5.333 2.267c0.371 0.158 0.608 0.521 0.608 0.921V20.333c0 0.554 -0.446 1 -1 1s-1 -0.446 -1 -1V16.396L3.646 14.829 1.958 20.612c-0.154 0.529 -0.708 0.833 -1.242 0.679S-0.113 20.583 0.042 20.054L2.042 13.188l0.317 -1.079 1.037 0.442z" />
  </SvgIcon>
);
export const ParkIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"-3 -3 24 24"} {...props}>
    <path d="M7.657 0.527c0.13 -0.317 0.44 -0.527 0.781 -0.527h1.125c0.344 0 0.654 0.208 0.781 0.527L16.766 16.313H17.156c0.467 0 0.844 0.377 0.844 0.844s-0.377 0.844 -0.844 0.844H0.844c-0.467 0 -0.844 -0.377 -0.844 -0.844s0.377 -0.844 0.844 -0.844H1.234L7.657 0.527zM13.571 12.938H4.429L3.055 16.313H14.945l-1.371 -3.375zm-0.685 -1.688L11.281 7.313H6.719L5.115 11.25h7.769zM7.404 5.625h3.192L9 1.702 7.404 5.625z" />
  </SvgIcon>
);
export const TravelIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 24 24"} {...props}>
    <path d="M9.267 3.087c0.233 -0.7 -0.146 -1.454 -0.842 -1.688s-1.454 0.146 -1.688 0.842L6.15 4H4.667c-0.737 0 -1.333 0.596 -1.333 1.333s0.596 1.333 1.333 1.333h0.596l-0.887 2.667H3c-0.737 0 -1.333 0.596 -1.333 1.333s0.596 1.333 1.333 1.333H3.483L2.596 14.667H1.333c-0.737 0 -1.333 0.596 -1.333 1.333s0.596 1.333 1.333 1.333h0.371l-0.304 0.912c-0.233 0.7 0.146 1.454 0.842 1.688s1.454 -0.146 1.688 -0.842l0.583 -1.754H19.483l0.583 1.754c0.233 0.7 0.987 1.075 1.688 0.842s1.075 -0.987 0.842 -1.688L22.296 17.333H22.667c0.737 0 1.333 -0.596 1.333 -1.333s-0.596 -1.333 -1.333 -1.333H21.404l-0.887 -2.667H21c0.737 0 1.333 -0.596 1.333 -1.333s-0.596 -1.333 -1.333 -1.333H19.629l-0.887 -2.667H19.333c0.737 0 1.333 -0.596 1.333 -1.333s-0.596 -1.333 -1.333 -1.333H17.85l-0.583 -1.754c-0.233 -0.7 -0.987 -1.075 -1.688 -0.842s-1.075 0.987 -0.842 1.688L15.037 4H8.962l0.304 -0.912zM8.071 6.667H15.929l0.887 2.667H7.183l0.887 -2.667zM18.596 14.667H5.404l0.887 -2.667H17.704l0.887 2.667z" />
  </SvgIcon>
);
export const SettingsIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 24 21"} {...props}>
    <path d="m13.75 0 0.608 2.783c0.496 0.229 0.971 0.5 1.408 0.817l2.717 -0.867 3 5.196 -2.108 1.921c0.025 0.267 0.037 0.537 0.037 0.813s-0.012 0.546 -0.037 0.813l2.108 1.921 -3 5.196 -2.717 -0.867c-0.442 0.313 -0.912 0.587 -1.408 0.817L13.75 21.333H7.75l-0.608 -2.783c-0.496 -0.229 -0.971 -0.5 -1.408 -0.817l-2.721 0.867 -3 -5.2 2.108 -1.921c-0.025 -0.267 -0.037 -0.537 -0.037 -0.813s0.012 -0.546 0.037 -0.813L0.012 7.933l3 -5.196 2.717 0.867c0.442 -0.313 0.912 -0.587 1.408 -0.817L7.75 0h6zm2.625 5.508 -0.954 0.304 -0.813 -0.579c-0.342 -0.242 -0.704 -0.454 -1.083 -0.629l-0.908 -0.417 -0.212 -0.975 -0.262 -1.212h-2.783l-0.267 1.212 -0.212 0.975 -0.908 0.417c-0.383 0.175 -0.746 0.388 -1.083 0.629l-0.813 0.579 -0.95 -0.304 -1.183 -0.375 -1.392 2.404 0.917 0.838 0.737 0.671 -0.092 0.992c-0.021 0.208 -0.029 0.417 -0.029 0.629s0.008 0.421 0.029 0.629l0.092 0.992L3.467 12.958l-0.917 0.838 1.392 2.408 1.183 -0.375 0.954 -0.304 0.813 0.579c0.342 0.242 0.704 0.454 1.083 0.629l0.908 0.417 0.212 0.975 0.267 1.212h2.779l0.267 -1.212 0.212 -0.975 0.908 -0.417c0.383 -0.175 0.746 -0.388 1.083 -0.629l0.813 -0.579 0.954 0.304 1.183 0.375 1.392 -2.408 -0.921 -0.838 -0.737 -0.671 0.092 -0.992c0.021 -0.208 0.029 -0.417 0.029 -0.629s-0.008 -0.421 -0.029 -0.629l-0.092 -0.992 0.737 -0.671 0.921 -0.838 -1.392 -2.408 -1.183 0.375zM12.75 10.667a2 2 0 1 0 -4 0 2 2 0 1 0 4 0zm-6 0a4 4 0 1 1 8 0 4 4 0 1 1 -8 0z" />
  </SvgIcon>
);
export const MCUIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 21 24"} {...props}>
    <path d="M18 0H3C1.35 0 0 1.35 0 3v15c0 1.108 0.607 2.064 1.5 2.584V22.5c0 0.828 0.672 1.5 1.5 1.5h15c0.828 0 1.5 -0.672 1.5 -1.5v-1.916C20.391 20.063 21 19.106 21 18V3c0 -1.65 -1.35 -3 -3 -3zm0 22.5H3v-1.5h15v1.5zm1.5 -4.5c0 0.828 -0.672 1.5 -1.5 1.5H3c-0.828 0 -1.5 -0.672 -1.5 -1.5V3c0 -0.828 0.672 -1.5 1.5 -1.5h15c0.828 0 1.5 0.672 1.5 1.5v15zM14.958 3h-9c-1.657 0 -3 1.343 -3 3l0.042 3c0 1.657 1.343 3 2.958 3h9c1.697 0 3.042 -1.345 3.042 -3v-3c-0.042 -1.657 -1.345 -3 -3.042 -3zM16.5 9c0 0.827 -0.673 1.5 -1.5 1.5H6c-0.825 0 -1.5 -0.675 -1.5 -1.5v-3c0 -0.827 0.673 -1.5 1.5 -1.5h9c0.827 0 1.5 0.673 1.5 1.5v3zM4.5 14.625c-0.621 0 -1.125 0.503 -1.125 1.125 0 0.621 0.504 1.125 1.125 1.125s1.125 -0.504 1.125 -1.125c0 -0.623 -0.502 -1.125 -1.125 -1.125zm12.75 0.375H11.25c-0.415 0 -0.75 0.335 -0.75 0.75s0.335 0.75 0.75 0.75h6c0.415 0 0.75 -0.335 0.75 -0.75s-0.338 -0.75 -0.75 -0.75z" />
  </SvgIcon>
);
export const EquipmentIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 21 24"} {...props}>
    <path d="M16.5 2.25c1.242 0 2.25 1.008 2.25 2.25v4.5H2.25V4.5c0 -1.242 1.008 -2.25 2.25 -2.25h12zM2.25 16.5V11.25h16.5v5.25c0 1.242 -1.008 2.25 -2.25 2.25H4.5c-1.242 0 -2.25 -1.008 -2.25 -2.25zM4.5 0C2.016 0 0 2.016 0 4.5v12c0 2.006 1.313 3.703 3.122 4.284L1.828 22.078c-0.441 0.441 -0.441 1.153 0 1.589s1.153 0.441 1.589 0l2.672 -2.672h8.822l2.672 2.672c0.441 0.441 1.153 0.441 1.589 0s0.441 -1.153 0 -1.589l-1.294 -1.294C19.688 20.203 21 18.506 21 16.5V4.5c0 -2.484 -2.016 -4.5 -4.5 -4.5H4.5zm6 16.875a1.875 1.875 0 1 0 0 -3.75 1.875 1.875 0 1 0 0 3.75z" />
  </SvgIcon>
);
export const AlertIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 24 24"} {...props}>
    <path d="M11.644 3.952c0.075 -0.127 0.211 -0.202 0.356 -0.202s0.281 0.075 0.356 0.202L21.652 19.219c0.066 0.108 0.098 0.23 0.098 0.352 0 0.375 -0.305 0.68 -0.68 0.68h-18.141c-0.375 0 -0.68 -0.305 -0.68 -0.68 0 -0.127 0.033 -0.248 0.098 -0.352L11.644 3.952zm-1.922 -1.172L0.427 18.047c-0.281 0.459 -0.427 0.984 -0.427 1.523C0 21.188 1.313 22.5 2.93 22.5h18.141c1.617 0 2.93 -1.313 2.93 -2.93 0 -0.539 -0.15 -1.064 -0.427 -1.523L14.278 2.78C13.795 1.987 12.933 1.5 12 1.5s-1.795 0.488 -2.278 1.28zM13.5 17.25a1.5 1.5 0 1 0 -3 0 1.5 1.5 0 1 0 3 0zm-0.375 -8.625c0 -0.623 -0.502 -1.125 -1.125 -1.125s-1.125 0.502 -1.125 1.125v4.5c0 0.623 0.502 1.125 1.125 1.125s1.125 -0.502 1.125 -1.125v-4.5z" />
  </SvgIcon>
);
export const CloudArrowDown: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 24 19.2"} {...props}>
    <path d="M13.309 4.564c0.517 0.6 1.369 0.791 2.096 0.469 0.334 -0.146 0.701 -0.232 1.095 -0.232 1.492 0 2.7 1.208 2.7 2.7 0 0.15 -0.011 0.296 -0.034 0.439 -0.131 0.81 0.304 1.609 1.054 1.939C21.39 10.384 22.2 11.55 22.2 12.9c0 1.755 -1.373 3.195 -3.105 3.292 -0.022 0 -0.049 0.004 -0.071 0.007H5.4c-1.987 0 -3.6 -1.613 -3.6 -3.6 0 -1.564 0.998 -2.899 2.4 -3.394 0.72 -0.255 1.2 -0.934 1.2 -1.699v-0.007c0 -2.486 2.014 -4.5 4.5 -4.5 1.361 0 2.58 0.604 3.409 1.564zM19.2 18v-0.007c2.678 -0.154 4.8 -2.374 4.8 -5.093 0 -2.089 -1.256 -3.889 -3.056 -4.676 0.037 -0.236 0.056 -0.48 0.056 -0.724 0 -2.486 -2.014 -4.5 -4.5 -4.5 -0.652 0 -1.267 0.139 -1.826 0.386C13.515 2.047 11.809 1.2 9.9 1.2c-3.48 0 -6.3 2.82 -6.3 6.3v0.007C1.504 8.25 0 10.249 0 12.6c0 2.981 2.419 5.4 5.4 5.4h13.8zM8.362 11.737l3 3c0.352 0.352 0.922 0.352 1.271 0l3 -3c0.352 -0.352 0.352 -0.922 0 -1.271s-0.922 -0.352 -1.271 0l-1.462 1.462V6.9c0 -0.499 -0.401 -0.9 -0.9 -0.9s-0.9 0.401 -0.9 0.9v5.029l-1.462 -1.462c-0.352 -0.352 -0.922 -0.352 -1.271 0s-0.352 0.922 0 1.271z" />
  </SvgIcon>
);
export const MilepostIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 24 24"} {...props}>
    <polyline points="0, 0 1, 1 2, 2" />
  </SvgIcon>
);
export const AnchorIcon: React.FC = (props: any) => (
  <SvgIcon viewBox={"0 0 24 19.2"} {...props}>
    <path d="M13.333 3.333a1.333 1.333 0 1 1 -2.667 0 1.333 1.333 0 1 1 2.667 0M12 0c-1.842 0 -3.333 1.492 -3.333 3.333 0 1.496 0.987 2.762 2.346 3.183q-0.012 0.075 -0.012 0.15v1.333h-2c-0.554 0 -1 0.446 -1 1s0.446 1 1 1h2v9.333h-1c-3.071 0 -5.571 -2.442 -5.662 -5.492l0.679 0.583c0.421 0.358 1.05 0.313 1.408 -0.108s0.313 -1.05 -0.108 -1.408l-2.333 -2c-0.375 -0.321 -0.929 -0.321 -1.3 0l-2.333 2c-0.421 0.358 -0.467 0.992 -0.108 1.408s0.992 0.467 1.408 0.108L2.333 13.838C2.425 17.992 5.825 21.333 10 21.333h4c4.175 0 7.575 -3.342 7.667 -7.496l0.683 0.587c0.421 0.358 1.05 0.313 1.408 -0.108s0.313 -1.05 -0.108 -1.408l-2.333 -2c-0.375 -0.321 -0.925 -0.321 -1.3 0l-2.333 2c-0.421 0.358 -0.467 0.992 -0.108 1.408s0.992 0.467 1.408 0.108l0.679 -0.583C19.571 16.892 17.071 19.333 14 19.333h-1V10h2c0.554 0 1 -0.446 1 -1s-0.446 -1 -1 -1h-2v-1.333q0 -0.075 -0.012 -0.15C14.346 6.096 15.333 4.829 15.333 3.333c0 -1.842 -1.492 -3.333 -3.333 -3.333" />
  </SvgIcon>
);
