import * as trackAction from "../actions/track.action";
import { catchError, debounce, map, switchMap } from "rxjs/operators";
import { EMPTY, Observable, of, timer, merge } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import { AssetEntry, NationalRouteEntry, TrackEntry } from "../const/types";
import { ajax } from "rxjs/ajax";
import { TRACK_API } from "../const/api";
import {
  getNationalRoute,
  getNationalRouteBoundaries,
  getToken,
} from "../reducers";
import { joinQueryStr } from "../helper/util";
import { RootState } from "../config/store";
import booleanIntersects from "@turf/boolean-intersects";
import { polygon } from "@turf/helpers";

export const fetchTracksEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(trackAction.FETCH_TRACKS),
    map((action) => action.payload),
    debounce(({ debounce }) => timer(debounce)),
    switchMap(({ qs, withAssets }) => {
      const actions: Observable<AnyAction>[] = [
        ajax
          .getJSON<TrackEntry[]>(
            `${TRACK_API}/lines_with_offset?${joinQueryStr(qs)}`,
            {
              Authorization: `Bearer ${getToken(state$.value)}`,
            }
          )
          .pipe(map(trackAction.loadTracksAction)),
      ];
      if (withAssets) {
        actions.push(
          ajax
            .getJSON<AssetEntry[]>(`${TRACK_API}/assets?${joinQueryStr(qs)}`, {
              Authorization: `Bearer ${getToken(state$.value)}`,
            })
            .pipe(map(trackAction.loadAssetsAction))
        );
      }
      return merge(...actions).pipe(
        catchError((err) => of(trackAction.fetchTrackFailedAction(err)))
      );
    })
  );

export const fetchNationalRouteEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(trackAction.FETCH_NATIONAL_ROUTE),
    map((action) => action.payload),
    debounce(({ boundary, debounce }) => timer(debounce)),
    switchMap(({ boundary, debounce }) => {
      if (!boundary.sw) {
        return EMPTY;
      }
      const [lat1, lng1] = boundary.sw?.split(",");
      const [lat2, lng2] = boundary.ne?.split(",");
      const mapBox = polygon([
        [
          [+lng1, +lat1],
          [+lng1, +lat2],
          [+lng2, +lat2],
          [+lng2, +lat1],
          [+lng1, +lat1],
        ],
      ]);
      const existingNR = getNationalRoute(state$.value);
      const ids = getNationalRouteBoundaries(state$.value)
        .filter(
          (b) =>
            booleanIntersects(b, mapBox) &&
            !existingNR.hasOwnProperty(b.properties.id)
        )
        .map((b) => b.properties.id);
      if (ids.length === 0) {
        return EMPTY;
      }
      return ajax
        .getJSON<NationalRouteEntry[]>(
          `${TRACK_API}/routes_with_ids?ids=${JSON.stringify(ids)}`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(trackAction.loadNationalRouteAction),
          catchError((err) => of(trackAction.fetchTrackFailedAction(err)))
        );
    })
  );

export const fetchNationalRouteBoundaryEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(trackAction.FETCH_NATIONAL_ROUTE_BOUNDARIES),
    map((action) => action.payload),
    switchMap(() =>
      ajax
        .getJSON<NationalRouteEntry[]>(`${TRACK_API}/route_boundaries`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(trackAction.loadNationalRouteBoundariesAction),
          catchError((err) => of(trackAction.fetchTrackFailedAction(err)))
        )
    )
  );

export const trackEpics = [
  fetchTracksEpic,
  fetchNationalRouteEpic,
  fetchNationalRouteBoundaryEpic,
];
