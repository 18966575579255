import * as React from "react";
import { getMe } from "../../reducers";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Role } from "../../const/types";
import { FunctionComponent, memo, PropsWithChildren, useEffect } from "react";
import { push } from "redux-first-history";
import { Loading } from "./Loading";

interface PropsFromParent {
  rolesAllowedTo: (Role | undefined)[];
  providersBlocked?: string[];
}
const ProtectedRoute: FunctionComponent<PropsWithChildren<Props>> = ({
  me,
  children,
  rolesAllowedTo,
  providersBlocked = [],
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      me.Role?.label &&
      !rolesAllowedTo.includes(me.Role?.label) &&
      !providersBlocked.includes(me.provider)
    ) {
      dispatch(push("/fleet"));
    }
  }, [rolesAllowedTo, dispatch, me, providersBlocked]);

  return <>{me.Role?.label !== Role.AMTRAK ? children : <Loading />}</>;
};

const mapStateToProps = (state: any) => ({
  me: getMe(state),
});

const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type StateProps = ConnectedProps<typeof connector>;

type Props = PropsFromParent & StateProps;

export default connector(memo(ProtectedRoute));
