/**
 * Action types
 */
import { ManyEntry, WorkGangQs, WorkGangEntry } from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_MANY_WORK_GANGS = Symbol.for("FETCH_MANY_WORK_GANGS");
export const FETCH_WORK_GANGS_FAILED = Symbol.for("FETCH_WORK_GANGS_FAILED");
export const LOAD_MANY_WORK_GANGS = Symbol.for("LOAD_MANY_WORK_GANGS");
export const DEACTIVATE_WORK_GANG = Symbol.for("DEACTIVATE_WORK_GANG");
export const CHANGE_WORK_GANG_STATUS = Symbol.for("CHANGE_WORK_GANG_STATUS");

/**
 * Actions
 */
export function fetchManyWorkGangAction(qs: WorkGangQs) {
  return {
    type: FETCH_MANY_WORK_GANGS,
    payload: qs,
  };
}

export function fetchWorkGangFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_WORK_GANGS_FAILED,
    payload: err.toString(),
  };
}

export function loadManyWorkGangAction(payload: ManyEntry<WorkGangEntry>) {
  return {
    type: LOAD_MANY_WORK_GANGS,
    payload,
  };
}

export function deactivateWorkGangAction(id: string) {
  return {
    type: DEACTIVATE_WORK_GANG,
    payload: id,
  };
}

export function changeWorkGangStatus(id: string, active: boolean) {
  return {
    type: CHANGE_WORK_GANG_STATUS,
    payload: { id, active },
  };
}
