import { AnyAction } from "redux";
import {
  DEFAULT_MAP,
} from "../const/const";
import * as mapAction from "../actions/map.action";
import * as trackAction from "../actions/track.action";
import {
  MapPoint,
  MapLine,
  TrackEntry,
  AssetEntry,
  NationalRouteEntry,
} from "../const/types";
import { TRACK_OPTIONS } from "../const/ui";
import { getLabel } from "../helper/mapSymbology";
import { MIN_ZOOM_LEVEL_FOR_TRACK_DATA } from "../const/const";
import { getIcon } from "../helper/mapSymbology";

export interface State {
  map: google.maps.Map | null;
  isLoaded: boolean;
  onLoad: (arg0: Function) => void; // typeof useCallback
  onUnmount: (arg0: Function) => void;
  center: google.maps.LatLngLiteral;
  zoom: number;
  onBoundsChanged: (map: google.maps.Map | null) => void;
  onCenterChanged: (map: google.maps.Map | null) => void;
  onZoomChanged: (map: google.maps.Map | null) => void;
  onDragEnd: (map: google.maps.Map | null) => void;
  points: MapPoint[][];
  clusterPoints: MapPoint[][];
  lines: MapLine[];
  routes: MapLine[];
  tracks: MapLine[];
  mileposts: MapPoint[];
}

const initialState: State = {
  map: null,
  isLoaded: false,
  onLoad: () => null,
  onUnmount: () => null,
  center: DEFAULT_MAP.center,
  zoom: DEFAULT_MAP.zoom,
  onBoundsChanged: () => null,
  onCenterChanged: () => null,
  onZoomChanged: () => null,
  onDragEnd: () => null,
  points: [],
  clusterPoints: [],
  lines: [],
  routes: [{ path: [], options: TRACK_OPTIONS }],
  tracks: [],
  mileposts: [],
};

/**
 * Reducers
 */

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case mapAction.SET_MAP: {
      return { ...state, map: action.payload };
    }
    case mapAction.SET_MAP_CENTER: {
      return { ...state, center: action.payload };
    }
    case mapAction.SET_MAP_ZOOM: {
      return { ...state, zoom: action.payload };
    }
    case mapAction.SET_MAP_POINTS: {
      return { ...state, points: action.payload };
    }
    case mapAction.SET_MAP_CLUSTER_POINTS: {
      return { ...state, clusterPoints: action.payload };
    }
    case mapAction.SET_MAP_LINES: {
      return { ...state, lines: action.payload };
    }
    case mapAction.SET_MAP_ON_BOUNDS_CHANGE: {
      return { ...state, onBoundsChanged: action.payload };
    }
    case mapAction.SET_MAP_ON_CENTER_CHANGE: {
      return { ...state, onCenterChanged: action.payload };
    }
    case mapAction.SET_MAP_ON_ZOOM_CHANGE: {
      return { ...state, onZoomChanged: action.payload };
    }
    case mapAction.SET_MAP_ON_DRAG_END: {
      return { ...state, onDragEnd: action.payload };
    }
    case mapAction.RESET_MAP: {
      // retain any loaded routes for future use
      return {
        ...initialState,
        routes: state.routes,
      };
    }
    case trackAction.LOAD_TRACKS: {
      const tracks: TrackEntry[] = action.payload;
      if (!state.map) return { ...state };
      if (state.map.getZoom()! >= MIN_ZOOM_LEVEL_FOR_TRACK_DATA) {
        return {
          ...state,
          tracks: [
            {
              path: tracks.map(({ line }) =>
                line.map(({ lla }) => ({ lat: lla[0], lng: lla[1] }))
              ),
              options: TRACK_OPTIONS,
            },
          ],
        };
      } else {
        return {
          ...state,
          points: [],
        };
      }
    }
    case trackAction.LOAD_NATIONAL_ROUTE: {
      const payload = action.payload as NationalRouteEntry[];
      const newRoutes = [...state.routes];
      payload.forEach((r) => {
        const path: google.maps.LatLngLiteral[] = r.geometry.coordinates.map(
          ([lng, lat]) => ({ lat, lng })
        );
        newRoutes.push({
          path: [path],
          options: TRACK_OPTIONS,
        });
      });
      return {
        ...state,
        mileposts: [],
        tracks: [],
        routes: newRoutes,
      };
    }
    case trackAction.FETCH_NATIONAL_ROUTE: {
      return {
        ...state,
        mileposts: [],
        tracks: [],
      };
    }
    case trackAction.LOAD_ASSETS: {
      const assets: AssetEntry[] = action.payload;
      if (!state.map) return { ...state };
      if (state.map.getZoom()! >= MIN_ZOOM_LEVEL_FOR_TRACK_DATA) {
        return {
          ...state,
          mileposts: assets.reduce(
            (acc: MapPoint[], { type, name, point, id }) => {
              if (type === "milepost") {
                acc.push({
                  labelStyle: getLabel("milepost"),
                  labelText: name,
                  position: {
                    lat: point.coordinates[1],
                    lng: point.coordinates[0],
                  },
                  zIdx: undefined,
                  icon: getIcon("mpRect"),
                  key: `milepost${id}`,
                });
              }
              return acc;
            },
            []
          ),
        };
      } else {
        return { ...state };
      }
    }
    default:
      return state;
  }
}

/**
 * selectors
 */

export const getMap = (state: State) => state.map;
export const getMapCenter = (state: State) => state.center;
export const getMapZoom = (state: State) => state.zoom;
export const getMapLoaded = (state: State) => state.isLoaded;
export const getMapPoints = (state: State) => state.points;
export const getMapClusterPoints = (state: State) => state.clusterPoints;
export const getMapLines = (state: State) => state.lines;
export const getMapTracks = (state: State) => state.tracks;
export const getMapNatRoutes = (state: State) => state.routes;
export const getMapMileposts = (state: State) => state.mileposts;
export const getMapBoundsFunc = (state: State) => state.onBoundsChanged;
export const getMapCenterFunc = (state: State) => state.onCenterChanged;
export const getMapZoomFunc = (state: State) => state.onZoomChanged;
export const getMapOnDragEndFunc = (state: State) => state.onDragEnd;
