/**
 * Action types
 */
import {
  ManyEntry,
  RoleEntry,
  UserEntry,
  UserQsModified,
} from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_MANY_USERS = Symbol.for("FETCH_MANY_USERS");
export const FETCH_ROLES = Symbol.for("FETCH_ROLES");
export const FETCH_SINGLE_USER = Symbol.for("FETCH_SINGLE_USER");
export const FETCH_USERS_FAILED = Symbol.for("FETCH_USERS_FAILED");
export const LOAD_MANY_USERS = Symbol.for("LOAD_MANY_USERS");
export const LOAD_ROLES = Symbol.for("LOAD_ROLES");

/**
 * Actions
 */
export function fetchManyUserAction(qs: UserQsModified) {
  return {
    type: FETCH_MANY_USERS,
    payload: qs,
  };
}

export function fetchRolesAction() {
  return {
    type: FETCH_ROLES,
  };
}

export function fetchUserFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_USERS_FAILED,
    payload: err.toString(),
  };
}

export function loadManyUserAction(payload: ManyEntry<UserEntry>) {
  return {
    type: LOAD_MANY_USERS,
    payload,
  };
}

export function loadRolesAction(payload: RoleEntry[]) {
  return {
    type: LOAD_ROLES,
    payload,
  };
}
