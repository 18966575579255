/**
 * Action types
 */
import { AlertEntry, AlertQs, AlertTypeEntry, ManyEntry } from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";
import { SetStateAction } from "react";

export const FETCH_MANY_ALERTS = Symbol.for("FETCH_MANY_ALERTS");
export const FETCH_ALERTS_TYPES = Symbol.for("FETCH_ALERTS_TYPES");
export const FETCH_SINGLE_ALERT = Symbol.for("FETCH_SINGLE_ALERT");
export const FETCH_ALERTS_FAILED = Symbol.for("FETCH_ALERTS_FAILED");
export const LOAD_MANY_ALERTS = Symbol.for("LOAD_MANY_ALERTS");
export const LOAD_ALERT_TYPES = Symbol.for("LOAD_ALERT_TYPES");
export const APPEND_ALERT = Symbol.for("APPEND_ALERT");
export const SET_ALERT_QUERY_STRING = Symbol.for("SET_ALERT_QUERY_STRING");
export const NEW_ALERT = Symbol.for("NEW_ALERT");
export const SET_SEARCH_INPUT_VALUE = Symbol.for("SET_INPUT_VALUE");
export const SET_SEARCH_RESULTS = Symbol.for("SET_SEARCH_RESULTS");

/**
 * Actions
 */
export function fetchManyAlertAction(qs: AlertQs) {
  return {
    type: FETCH_MANY_ALERTS,
    payload: qs,
  };
}

export function fetchAlertTypesAction() {
  return {
    type: FETCH_ALERTS_TYPES,
  };
}

export function fetchAlertFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_ALERTS_FAILED,
    payload: err.toString(),
  };
}
export function loadManyAlertAction(payload: ManyEntry<AlertEntry>) {
  return {
    type: LOAD_MANY_ALERTS,
    payload,
  };
}

export function newAlertAction(payload: AlertEntry) {
  return {
    type: NEW_ALERT,
    payload,
  };
}

export function appendAlertAction(payload: AlertEntry) {
  return {
    type: APPEND_ALERT,
    payload,
  };
}

export function loadAlertTypesAction(payload: AlertTypeEntry[]) {
  return {
    type: LOAD_ALERT_TYPES,
    payload,
  };
}

export function setAlertQueryStringAction(payload: SetStateAction<AlertQs>) {
  return {
    type: SET_ALERT_QUERY_STRING,
    payload,
  };
}

export function setSearchInputValueAction(payload: string) {
  return {
    type: SET_SEARCH_INPUT_VALUE,
    payload,
  };
}

export function setSearchResultsAction(payload: any) {
  return {
    type: SET_SEARCH_RESULTS,
    payload,
  };
}
