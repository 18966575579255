import * as installationAction from "../actions/installation.action";
import {
  catchError,
  debounce,
  filter,
  ignoreElements,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { Observable, of, timer } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import { ManyEntry, InstallationQs, InstallationEntry } from "../const/types";
import { ajax } from "rxjs/ajax";
import { INSTALLATION_API } from "../const/api";
import { getToken } from "../reducers";
import { downloadAsFile, formatDate, joinQueryStr } from "../helper/util";
import { RootState } from "../config/store";
import {
  DEFAULT_SEARCH_DEBOUNCE_TIME,
  FILENAME_DATE_FORMAT,
} from "../const/const";

export const fetchManyInstallationsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(installationAction.FETCH_MANY_INSTALLATIONS),
    map((action) => action.payload),
    filter((qs: InstallationQs) => !qs.mcu_sn || qs.mcu_sn.length > 2),
    debounce((qs: InstallationQs) =>
      timer(!!qs.mcu_sn ? DEFAULT_SEARCH_DEBOUNCE_TIME : 0)
    ),
    switchMap((qs: InstallationQs) =>
      ajax
        .getJSON<ManyEntry<InstallationEntry>>(
          `${INSTALLATION_API}/install_requests?${joinQueryStr(qs)}`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(installationAction.loadManyInstallationAction),
          catchError((err) =>
            of(installationAction.fetchInstallationFailedAction(err))
          )
        )
    )
  );

export const downloadInstalledUnitCSVEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(installationAction.DOWNLOAD_CSV),
    switchMap(() =>
      ajax
        .getJSON<{ csv: string }>(
          `${INSTALLATION_API}/installed_units?format=csv`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          tap(({ csv }) => {
            const filename = `MOW_Vehicles_Installation_${formatDate(
              new Date(),
              FILENAME_DATE_FORMAT
            )}`;
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            downloadAsFile(blob, filename);
          }),
          ignoreElements(),
          catchError((err) =>
            of(installationAction.fetchInstallationFailedAction(err))
          )
        )
    )
  );

export const fetchSingleInstallationEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(installationAction.FETCH_SINGLE_INSTALLATION),
    map((action) => action.payload),
    switchMap((mcuId: string) => {
      return ajax
        .getJSON<InstallationEntry>(
          `${INSTALLATION_API}/install_requests_with_mcu_id/${mcuId}`,
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          map(installationAction.loadSingleInstallationAction),
          catchError((err) =>
            of(installationAction.fetchInstallationFailedAction(err))
          )
        );
    })
  );

export const installationEpics = [
  fetchManyInstallationsEpic,
  fetchSingleInstallationEpic,
  downloadInstalledUnitCSVEpic,
];
