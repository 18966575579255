import * as searchBarAction from "../actions/searchBar.action";
import { AnyAction } from "redux";

/** searchVal is an Autocomplete or SearchTextField's "value" prop; this
 *  represents the selected value, same as pressing "Enter"
 * inputVal is an Autocomplete's "inputValue" prop; this represents
 * value displayed in the text field
 */
export interface State {
  searchVal: string;
  inputVal: string;
  suggestions: string[];
  prefix: string | undefined;
}

const initialState: State = {
  searchVal: "",
  inputVal: "",
  suggestions: [],
  prefix: undefined,
};

/**
 * Reducers
 */

export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case searchBarAction.SET_SEARCH_VAL: {
      return { ...state, searchVal: action.payload };
    }

    case searchBarAction.SET_INPUT_VAL: {
      return { ...state, inputVal: action.payload };
    }

    case searchBarAction.SET_SUGGESTIONS: {
      return { ...state, suggestions: action.payload };
    }

    case searchBarAction.RESET_SEARCH_BAR: {
      return { ...initialState };
    }

    case searchBarAction.SET_SEARCH_PREFIX: {
      return { ...state, prefix: action.payload };
    }

    default:
      return state;
  }
}

/**
 * Selectors
 */

export const getSearchVal = (state: State) => state.searchVal;
export const getInputVal = (state: State) => state.inputVal;
export const getSuggestions = (state: State) => state.suggestions;
export const getSearchPrefix = (state: State) => state.prefix;
