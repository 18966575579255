import React, { lazy, memo, Suspense } from "react";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { Navigate, Route, Routes } from "react-router-dom";
import { history, RootState } from "./config/store";
import Navigation from "./components/Navigation/Navigation";
import FormDPage from "./pages/formd/FormDPage";
import { Loading } from "./components/common/Loading";
import Notification from "./components/common/Notification";
import AccountPage from "./pages/account/AccountPage";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { getDarkMode } from "./reducers";
import ProtectedRoute from "./components/common/ProtectedRoute";
import {
  ALERT_PAGE,
  ALERT_PATH,
  BASESTATION_PAGE,
  BASESTATION_PATH,
  CAB_CAM_PAGE,
  CAB_CAM_PATH,
  DASHBOARD_PAGE,
  DASHBOARD_PATH,
  DEVICE_MONITORING_PAGE,
  DEVICE_MONITORING_PATH,
  EQUIPMENT_PATH,
  FAT_PAGE,
  FAT_PATH,
  FLEET_PATH,
  FORM_D_PAGE,
  FORM_D_PATH,
  INSTALLATION_PAGE,
  INSTALLATION_PATH,
  MCU_PAGE,
  MCU_PATH,
  SW_UPDATE_PAGE,
  SW_UPDATE_PATH,
  WORK_GANG_PAGE,
  USER_PATH,
  USERS_PAGE,
  VEHICLE_PAGE,
  VIDEO_PAGE,
  VIDEO_PATH,
  VOD_PAGE,
  VOD_PATH,
  VPU_PAGE,
  VPU_PATH,
  WORK_GANG_PATH,
  SW_MANIFEST_PATH,
  ANCHOR_PATH,
  ANCHOR_PAGE,
  VEHICLE_PATH,
  FORBIDDEN_PATH,
} from "./const/ui";
import { appTheme } from "./const/themes";
const DashboardPage = lazy(() => import("./pages/dashboard/DashboardPage"));
const LoginPage = lazy(() => import("./pages/login/LoginPage"));
const McuPage = lazy(() => import("./pages/mcu/MCUPage"));
const FleetPage = lazy(() => import("./pages/fleet/FleetPage"));
const EquipmentPage = lazy(() => import("./pages/vehicle/EquipmentPage"));
const InstallationPage = lazy(
  () => import("./pages/installation/InstallationPage")
);
const UserPage = lazy(() => import("./pages/user/UserPage"));
const VpuPage = lazy(() => import("./pages/vpu/VpuPage"));
const VodPage = lazy(() => import("./pages/vod/VodPage"));
const CabCameraPage = lazy(() => import("./pages/cabcamera/CabCameraPage"));
const VideoPage = lazy(() => import("./pages/video/VideoPage"));
const BasestationPage = lazy(
  () => import("./pages/basestation/BasestationPage")
);
const AlertPage = lazy(() => import("./pages/alert/AlertPage"));
const FactoryAcceptanceTestPage = lazy(
  () => import("./pages/fat/FactoryAcceptanceTestPage")
);
const SWUpdatePage = lazy(() => import("./pages/swupdate/SWUpdatePage"));
const PiperSWUpdatePage = lazy(
  () => import("./pages/swupdate/PiperUpdateDevicePage")
);
const MenderSWUpdatePage = lazy(
  () => import("./pages/swupdate/MenderUpdateDevicePage")
);
const ManifestTablePage = lazy(
  () => import("./pages/swupdate/ManifestTablePage")
);
const WorkGangPage = lazy(() => import("./pages/workgang/WorkGangPage"));
const DevMonitoringPage = lazy(
  () => import("./pages/devicemonitoring/DeviceMonitoringPage")
);
const AnchorPage = lazy(() => import("./pages/anchor/AnchorPage"));
const VehiclePage = lazy(() => import("./pages/vehicle/VehiclePage"));
const ForbiddenPage = lazy(() => import("./components/common/forbidden"));

const App: React.FC<Props> = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />

      <Router history={history}>
        <Notification />
        <Routes>
          <Route element={<Navigation />}>
            <Route
              path={DASHBOARD_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={DASHBOARD_PAGE.allowedTo}
                    providersBlocked={DASHBOARD_PAGE.providersBlocked}
                  >
                    <DashboardPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={FLEET_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <FleetPage />
                </Suspense>
              }
            />
            <Route
              path={MCU_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={MCU_PAGE.allowedTo}
                    providersBlocked={MCU_PAGE.providersBlocked}
                  >
                    <McuPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={VPU_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={VPU_PAGE.allowedTo}
                    providersBlocked={VPU_PAGE.providersBlocked}
                  >
                    <VpuPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={VOD_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={VOD_PAGE.allowedTo}
                    providersBlocked={VOD_PAGE.providersBlocked}
                  >
                    <VodPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={CAB_CAM_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={CAB_CAM_PAGE.allowedTo}
                    providersBlocked={CAB_CAM_PAGE.providersBlocked}
                  >
                    <CabCameraPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={BASESTATION_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={BASESTATION_PAGE.allowedTo}
                    providersBlocked={BASESTATION_PAGE.providersBlocked}
                  >
                    <BasestationPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={ALERT_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={ALERT_PAGE.allowedTo}
                    providersBlocked={ALERT_PAGE.providersBlocked}
                  >
                    <AlertPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={EQUIPMENT_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={VEHICLE_PAGE.allowedTo}
                    providersBlocked={VEHICLE_PAGE.providersBlocked}
                  >
                    <EquipmentPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={VEHICLE_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={VEHICLE_PAGE.allowedTo}
                    providersBlocked={VEHICLE_PAGE.providersBlocked}
                  >
                    <VehiclePage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={USER_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={USERS_PAGE.allowedTo}
                    providersBlocked={USERS_PAGE.providersBlocked}
                  >
                    <UserPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={FORM_D_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={FORM_D_PAGE.allowedTo}
                    providersBlocked={FORM_D_PAGE.providersBlocked}
                  >
                    <FormDPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={INSTALLATION_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={INSTALLATION_PAGE.allowedTo}
                    providersBlocked={INSTALLATION_PAGE.providersBlocked}
                  >
                    <InstallationPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={FAT_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={FAT_PAGE.allowedTo}
                    providersBlocked={FAT_PAGE.providersBlocked}
                  >
                    <FactoryAcceptanceTestPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={DEVICE_MONITORING_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={DEVICE_MONITORING_PAGE.allowedTo}
                    providersBlocked={DEVICE_MONITORING_PAGE.providersBlocked}
                  >
                    <DevMonitoringPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={SW_UPDATE_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={SW_UPDATE_PAGE.allowedTo}
                    providersBlocked={SW_UPDATE_PAGE.providersBlocked}
                  >
                    <SWUpdatePage />
                  </ProtectedRoute>
                </Suspense>
              }
            >
              <Route index element={<Navigate to="mender" />} />
              <Route
                path="mender"
                element={
                  <Suspense fallback={<Loading />}>
                    <MenderSWUpdatePage />
                  </Suspense>
                }
              />
              <Route
                path="piper"
                element={
                  <Suspense fallback={<Loading />}>
                    <PiperSWUpdatePage />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path={SW_MANIFEST_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={SW_UPDATE_PAGE.allowedTo}
                    providersBlocked={SW_UPDATE_PAGE.providersBlocked}
                  >
                    <ManifestTablePage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={WORK_GANG_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={WORK_GANG_PAGE.allowedTo}
                    providersBlocked={WORK_GANG_PAGE.providersBlocked}
                  >
                    <WorkGangPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={VIDEO_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={VIDEO_PAGE.allowedTo}
                    providersBlocked={VIDEO_PAGE.providersBlocked}
                  >
                    <VideoPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path={ANCHOR_PATH}
              element={
                <Suspense fallback={<Loading />}>
                  <ProtectedRoute
                    rolesAllowedTo={ANCHOR_PAGE.allowedTo}
                    providersBlocked={ANCHOR_PAGE.providersBlocked}
                  >
                    <AnchorPage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              path="/account"
              element={
                <Suspense fallback={<Loading />}>
                  <AccountPage />
                </Suspense>
              }
            />
            <Route path="*" element={<Navigate to="/fleet" />} />
          </Route>
          <Route
            path="/login"
            element={
              <Suspense fallback={<Loading />}>
                <LoginPage />
              </Suspense>
            }
          />
          <Route
            path={FORBIDDEN_PATH}
            element={
              <Suspense fallback={<Loading />}>
                <ForbiddenPage />
              </Suspense>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  darkMode: getDarkMode(state),
});

const connector = connect(mapStateToProps);
export default connector(memo(App));
type Props = ConnectedProps<typeof connector>;
