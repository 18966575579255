/**
 * Action types
 */
import {
  createMenderDeploymentPayload,
  MenderDeploymentEntry,
  ReleaseEntry,
} from "../const/types";
import { AjaxError } from "rxjs/ajax";
import { forbiddenErrorAction, unauthorizedErrorAction } from "./auth.action";

export const FETCH_CURRENT_RELEASE = Symbol.for("FETCH_CURRENT_RELEASE");
export const LOAD_CURRENT_RELEASE = Symbol.for("LOAD_CURRENT_RELEASE");
export const FETCH_OTA_FAILED = Symbol.for("FETCH_OTA_FAILED");
export const CREATE_MENDER_DEPLOYMENTS = Symbol.for(
  "CREATE_MENDER_DEPLOYMENTS"
);
export const FETCH_MANY_MENDER_DEPLOYMENTS = Symbol.for(
  "FETCH_MANY_MENDER_DEPLOYMENTS"
);
export const LOAD_MANY_MENDER_DEPLOYMENTS = Symbol.for(
  "LOAD_MANY_MENDER_DEPLOYMENTS"
);

/**
 * Actions
 */
export function fetchCurrentReleaseAction() {
  return {
    type: FETCH_CURRENT_RELEASE,
  };
}

export function loadCurrentReleaseAction(payload: ReleaseEntry) {
  return {
    type: LOAD_CURRENT_RELEASE,
    payload,
  };
}
export function fetchManyMenderDeploymentsAction(id: string) {
  return {
    type: FETCH_MANY_MENDER_DEPLOYMENTS,
    payload: id,
  };
}

export function loadManyMenderDeploymentsAction(
  id: string,
  deployments: MenderDeploymentEntry[]
) {
  return {
    type: LOAD_MANY_MENDER_DEPLOYMENTS,
    payload: {
      id,
      deployments,
    },
  };
}

export function fetchOTAFailedAction(err: AjaxError) {
  if (err.status === 401) {
    return unauthorizedErrorAction(err);
  }
  if (err.status === 403) {
    return forbiddenErrorAction(err);
  }
  return {
    type: FETCH_OTA_FAILED,
    payload: err.toString(),
  };
}

export function createMenderDeploymentsAction(
  payload: createMenderDeploymentPayload
) {
  return {
    type: CREATE_MENDER_DEPLOYMENTS,
    payload,
  };
}
