import React, { memo } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";

interface Props {
  open: boolean;
}

const RefreshPageModal: React.FC<Props> = ({ open }) => {
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" component="h2" textAlign="center">
          A new version of the LCCAS Dashboard is available.
        </Typography>
        <Typography sx={{ mt: 2 }} textAlign="center">
          Please click to refresh the page.
        </Typography>
        <Button
          onClick={() => window.location.reload()}
          sx={{
            width: 100,
            marginTop: "20px",
          }}
          variant="outlined"
        >
          Refresh
        </Button>
      </Box>
    </Modal>
  );
};

export default memo(RefreshPageModal);
