import React, { memo } from "react";
import { CircularProgress, Paper } from "@mui/material";

export const Loading = memo(() => {
  return (
    <Paper
      sx={{
        backgroundColor: "rgba(255,255,255,0.3)",
        position: "fixed",
        left: "50%",
        top: "50%",
        padding: 2,
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress color={"primary"} />
    </Paper>
  );
});
