import * as otaAction from "../actions/mender.action";
import { AnyAction } from "redux";
import { MenderDeploymentEntry, ReleaseEntry } from "../const/types";

export interface State {
  current: ReleaseEntry | null;
  menderDeployments: {
    [id: string]: MenderDeploymentEntry[];
  };
}

const initialState: State = {
  current: null,
  menderDeployments: {},
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case otaAction.LOAD_CURRENT_RELEASE: {
      return {
        ...state,
        current: action.payload,
      };
    }

    case otaAction.LOAD_MANY_MENDER_DEPLOYMENTS: {
      const { id, deployments } = action.payload;
      return {
        ...state,
        menderDeployments: {
          ...state.menderDeployments,
          [id]: deployments.sort(
            (a: any, b: any) =>
              new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
          ),
        },
      };
    }

    default:
      return state;
  }
}

/**
 * selector
 */
export const getCurrentRelease = (state: State) => state.current;
export const getMenderDeployments = (state: State) => state.menderDeployments;
export const getMenderDeploymentsForDevice = (state: State, id: string) =>
  state.menderDeployments[id];
