import { PublicClientApplication, Configuration } from "@azure/msal-browser";

const uri =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_REDIRECT_URI
    : "http://localhost:3000";
const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AD_CLIENT_ID || "",
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AD_TENANT_ID}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: uri,
    postLogoutRedirectUri: uri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
    secureCookies: process.env.NODE_ENV === "production",
  },
};

export const scopes: string[] = [process.env.REACT_APP_AD_SCOPE || ""];

const pca = new PublicClientApplication(configuration);
export default pca;
