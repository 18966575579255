import * as userAction from "../actions/user.action";
import { AnyAction } from "redux";
import { RoleEntry, Roles, UserEntry } from "../const/types";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  entries: UserEntry[];
  count: number;
  selected: UserEntry | null;
  loading: boolean;
  roles: Roles;
}

const initialState: State = {
  entries: [],
  count: 0,
  selected: null,
  loading: false,
  roles: {},
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case userAction.LOAD_MANY_USERS: {
      const { rows, count } = action.payload;
      return { ...state, entries: rows, count, loading: false };
    }

    case userAction.LOAD_ROLES: {
      return {
        ...state,
        loading: false,
        roles: action.payload.reduce(
          (acc: Roles, { id, label }: RoleEntry) => ({ ...acc, [label]: id }),
          {}
        ),
      };
    }

    case userAction.FETCH_USERS_FAILED:
    case FORBIDDEN_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getUsers = (state: State) => ({
  entries: state.entries,
  count: state.count,
});
export const getRoles = (state: State) => state.roles;
