import {
  AlertEntry,
  AlertQs,
  AlertTypeEntry,
  AssetEntry,
  BasestationEntry,
  McuEntry,
  SearchDeviceActionPayload,
  TableHeadRow,
  TableRowFilter,
  VpuEntry,
  VodEntry,
  CabCameraEntry,
  AllowedVersionsType,
} from "../const/types";
import {
  DEFAULT_DATE_FORMAT,
  ONLINE_BASESTATION_MSG_RATE,
  HEALTH_OK,
  HEALTH_NOT_READY,
  HEALTH_USABLE,
  HEALTH_NOT_USABLE,
  HEALTH_FAILED,
  HEALTH_REPLACE,
  HEALTH_DISABLED,
  HEALTH_DIC,
  HEALTH_BAD_CONFIG,
  NO_DATA,
} from "../const/const";
import { HEALTH_CODE_COLORS } from "../const/ui";
import { green, red, yellow } from "@mui/material/colors";
import { MCU_API, VEHICLE_API } from "../const/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { getOpModeChip } from "../components/Fleet/getOpModeChip";
import { Chip, Box } from "@mui/material";
import { MutableRefObject } from "react";

dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export const joinQueryStr = (qsObj: Object) =>
  Object.entries(qsObj)
    .filter(([_, v]) => !!v)
    .map(([k, v]) => {
      if (k === "dir") {
        return `${k}=${v.toUpperCase()}`;
      } else if (v === "YES" || v === "NO") {
        return `${k}=${v === "YES"}`;
      } else {
        return `${k}=${v}`;
      }
    })
    .join("&");

export const joinAlertQueryStr = (qsObj: AlertQs) => {
  let qsArr = [
    `limit=${qsObj.limit}&order=${qsObj.order}&dir=${(
      qsObj.dir as string
    ).toUpperCase()}&offset=${qsObj.offset}`,
  ];
  if (qsObj.type) {
    qsArr.push(`type=${qsObj.type}`);
  }
  if (qsObj.date_from && qsObj.date_to) {
    qsArr.push(
      `date_range=${qsObj.date_from.valueOf()}-${qsObj.date_to.valueOf()}`
    );
  }
  if (qsObj.filter_by) {
    qsArr.push(`filter_by=${qsObj.filter_by}`);
  }
  if (qsObj.filter_value) {
    qsArr.push(`filter_value=${qsObj.filter_value}`);
  }
  if (qsObj.mcu_id) {
    qsArr.push(`mcu_id=${qsObj.mcu_id}`);
  }
  return qsArr.join("&");
};

export const pathJoin = (...args: string[]) =>
  args
    .map((part, i) => {
      if (i === 0) {
        return part.trim().replace(/\/*$/g, "");
      } else {
        return part.trim().replace(/(^\/*|\/*$)/g, "");
      }
    })
    .filter((x) => x.length)
    .join("/");

export const generateRowFilterFn =
  (head: TableHeadRow[]) =>
  (prop: string): TableRowFilter | false => {
    const index = head.findIndex((r) => r.prop === prop);
    if (index > -1)
      return {
        align: head[index].align,
        index,
      };
    else return false;
  };

const pi = 0.017453292519943295; // Math.PI / 180
export const dist = (p1: google.maps.LatLng, p2: google.maps.LatLng) => {
  const a =
    0.5 -
    Math.cos((p2.lat() - p1.lat()) * pi) / 2 +
    (Math.cos(p1.lat() * pi) *
      Math.cos(p2.lat() * pi) *
      (1 - Math.cos((p2.lng() - p1.lng()) * pi))) /
      2;

  return 12742e3 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

export const alertMessage = (alertType: AlertTypeEntry, o: object): string => {
  if (!alertType || !alertType.template) return "No alert message template.";
  // eslint-disable-next-line no-new-func
  const handler = new Function(
    "o",
    "const tagged = " + alertType.template + "; return tagged(o)"
  );
  return handler(o);
};

export const alertColor = (alertType: AlertTypeEntry, o: object): string => {
  if (!alertType.color) return "red";
  // eslint-disable-next-line no-new-func
  const handler = new Function(
    "o",
    "const tagged = " + alertType.color + "; return tagged(o)"
  );
  return handler(o);
};
export const toAlertEntry = ({
  alert_type_id,
  mcu_id,
  vehicle_id,
  form_d_id,
  max_spd,
  updated_at,
  created_at,
  vpu_id,
  vpu_time,
  foreman_feedback,
  ...r
}: any): AlertEntry => ({
  seen: false,
  AlertTypeId: alert_type_id,
  McuId: mcu_id,
  VehicleId: vehicle_id,
  FormDId: form_d_id,
  maxSpd: max_spd,
  updatedAt: updated_at,
  createdAt: created_at,
  VpuId: vpu_id,
  vpuTime: vpu_time,
  foremanFeedback: foreman_feedback,
  ...r,
});

export const getBasestationColor = (bs: BasestationEntry) => {
  if (bs.installed) {
    return bs.avgMsgPerSec > ONLINE_BASESTATION_MSG_RATE
      ? green["700"] // online
      : red["600"]; // offline
  } else {
    return yellow["700"]; // maintenance
  }
};

export const getSearchUrl = (payload: SearchDeviceActionPayload) => {
  const { idType, value } = payload;
  switch (idType) {
    case "mcu_id":
      return `${MCU_API}/search?id=${value}&only_ids=true`;
    case "mcu_sn":
      return `${MCU_API}/search?sn=${value}&only_ids=true`;
    case "vehicle_id":
      return `${VEHICLE_API}?query=${value}&only_ids=true`;
  }
};

export const formatDate = (
  date: string | number | Date | undefined,
  template = DEFAULT_DATE_FORMAT
) => {
  if (!date) return NO_DATA;
  return dayjs(date).utc().local().format(template);
};

export const formatDateUTC = (date: string | number | Date) =>
  dayjs(date).utc().format();

export const toDuration = (n: number) => dayjs.duration(n).humanize();

export const downloadAsFile = (blob: Blob, filename: string) => {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const getAssetColor = (a: AssetEntry) => {
  switch (a.type) {
    case "signal":
      return "#e0a40c";
    case "switch":
      return "#0c56e0";
    case "milepost":
      return "#036404";
    case "cat_pole":
      return "#7a03a9";
  }
};

const greens: any[] = [1, "okay", "true", true, "ok"];
const yellows: any[] = ["warn", "PASS"];
export const getStatusColorDot = (value: any) => {
  let color;
  if (typeof value === "object") {
    if (value === null || value?.front === null || value?.back === null) {
      color = "red";
    } else if (
      greens.includes(value.front?.state) &&
      greens.includes(value.back?.state)
    ) {
      color = "green";
    } else if (
      yellows.includes(value.front?.state) ||
      yellows.includes(value.back?.state)
    ) {
      color = "#ffa31c";
    } else {
      color = "red";
    }
  } else {
    color = greens.includes(value)
      ? "green"
      : yellows.includes(value)
      ? "#ffa31c"
      : "red";
  }
  return (
    <span
      style={{
        height: "10px",
        width: "10px",
        backgroundColor: color,
        borderRadius: "50%",
        display: "inline-block",
      }}
    />
  );
};

export const getHealthStatusIcon = (
  code: number | string | boolean | undefined
): JSX.Element | string => {
  let label = "No Value";
  let color = "grey";
  switch (code) {
    case HEALTH_OK:
      label = HEALTH_DIC[HEALTH_OK];
      color = HEALTH_CODE_COLORS[HEALTH_OK];
      break;
    case HEALTH_NOT_READY:
      label = HEALTH_DIC[HEALTH_NOT_READY];
      color = HEALTH_CODE_COLORS[HEALTH_NOT_READY];
      break;
    case HEALTH_USABLE:
      label = HEALTH_DIC[HEALTH_USABLE];
      color = HEALTH_CODE_COLORS[HEALTH_USABLE];
      break;
    case HEALTH_NOT_USABLE:
      label = HEALTH_DIC[HEALTH_NOT_USABLE];
      color = HEALTH_CODE_COLORS[HEALTH_NOT_USABLE];
      break;
    case HEALTH_FAILED:
      label = HEALTH_DIC[HEALTH_FAILED];
      color = HEALTH_CODE_COLORS[HEALTH_FAILED];
      break;
    case HEALTH_REPLACE:
      label = HEALTH_DIC[HEALTH_REPLACE];
      color = HEALTH_CODE_COLORS[HEALTH_REPLACE];
      break;
    case HEALTH_DISABLED:
      label = HEALTH_DIC[HEALTH_DISABLED];
      color = HEALTH_CODE_COLORS[HEALTH_REPLACE];
      break;
    case HEALTH_BAD_CONFIG:
      label = HEALTH_DIC[HEALTH_BAD_CONFIG];
      color = HEALTH_CODE_COLORS[HEALTH_BAD_CONFIG];
      break;
  }
  return label === "No Value" ? (
    NO_DATA
  ) : (
    <Chip
      size="small"
      variant="outlined"
      label={label}
      sx={{
        paddingX: 0.5,
        paddingY: 1,
        marginTop: "2px",
        marginBottom: "2px",
        color: color,
        borderColor: color,
        backgroundColor: code === HEALTH_NOT_READY ? "#DADADA" : "inherit",
      }}
    />
  );
};

const parseSwVer = (swVer: string | undefined) => {
  if (!swVer) return NO_DATA;
  const ver = /(?<=V)\d+\.\d+/.exec(swVer);
  if (typeof swVer !== "number" && ver !== null) {
    return ver[0];
  } else {
    return "-";
  }
};

export const usingDetailHealthPackets = (swVersion: string | undefined) => {
  if (!swVersion) return false;
  const swVer = parseSwVer(swVersion);
  let verMaj = 0;
  let verMin = 0;
  if (swVer === "-") return false;
  const ver = swVer.split(".");
  verMaj = +ver[0];
  verMin = +ver[1];
  return verMaj === 3 ? verMin >= 32 : verMaj > 3;
};

/** Pass an McuEntry and a prop and this erorr checks
 * the data and returns a formatted result.
 * If 'icon' param is true, it will return a formatted
 * JSX Element, otherwise it returns the raw data
 */
export const mcuFormatter = (
  entry: McuEntry,
  prop: string,
  icon?: boolean,
  packetVer = "0",
  idx = 0
): JSX.Element | string => {
  switch (prop) {
    case "ID":
    case "id":
    case "MCU ID":
      return entry?.id || NO_DATA;

    case "sn":
    case "SN":
    case "MCU S/N":
      return entry?.sn || NO_DATA;

    case "UWB Dist":
    case "UWB DIST":
    case "uwbDist":
    case "UWB Distance":
    case "UWB Dist (m)":
      const uwbFront = entry.uwb?.front?.dist
        ? (entry.uwb.front.dist / 1000).toFixed(2)
        : "No Value";
      const uwbBack = entry.uwb?.back?.dist
        ? (entry.uwb.back.dist / 1000).toFixed(2)
        : "No Value";
      return `Front: ${uwbFront}, Back: ${uwbBack}`;

    case "Lidar Dist":
    case "Lidar Distance":
    case "LiDAR Dist":
    case "LIDAR DIST":
    case "Lidar Dist (m)":
      const lidarFront = entry.lidar?.front?.dist
        ? (entry.lidar.front.dist / 1000).toFixed(2)
        : "No Value";
      const lidarBack = entry.lidar?.back?.dist
        ? (entry.lidar.back.dist / 1000).toFixed(2)
        : "No Value";
      return `Front: ${lidarFront}, Back: ${lidarBack}`;

    case "Vehicle.id":
      return entry.Vehicle?.id ?? NO_DATA;

    case "uwbFront":
      return entry.uwb?.front?.dist
        ? (entry.uwb.front.dist / 1000).toFixed(2)
        : "No Value";

    case "uwbBack":
      return entry.uwb?.back?.dist
        ? (entry.uwb.back.dist / 1000).toFixed(2)
        : "No Value";

    case "lidarFront":
      return entry.lidar?.front?.dist
        ? (entry.lidar.front.dist / 1000).toFixed(2)
        : "No Value";

    case "lidarBack":
      return entry.lidar?.back?.dist
        ? (entry.lidar.back.dist / 1000).toFixed(2)
        : "No Value";

    case "state":
    case "Region":
    case "region":
    case "State":
      return entry.region ?? NO_DATA;

    case "City":
    case "city":
      return entry.city ?? NO_DATA;

    case "sw":
    case "SW":
    case "swVersion":
      return parseSwVer(entry.swVersion);

    case "hw":
    case "HW":
    case "hwVersion":
      return entry.hwVersion ?? NO_DATA;

    case "gcs":
    case "location":
    case "Location":
      if (entry.gcs?.coordinates[1] && entry.gcs?.coordinates[0]) {
        return `(${entry.gcs.coordinates[1].toFixed(
          2
        )}, ${entry.gcs.coordinates[0].toFixed(2)})`;
      } else {
        return NO_DATA;
      }

    case "Speed (km/h)":
    case "speed":
    case "Speed":
      return isNaN(entry.speed)
        ? NO_DATA
        : `${(entry.speed * 0.0036).toFixed(2)} kph`;

    case "Speed (mph)":
    case "speed mph":
      return isNaN(entry.speed)
        ? NO_DATA
        : `${(entry.speed / 447.04).toFixed(2)} mph`;

    case "ssd":
    case "SSD":
      return entry.health?.ssd?.status !== undefined
        ? usingDetailHealthPackets(entry.swVersion)
          ? getHealthStatusIcon(entry.health.ssd.status)
          : getStatusColorDot(entry.health.ssd.status)
        : NO_DATA;

    case "freeMem":
    case "freemem":
    case "Free memory":
    case "Free Memory":
    case "Free Memory (KB)":
    case "Free memory (KB)":
      return entry.health?.freemem ?? NO_DATA;

    case "lteRssi":
    case "LTE RSSI":
    case "lte":
    case "LTE":
      return icon
        ? usingDetailHealthPackets(entry.swVersion)
          ? getHealthStatusIcon(entry.health?.lte_rssi)
          : getStatusColorDot(entry.health?.lte_rssi)
        : entry.health?.lte_rssi ?? NO_DATA;

    case "temp":
    case "temperature":
    case "Temp":
    case "TEMP":
    case "Temp (°C)":
    case "Temp (˚C)":
      return entry.health?.temp?.toFixed(2) ?? NO_DATA;

    case "mode":
    case "Operating Mode":
    case "Op Mode":
    case "Mode":
      return icon ? getOpModeChip(entry) : entry.mode ?? NO_DATA;

    case "updatedAt":
    case "updated":
    case "Updated":
    case "Last Updated":
      return formatDate(entry.updatedAt) ?? NO_DATA;

    case "createdAt":
    case "created":
      return formatDate(entry.createdAt) ?? NO_DATA;

    case "Nearest Vehicle":
      const nVFront = entry.uwb?.front?.dist
        ? `${(entry.uwb.front.dist * 0.00328).toFixed(0)} ft`
        : NO_DATA;
      const nVBack = entry.uwb?.back?.dist
        ? `${(entry.uwb.back.dist * 0.00328).toFixed(0)} ft`
        : NO_DATA;
      return `Front: ${nVFront}\nBack: ${nVBack}`;

    case "Obstruction":
      const ObstFront = entry.lidar?.front?.dist
        ? `${(entry.lidar.front.dist * 0.00328).toFixed(0)} ft`
        : NO_DATA;
      const ObstBack = entry.lidar?.back?.dist
        ? `${(entry.lidar.back.dist * 0.00328).toFixed(0)} ft`
        : NO_DATA;
      return `Front: ${ObstFront}\nBack: ${ObstBack}`;

    case "network":
    case "Network":
      return icon
        ? +packetVer >= 13
          ? getHealthStatusIcon(entry.health?.network)
          : getStatusColorDot(entry.health?.network)
        : entry.health?.network ?? NO_DATA;

    case "gps":
    case "GPS":
      return icon
        ? getStatusColorDot(entry.gpsState)
        : entry.gpsState ?? NO_DATA;

    case "uwb":
    case "UWB":
      return icon ? getStatusColorDot(entry.uwb) : entry.uwb ?? NO_DATA;

    case "lidar":
    case "LIDAR":
    case "LiDAR":
    case "Lidar":
      return icon ? getStatusColorDot(entry.lidar) : entry.lidar ?? NO_DATA;

    case "installed":
    case "Installed":
      return icon
        ? getStatusColorDot(entry.installed)
        : entry.installed
        ? "true"
        : "false";

    case "Maint":
    case "Maintenance":
    case "maintenance":
    case "maint":
      return entry.maintenance ? "YES" : "NO";

    case "Re-Init Requested":
    case "reInit":
    case "re-init":
    case "Re-Init requested":
      return entry.needToReInit ? "YES" : "NO";

    case "imei":
    case "IMEI":
      return entry.modem?.imei ?? NO_DATA;

    case "iccid1":
    case "ICCID1":
      return entry.modem?.iccid1 ?? NO_DATA;

    case "iccid2":
    case "ICCID2":
      return entry.modem?.iccid2 ?? NO_DATA;

    case "vod":
      return icon
        ? +packetVer >= 13
          ? getHealthStatusIcon(entry.Vods[idx]?.status)
          : getStatusColorDot(entry.Vods[idx]?.status)
        : entry.Vods[idx]?.status ?? NO_DATA;

    case "cabCamera":
      return icon
        ? +packetVer >= 13
          ? getHealthStatusIcon(entry.CabCameras[idx]?.status)
          : getStatusColorDot(entry.CabCameras[idx]?.status)
        : entry.CabCameras[idx]?.status ?? NO_DATA;

    default:
      return entry[prop as keyof McuEntry] ?? NO_DATA;
  }
};

export const vpuFormatter = (
  entry: VpuEntry,
  prop: string,
  icon?: boolean,
  idx = 0
): JSX.Element | string => {
  const detailHealthPackets = usingDetailHealthPackets(entry.swVersion);

  switch (prop) {
    case "ID":
    case "id":
    case "MCU ID":
      return entry.id ?? NO_DATA;

    case "sn":
    case "SN":
      return entry.sn ?? NO_DATA;

    case "sw":
    case "SW":
    case "swVersion":
      return `V${parseSwVer(entry.swVersion)}`;

    case "hw":
    case "HW":
    case "hwVersion":
      return entry?.hwVersion ?? NO_DATA;

    case "mcu":
    case "Mcu":
    case "MCU":
    case "MCU S/N":
      return entry?.Mcu?.sn ?? NO_DATA;

    case "McuId":
    case "MCUId":
    case "mcuId":
      return entry?.McuId ?? NO_DATA;

    case "gps":
    case "GPS":
      return entry.gps !== null
        ? icon
          ? detailHealthPackets
            ? getHealthStatusIcon(entry.gps)
            : getStatusColorDot(entry.gps)
          : `${entry.gps}`
        : NO_DATA;

    case "lidar":
    case "LIDAR":
      return entry.lidar !== null
        ? icon
          ? detailHealthPackets
            ? getHealthStatusIcon(entry.lidar)
            : getStatusColorDot(entry.lidar)
          : `${entry.lidar}`
        : NO_DATA;

    case "uwb":
    case "UWB":
      return entry.uwb !== null
        ? icon
          ? detailHealthPackets
            ? getHealthStatusIcon(entry.uwb)
            : getStatusColorDot(entry.uwb)
          : `${entry.gps}`
        : NO_DATA;

    case "imu":
    case "IMU":
      return entry.imu !== null
        ? icon
          ? detailHealthPackets
            ? getHealthStatusIcon(entry.imu)
            : getStatusColorDot(entry.imu)
          : `${entry.imu}`
        : NO_DATA;

    case "camera":
    case "Camera":
      return entry.camera !== null
        ? icon
          ? detailHealthPackets
            ? getHealthStatusIcon(entry.camera)
            : getStatusColorDot(entry.camera)
          : `${entry.camera}`
        : NO_DATA;

    case "ssd":
    case "SSD":
    case "ssd0":
    case "SSD0":
      if (
        entry.health?.ssd?.status === undefined &&
        entry.health?.ssd0?.status === undefined
      )
        return NO_DATA;
      if (
        entry.health?.ssd?.status !== null ||
        entry.health?.ssd0?.status !== null
      ) {
        if (icon) {
          if (detailHealthPackets) {
            if (entry.health?.ssd?.status !== undefined) {
              return getHealthStatusIcon(entry.health.ssd.status);
            } else {
              return getHealthStatusIcon(entry.health?.ssd0?.status);
            }
          } else {
            if (entry.health?.ssd?.status !== undefined) {
              return getStatusColorDot(entry.health.ssd.status);
            } else if (entry.health?.ssd0?.status !== undefined) {
              return getStatusColorDot(entry.health.ssd0.status);
            } else {
              return NO_DATA;
            }
          }
        } else {
          return `${entry.health.ssd.status}`;
        }
      } else {
        return NO_DATA;
      }

    case "ssd1":
    case "SSD1":
      if (entry.health?.ssd1?.status === undefined) return NO_DATA;
      return entry.health?.ssd1?.status !== null
        ? icon
          ? detailHealthPackets
            ? getHealthStatusIcon(entry.health?.ssd1?.status)
            : getStatusColorDot(entry.health?.ssd1?.status)
          : `${entry.health?.ssd1?.status}`
        : NO_DATA;

    case "createdAt":
      return formatDate(entry.createdAt);

    case "updatedAt":
      return formatDate(entry.updatedAt);

    default:
      return entry[prop as keyof VpuEntry] ?? NO_DATA;
  }
};

export const vodFormatter = (
  entry: VodEntry,
  prop: string,
  icon?: boolean
): JSX.Element | string | boolean => {
  const detailHealthPackets = usingDetailHealthPackets(entry["Mcu.swVersion"]);
  try {
    switch (prop) {
      case "id":
      case "ID":
        return entry.id;

      case "mcu_sn":
      case "mcu.sn":
      case "Mcu.sn":
        return entry["Mcu.sn"];

      case "mcu_id":
      case "mcu.id":
      case "Mcu.id":
        return entry["Mcu.id"];

      case "status":
      case "health":
        return icon
          ? detailHealthPackets
            ? getHealthStatusIcon(entry.status)
            : getStatusColorDot(entry.status)
          : `${entry.status}`;

      case "sn":
      case "SN":
      case "S/N":
        return entry.sn;

      case "createdAt":
        return formatDate(entry.createdAt);

      case "updatedAt":
        return formatDate(entry.updatedAt);

      default:
        return entry[prop as keyof VodEntry];
    }
  } catch (error) {
    return "-";
  }
};

export const cabCamFormatter = (
  entry: CabCameraEntry,
  prop: string,
  icon?: boolean
): JSX.Element | string | number | boolean => {
  const detailHealthPackets = usingDetailHealthPackets(entry["Mcu.swVersion"]);
  try {
    switch (prop) {
      case "id":
      case "ID":
        return entry.id;

      case "mcu_sn":
      case "mcu.sn":
      case "Mcu.sn":
        return entry["Mcu.sn"];

      case "mcu_id":
      case "mcu.id":
      case "Mcu.id":
        return entry["Mcu.id"];

      case "mac":
        return entry.mac;

      case "status":
        return icon
          ? detailHealthPackets
            ? getHealthStatusIcon(entry.status)
            : getStatusColorDot(entry.status)
          : `${entry.status}`;

      case "createdAt":
        return formatDate(entry.createdAt);

      case "updatedAt":
        return formatDate(entry.updatedAt);

      default:
        return entry[prop as keyof CabCameraEntry];
    }
  } catch (error) {
    return "-";
  }
};

export const basestationFormatter = (
  entry: BasestationEntry,
  prop: string,
  icon?: boolean
) => {
  switch (prop) {
    case "Lat":
      return entry.gcs?.coordinates[1] ?? NO_DATA;

    case "Lon":
      return entry.gcs?.coordinates[0] ?? NO_DATA;

    case "Alt":
      return entry.alt ?? NO_DATA;

    case "Last Upd":
      return formatDate(entry.updatedAt);

    case "Idle":
    case "idle":
      return entry.heartbeat?.idle ?? NO_DATA;

    case "RSRP":
    case "rsrp":
      return entry.heartbeat?.rsrp ?? NO_DATA;

    case "RSRQ":
    case "rsrq":
      return entry.heartbeat?.rsrq ?? NO_DATA;

    case "Temp":
    case "temp":
      return entry.heartbeat?.temp ?? NO_DATA;

    case "Uptime":
    case "uptime":
      return entry.heartbeat?.uptime ?? NO_DATA;

    case "Free Mem":
    case "Free Memory":
    case "free mem":
      return entry.heartbeat?.freemem ?? NO_DATA;

    case "Sig Qual":
    case "Signal Quality":
      return entry.heartbeat?.signal_quality ?? NO_DATA;

    case "sn":
    case "SN":
    case "s/n":
    case "S/N":
      return entry.sn;

    case "PPP Start":
    case "ppp start":
    case "PPP start":
      return entry.statusMessage?.PPP_START ?? NO_DATA;

    case "PPP End":
    case "ppp end":
    case "PPP end":
      return entry.statusMessage?.PPP_END ?? NO_DATA;

    default:
      return entry[prop as keyof BasestationEntry] ?? NO_DATA;
  }
};

export const cabCamStatusIcon = (entry: McuEntry | null, idx: number) => {
  if (entry === null) return "-";
  const detailHealthPackets = usingDetailHealthPackets(entry.swVersion!);
  try {
    return detailHealthPackets
      ? getHealthStatusIcon(entry.CabCameras[idx].status)
      : getStatusColorDot(entry.CabCameras[idx].status);
  } catch (error) {
    return "-";
  }
};

export const getMapDimensions = (map: google.maps.Map) => {
  const ne = map.getBounds()!.getNorthEast();
  const sw = map.getBounds()!.getSouthWest();
  const latlon = map.getBounds()!.getCenter().toUrlValue();
  const radius = Math.floor(dist(map.getBounds()!.getCenter(), ne));
  return { latlon, radius, ne, sw };
};

export const vehicleTypeFormatter = (vType: string): string => {
  if (vType === NO_DATA) return NO_DATA;
  let words: any = vType.split(",");
  words = words.map((phrase: any) =>
    phrase.split(" ").filter((word: any) => word !== "")
  );
  words = words.map((phrase: any) =>
    phrase.map((word: any) => {
      if (isNaN(word)) {
        return word[0].toUpperCase() + word.substr(1).toLowerCase();
      } else {
        return word;
      }
    })
  );
  let vehicleType = "";
  words.forEach((phrase: any, i: number) => {
    phrase.forEach((word: any, j: number) => {
      vehicleType += word;
      if (j !== phrase.length - 1) {
        vehicleType += " ";
      }
    });
    if (i !== words.length - 1) {
      vehicleType += ", ";
    }
  });
  return vehicleType;
};

export const isValidVersion = (
  appVersion = "1.0.0",
  allowedVersion: AllowedVersionsType
) => {
  const [major, minor, patch] = appVersion.split(".").map((v) => parseInt(v));
  const [majorMin, minorMin, patchMin] = allowedVersion.minAppVersion
    .split(".")
    .map((v) => parseInt(v));
  const [majorMax, minorMax, patchMax] = allowedVersion.maxAppVersion
    .split(".")
    .map((v) => parseInt(v));

  const clientVersion = major * 1e6 + minor * 1e3 + (patch ?? 0);
  const minVersion = majorMin * 1e6 + minorMin * 1e3 + patchMin;
  const maxVersion = majorMax * 1e6 + minorMax * 1e3 + patchMax;
  return clientVersion >= minVersion && clientVersion <= maxVersion;
};

export const calcHeights = (
  bottomRef: MutableRefObject<HTMLElement | undefined>,
  topRef: MutableRefObject<HTMLElement | undefined>,
  baseRef: MutableRefObject<HTMLElement | undefined>
) => {
  const bottomHeight = bottomRef.current ? bottomRef.current.offsetHeight : 0;
  const topHeight = topRef.current ? topRef.current.offsetHeight : 0;
  const baseHeight = baseRef.current ? baseRef.current.offsetHeight : 0;
  return baseHeight - (bottomHeight + topHeight) - 1;
};

export const digestMessage = async (msg: any) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(msg);
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};
