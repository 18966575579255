import * as vehicleAction from "../actions/vehicle.action";
import { AnyAction } from "redux";
import {
  VehicleEntry,
  VehicleServiceTypeEntry,
  VehicleServiceTypeObject,
  VehicleServiceTypes,
} from "../const/types";
import { FORBIDDEN_ERROR } from "../actions/auth.action";

export interface State {
  entries: VehicleEntry[];
  count: number;
  selected: VehicleEntry | null;
  loading: boolean;
  vehicleTypes: VehicleServiceTypeObject;
  serviceTypes: VehicleServiceTypeObject;
}

const initialState: State = {
  entries: [],
  count: 0,
  selected: null,
  loading: false,
  vehicleTypes: {},
  serviceTypes: {},
};

/**
 * Reducers
 */
export function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case vehicleAction.LOAD_VEHICLE_TYPES: {
      const { vehicleTypes, serviceTypes }: VehicleServiceTypes =
        action.payload;
      return {
        ...state,
        vehicleTypes: vehicleTypes.reduce(
          (acc: VehicleServiceTypeObject, { id, label }) => ({
            ...acc,
            [id]: label,
          }),
          {}
        ),
        serviceTypes: serviceTypes.reduce(
          (acc: VehicleServiceTypeObject, { id, label }) => ({
            ...acc,
            [id]: label,
          }),
          {}
        ),
        loading: false,
      };
    }
    case vehicleAction.LOAD_MANY_VEHICLES: {
      const { rows, count } = action.payload;
      return {
        ...state,
        entries: rows.map((r: VehicleEntry) => ({ ...r, edit: null })),
        count,
        loading: false,
      };
    }

    case vehicleAction.LOAD_VEHICLE: {
      const { id, payload } = action.payload;
      const idx = state.entries.findIndex((v) => v.id === id);
      if (idx === -1) return state;
      return {
        ...state,
        entries: [
          ...state.entries.slice(0, idx),
          { ...state.entries[idx], ...payload },
          ...state.entries.slice(idx + 1),
        ],
        count: state.count,
        loading: false,
      };
    }

    case vehicleAction.LOAD_VEHICLE_TYPE: {
      const vt = action.payload as VehicleServiceTypeEntry;
      return {
        ...state,
        vehicleTypes: {
          ...state.vehicleTypes,
          [vt.id]: vt.label,
        },
      };
    }

    case vehicleAction.FETCH_VEHICLE_FAILED:
    case FORBIDDEN_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
}

/**
 * selector
 */
export const getVehicles = (state: State) => ({
  entries: state.entries,
  count: state.count,
});

export const getServiceAndVehicleTypes = ({
  serviceTypes,
  vehicleTypes,
}: State) => ({
  serviceTypes,
  vehicleTypes,
});

export const getVehicleTypes = (state: State) => state.vehicleTypes;
