import * as workGangAction from "../actions/workgang.action";
import {
  catchError,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
} from "rxjs/operators";
import { Observable, of } from "rxjs";
import { ofType, StateObservable } from "redux-observable";
import { AnyAction } from "redux";
import { ManyEntry, WorkGangEntry, WorkGangQs } from "../const/types";
import { ajax } from "rxjs/ajax";
import { WORK_GANG_API } from "../const/api";
import { getToken } from "../reducers";
import { joinQueryStr } from "../helper/util";
import { RootState } from "../config/store";
import * as notificationAction from "../actions/notification.action";
import {
  NOTIFICATION_SEVERITY_ERROR,
  NOTIFICATION_SEVERITY_SUCCESS,
} from "../const/const";
import { NOTIFICATION_BOTTOM } from "../const/ui";

export const fetchManyWorkGangsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(workGangAction.FETCH_MANY_WORK_GANGS),
    map((action) => action.payload),
    map((qs: WorkGangQs) => joinQueryStr(qs)),
    distinctUntilChanged(),
    switchMap((qsStr: string) =>
      ajax
        .getJSON<ManyEntry<WorkGangEntry>>(`${WORK_GANG_API}?${qsStr}`, {
          Authorization: `Bearer ${getToken(state$.value)}`,
        })
        .pipe(
          map(workGangAction.loadManyWorkGangAction),
          catchError((err) => of(workGangAction.fetchWorkGangFailedAction(err)))
        )
    )
  );

export const deactivateWorkGangsEpic = (
  action$: Observable<AnyAction>,
  state$: StateObservable<RootState>
): Observable<AnyAction> =>
  action$.pipe(
    ofType(workGangAction.DEACTIVATE_WORK_GANG),
    map((action) => action.payload),
    switchMap((id: string) =>
      ajax
        .put(
          `${WORK_GANG_API}/${id}/activate`,
          {
            active: false,
          },
          {
            Authorization: `Bearer ${getToken(state$.value)}`,
          }
        )
        .pipe(
          mergeMap(() =>
            of(
              workGangAction.changeWorkGangStatus(id, false),
              notificationAction.loadNotificationAction({
                type: `WORK_GANG_DEACTIVATED`,
                severity: NOTIFICATION_SEVERITY_SUCCESS,
                position: NOTIFICATION_BOTTOM,
                title: `Work Gang De-activated`,
                content: `Work Gang ${id} was de-activated successfully`,
                dur: 10000,
                created: new Date().toISOString(),
              })
            )
          ),
          catchError((err) => {
            if (err.status === 400) {
              return of(
                notificationAction.loadNotificationAction({
                  type: `WORK_GANG_DEACTIVATED_FAILURE`,
                  severity: NOTIFICATION_SEVERITY_ERROR,
                  position: NOTIFICATION_BOTTOM,
                  title: `Work Gang De-activated`,
                  content: `Work Gang ${id} de-activation failed`,
                  dur: 10000,
                  created: new Date().toISOString(),
                })
              );
            }
            return of(workGangAction.fetchWorkGangFailedAction(err));
          })
        )
    )
  );

export const workGangEpics = [fetchManyWorkGangsEpic, deactivateWorkGangsEpic];
